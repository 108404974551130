@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Template Name: Daxone - eCommerce Bootstrap 4 Template
    Version: 2.0

-----------------------------------------------------------------------------------

    CSS INDEX
    ===================

    1. Template default CSS
    2. Header style
    3. Slider style
    4. Banner style
    5. Section title style
    6. Product style
    7. Discount style
    8. Footer style
    9. Overview style
    10. Instagram style
    11. Category style
    12. Deal style
    13. Feature style
    14. Brand logo style
    15. Blog style
    16. Breadcrumb style
    17. Product details style
    18. Blog details style
    19. Sidebar style
    20. Cart style
    21. Login register style
    22. Contect us style
    23. Checkout style
    24. Compare style
    25. My account style
    26. About us style

-----------------------------------------------------------------------------------*/
/*----------------------------------------*/
/*  1. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
@import url("https://fonts.googleapis.com/css?family=Marck+Script|Poppins:300,400,500,600,700|Roboto+Mono:400,500,700|Roboto+Slab:300,400,700|Roboto:300,400,500,700,900");
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background-color: #ffffff;
  line-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  color: #010225;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #373737;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #737373;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 12px;
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  color: inherit;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #373737;
}

a,
button,
img,
input,
span {
  transition: all 0.3s ease 0s;
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

button,
input[type=submit] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*-- 
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden;
}

.hidden {
  display: none;
}

.clear {
  clear: both;
}

.section {
  float: left;
  width: 100%;
}

.f-right {
  float: right;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bg-img {
  background-position: center center;
  background-size: cover;
}

.border-bottom-1 {
  border-bottom: 1px solid #d5d5d5;
}

.border-top-1 {
  border-top: 1px solid #e5e5e5;
}

.border-top-2 {
  border-top: 1px solid #282828;
}

.border-top-3 {
  border-top: 2px solid #313443;
}

a:hover {
  color: #ff5151;
}

/*************************
  Basic margin padding
*************************/
.m-0 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}

.p-0 {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}

/*************************
         Margin top
*************************/
.mt-0 {
  margin-top: 0;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

/*************************
      Margin right
*************************/
.mr-0 {
  margin-right: 0px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-35 {
  margin-right: 35px;
}

.mr-40 {
  margin-right: 40px;
}

.mr-45 {
  margin-right: 45px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-60 {
  margin-right: 60px;
}

.mr-70 {
  margin-right: 70px;
}

.mr-80 {
  margin-right: 80px;
}

.mr-90 {
  margin-right: 90px;
}

.mr-100 {
  margin-right: 100px;
}

.mr-110 {
  margin-right: 110px;
}

.mr-120 {
  margin-right: 120px;
}

.mr-130 {
  margin-right: 130px;
}

.mr-140 {
  margin-right: 140px;
}

.mr-150 {
  margin-right: 150px;
}

.mr-200 {
  margin-right: 200px;
}

.mr-210 {
  margin-right: 210px;
}

.mr-220 {
  margin-right: 220px;
}

.mr-230 {
  margin-right: 230px;
}

/*************************
      Margin bottom
*************************/
.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/*************************
        Margin left
*************************/
.ml-0 {
  margin-left: 0;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-35 {
  margin-left: 35px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-45 {
  margin-left: 45px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-55 {
  margin-left: 55px;
}

.ml-60 {
  margin-left: 60px;
}

.ml-70 {
  margin-left: 70px;
}

.ml-75 {
  margin-left: 75px;
}

.ml-80 {
  margin-left: 80px;
}

.ml-90 {
  margin-left: 90px;
}

.ml-95 {
  margin-left: 95px;
}

.ml-100 {
  margin-left: 100px;
}

.ml-110 {
  margin-left: 110px;
}

.ml-120 {
  margin-left: 120px;
}

.ml-130 {
  margin-left: 130px;
}

.ml-135 {
  margin-left: 135px;
}

.ml-140 {
  margin-left: 140px;
}

.ml-145 {
  margin-left: 145px;
}

.ml-150 {
  margin-left: 150px;
}

.ml-200 {
  margin-left: 200px;
}

.ml-300 {
  margin-left: 300px;
}

.ml-ntv5 {
  margin-left: -5px;
}

/*************************
        Padding top
*************************/
.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-85 {
  padding-top: 85px;
}
@media only screen and (max-width: 767px) {
  .pt-85 {
    padding-top: 45px;
  }
}

.pt-80 {
  padding-top: 80px;
}
@media only screen and (max-width: 767px) {
  .pt-80 {
    padding-top: 50px;
  }
}

.pt-95 {
  padding-top: 95px;
}

.pt-90 {
  padding-top: 90px;
}
@media only screen and (max-width: 767px) {
  .pt-90 {
    padding-top: 50px;
  }
}

.pt-100 {
  padding-top: 100px;
}
@media only screen and (max-width: 767px) {
  .pt-100 {
    padding-top: 50px;
  }
}

.pt-105 {
  padding-top: 105px;
}
@media only screen and (max-width: 767px) {
  .pt-105 {
    padding-top: 45px;
  }
}

.pt-110 {
  padding-top: 110px;
}
@media only screen and (max-width: 767px) {
  .pt-110 {
    padding-top: 50px;
  }
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}
@media only screen and (max-width: 767px) {
  .pt-120 {
    padding-top: 50px;
  }
}

.pt-125 {
  padding-top: 125px;
}

.pt-128 {
  padding-top: 128px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-270 {
  padding-top: 270px;
}

.pt-240 {
  padding-top: 240px;
}

.pt-365 {
  padding-top: 365px;
}

.pt-370 {
  padding-top: 370px;
}

.pt-380 {
  padding-top: 380px;
}

/*************************
        Padding right
*************************/
.pr-0 {
  padding-right: 0;
}

.pr-10 {
  padding-right: 10px;
}

.pr-15 {
  padding-right: 15px;
}

.pr-20 {
  padding-right: 20px;
}

.pr-30 {
  padding-right: 30px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-60 {
  padding-right: 60px;
}

.pr-70 {
  padding-right: 70px;
}

.pr-80 {
  padding-right: 80px;
}

.pr-90 {
  padding-right: 90px;
}

.pr-100 {
  padding-right: 100px;
}

.pr-110 {
  padding-right: 110px;
}

.pr-120 {
  padding-right: 120px;
}

.pr-130 {
  padding-right: 130px;
}

.pr-140 {
  padding-right: 140px;
}

.pr-155 {
  padding-right: 155px;
}

.pr-270 {
  padding-right: 270px;
}

.pr-210 {
  padding-right: 210px;
}

.pr-215 {
  padding-right: 215px;
}

.pr-220 {
  padding-right: 220px;
}

.pr-230 {
  padding-right: 230px;
}

.pr-260 {
  padding-right: 260px;
}

.pr-430 {
  padding-right: 430px;
}

.pr-445 {
  padding-right: 445px;
}

/*************************
        Padding bottom
*************************/
.pb-0 {
  padding-bottom: 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .pb-50 {
    padding-bottom: 20px;
  }
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}
@media only screen and (max-width: 767px) {
  .pb-70 {
    padding-bottom: 20px;
  }
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}
@media only screen and (max-width: 767px) {
  .pb-80 {
    padding-bottom: 50px;
  }
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}
@media only screen and (max-width: 767px) {
  .pb-90 {
    padding-bottom: 50px;
  }
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}
@media only screen and (max-width: 767px) {
  .pb-100 {
    padding-bottom: 50px;
  }
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}
@media only screen and (max-width: 767px) {
  .pb-110 {
    padding-bottom: 50px;
  }
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}
@media only screen and (max-width: 767px) {
  .pb-135 {
    padding-bottom: 86px;
  }
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-285 {
  padding-bottom: 285px;
}

.pb-270 {
  padding-bottom: 270px;
}

.pb-290 {
  padding-bottom: 290px;
}

/*************************
        Padding left
*************************/
.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-60 {
  padding-left: 60px;
}

.pl-70 {
  padding-left: 70px;
}

.pl-80 {
  padding-left: 80px;
}

.pl-90 {
  padding-left: 90px;
}

.pl-100 {
  padding-left: 100px;
}

.pl-110 {
  padding-left: 110px;
}

.pl-120 {
  padding-left: 120px;
}

.pl-130 {
  padding-left: 130px;
}

.pl-140 {
  padding-left: 140px;
}

.pl-150 {
  padding-left: 150px;
}

.pl-155 {
  padding-left: 155px;
}

.pl-190 {
  padding-left: 190px;
}

.pl-200 {
  padding-left: 200px;
}

.pl-215 {
  padding-left: 215px;
}

/***************************
    Page section padding 
****************************/
.ptb-0 {
  padding: 0;
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-35 {
  padding: 35px 0;
}

.ptb-32 {
  padding: 32px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-70 {
  padding: 70px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-90 {
  padding: 90px 0;
}

.ptb-100 {
  padding: 100px 0;
}

.ptb-110 {
  padding: 110px 0;
}

.ptb-120 {
  padding: 120px 0;
}

.ptb-130 {
  padding: 130px 0;
}

.ptb-140 {
  padding: 140px 0;
}

.ptb-150 {
  padding: 150px 0;
}

.ptb-160 {
  padding: 160px 0;
}

.ptb-170 {
  padding: 170px 0;
}

.ptb-177 {
  padding: 177px 0;
}

.ptb-180 {
  padding: 180px 0;
}

.ptb-190 {
  padding: 190px 0;
}

.ptb-200 {
  padding: 200px 0;
}

.ptb-210 {
  padding: 210px 0;
}

.ptb-220 {
  padding: 220px 0;
}

.ptb-290 {
  padding: 290px 0;
}

.ptb-310 {
  padding: 310px 0;
}

/***************************
    Page section margin 
****************************/
.mtb-0 {
  margin: 0;
}

.mtb-10 {
  margin: 10px 0;
}

.mtb-15 {
  margin: 15px 0;
}

.mtb-20 {
  margin: 20px 0;
}

.mtb-30 {
  margin: 30px 0;
}

.mtb-40 {
  margin: 40px 0;
}

.mtb-50 {
  margin: 50px 0;
}

.mtb-60 {
  margin: 60px 0;
}

.mtb-70 {
  margin: 70px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.mtb-90 {
  margin: 90px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.mtb-110 {
  margin: 110px 0;
}

.mtb-120 {
  margin: 120px 0;
}

.mtb-130 {
  margin: 130px 0;
}

.mtb-140 {
  margin: 140px 0;
}

.mtb-150 {
  margin: 150px 0;
}

.mtb-290 {
  margin: 290px 0;
}

/*-- 
    - Input Placeholder
-----------------------------------------*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input {
  background: #eceff8;
  border: 2px solid #eceff8;
  height: 45px;
  box-shadow: none;
  padding-left: 10px;
  font-size: 14px;
  color: #737373;
  width: 100%;
}

select {
  width: 100%;
  background: transparent;
  border: 0px solid #eceff8;
  box-shadow: none;
  font-size: 14px;
  color: #737373;
}

option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}

input:focus {
  background: transparent;
}

textarea {
  resize: vertical;
  background: #eceff8;
  border: 2px solid #eceff8;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  color: #737373;
}

textarea:focus {
  background: transparent;
  border: 2px solid #4FC1F0;
  outline: none;
}

button, input, optgroup, select, textarea {
  font-family: "Roboto", sans-serif;
}

.custom-col-5 {
  flex: 0 0 20%;
  max-width: 20%;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
  width: 100%;
  min-height: 1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .custom-col-5 {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .custom-col-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

/*-- 
    Scroll Up 
-----------------------------------------*/
#scrollUp {
  width: 50px;
  height: 50px;
  background-color: #ff5151;
  color: #fff;
  right: 20px;
  bottom: 60px;
  text-align: center;
  overflow: hidden;
  border-radius: 50px;
  z-index: 9811 !important;
}
@media only screen and (max-width: 767px) {
  #scrollUp {
    width: 40px;
    height: 40px;
  }
}
#scrollUp i {
  display: block;
  line-height: 50px;
  font-size: 22px;
}
@media only screen and (max-width: 767px) {
  #scrollUp i {
    line-height: 40px;
    font-size: 16px;
  }
}
#scrollUp:hover i {
  animation-name: fadeInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}

.height-100vh {
  height: 100vh;
}

.transparent-bar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
}

/*--
    - Background Color
------------------------------------------*/
.bg-gray {
  background-color: #f8f8f8;
}

.bg-gray-2 {
  background-color: #ebebeb;
}

.bg-gray-3 {
  background-color: #f7f8f9;
}

.bg-gray-4 {
  background-color: #faf9f9;
}

.bg-black {
  background-color: #010211;
}

.bg-black-2 {
  background-color: #282828;
}

.bg-black-3 {
  background-color: #030512;
}

.bg-black-4 {
  background-color: #333745;
}

.bg-bluegray {
  background-color: #f4f8fa;
}

.bg-red {
  background-color: #ea2e49;
}

.bg-yellow-light {
  background-color: #f3e7cb;
}

.bg-yellow-light-2 {
  background-color: #fffaf3;
}

.default-overlay {
  position: relative;
}
.default-overlay:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  right: 0;
  bottom: 0;
}

.default-btn a {
  font-weight: 500;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1;
  z-index: 1;
}
.default-btn a.btn-color-theme {
  color: #ff5151;
}
.default-btn a.btn-color-theme-bg {
  background-color: #ff5151;
}
.default-btn a.btn-color-white {
  color: #ffffff;
}
.default-btn a.btn-size-md {
  padding: 14px 30px 14px;
}
.default-btn a.btn-size-md-2 {
  padding: 12px 30px 12px;
}
.default-btn a.btn-style-outline {
  background-color: transparent;
  border: 1px solid #ff5151;
}
.default-btn a.border-radious {
  border-radius: 50px;
}
.default-btn a.border-radious-5 {
  border-radius: 5px;
}

.default-btn-2 a {
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
  line-height: 1;
  z-index: 1;
  font-size: 16px;
  letter-spacing: 0.2px;
}
.default-btn-2 a.btn-color-theme {
  color: #010211;
}
.default-btn-2 a.btn-size-md {
  padding: 8px 30px 11px;
}
.default-btn-2 a.btn-style-outline-black {
  background-color: transparent;
  border: 1px solid #010211;
}
.default-btn-2 a.border-radious {
  border-radius: 50px;
}

.btn-hover a {
  position: relative;
  transition: all 0.5s ease-in-out 0s;
}
.btn-hover a:hover {
  border: 1px solid #ff5151;
  color: #fff;
}
.btn-hover a::before, .btn-hover a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: -1;
}
.btn-hover a::after {
  width: 0;
  left: auto;
  right: 0;
  background: #ff5151;
}
.btn-hover a.black-color::after {
  background: #373737;
}
.btn-hover a.white-color::after {
  background: #fff;
}
.btn-hover a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
}
.btn-hover.btn-hover-radious a::before, .btn-hover.btn-hover-radious a::after {
  border-radius: 50px;
}
.btn-hover.hover-border-none a.black-color::after {
  border-radius: 5px;
}
.btn-hover.hover-border-none a:hover {
  border: none;
}

.section-padding-1 .container-fluid {
  padding: 0 185px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-1 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-1 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-1 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-2 .container-fluid {
  padding: 0 100px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-2 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-2 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-2 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-3 .container-fluid {
  padding: 0 150px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-3 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-3 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-3 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

.section-padding-4 .container-fluid {
  padding: 0 250px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-padding-4 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-4 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-4 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

.section-margin-1 {
  margin: 0 100px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-margin-1 {
    margin: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-margin-1 {
    margin: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-margin-1 {
    margin: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-margin-1 {
    margin: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .section-margin-1 {
    margin: 0 15px;
  }
}

.section-margin-2 {
  margin: 0 250px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-margin-2 {
    margin: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-margin-2 {
    margin: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-margin-2 {
    margin: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-margin-2 {
    margin: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .section-margin-2 {
    margin: 0 15px;
  }
}

.section-margin-3 {
  margin: 0 120px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .section-margin-3 {
    margin: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .section-margin-3 {
    margin: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-margin-3 {
    margin: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-margin-3 {
    margin: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .section-margin-3 {
    margin: 0 15px;
  }
}

/*-------- 2. Header style ---------*/
.logo.logo-mrg-2 {
  margin-top: 33px;
}

.logo-border {
  position: relative;
}
.logo-border:before {
  position: absolute;
  right: 40px;
  top: 0px;
  width: 1px;
  background-color: #fff;
  content: "";
  height: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .logo-border:before {
    right: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .logo-border:before {
    right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .logo-border:before {
    right: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .main-header-wrap {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .main-header-wrap {
    display: none;
  }
}

.header-small-mobile {
  display: none;
  padding: 25px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-small-mobile {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .header-small-mobile {
    display: block;
  }
}

.main-menu.menu-other-style {
  margin-left: 9px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-other-style {
    margin-left: 0px;
  }
}
.main-menu.menu-common-style nav ul li {
  display: inline-block;
  position: relative;
  margin-right: 41px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style nav ul li {
    margin-right: 28px;
  }
}
.main-menu.menu-common-style nav ul li:last-child {
  margin-right: 0;
}
.main-menu.menu-common-style nav ul li.angle-shape::before {
  border-bottom: 16px solid #fff;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 0px;
  width: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
  z-index: 9999;
}
.main-menu.menu-common-style nav ul li a {
  font-size: 18px;
  display: inline-block;
  letter-spacing: 0.2px;
}
.main-menu.menu-common-style nav ul li ul.submenu {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  display: block;
  left: 0;
  padding: 28px 30px 28px;
  position: absolute;
  top: 100%;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 190px;
  z-index: 999;
}
.main-menu.menu-common-style nav ul li ul.submenu li {
  margin-bottom: 22px;
  margin-right: 0;
  display: block;
}
.main-menu.menu-common-style nav ul li ul.submenu li:last-child {
  margin-bottom: 0px;
}
.main-menu.menu-common-style nav ul li ul.submenu li a {
  font-size: 14px;
  color: #000000;
  letter-spacing: 0.2px;
  display: block;
  line-height: 1;
  text-transform: capitalize;
  text-align: left;
}
.main-menu.menu-common-style nav ul li ul.submenu li a:hover {
  color: #ff5151;
}
.main-menu.menu-common-style nav ul li ul.mega-menu {
  background-color: #fff;
  display: block;
  left: -273px;
  padding: 23px 30px 67px 0;
  position: absolute;
  text-align: left;
  top: 100%;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  width: 972px;
  z-index: 9999;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style nav ul li ul.mega-menu {
    width: 900px;
    left: -320px;
  }
}
.main-menu.menu-common-style nav ul li ul.mega-menu.mega-menu-mrg-ngtv2 {
  left: -445px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style nav ul li ul.mega-menu.mega-menu-mrg-ngtv2 {
    left: -460px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style nav ul li ul.mega-menu.mega-menu-mrg-ngtv2 {
    left: -520px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style nav ul li ul.mega-menu.mega-menu-mrg-ngtv2 {
    left: -470px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style nav ul li ul.mega-menu.mega-menu-hm4 {
    left: -280px;
  }
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li {
  display: inline-block;
  float: left;
  padding-left: 30px;
  margin-right: 0;
  width: 25%;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li a.menu-title {
  color: #000000;
  font-size: 16px;
  letter-spacing: 0.2px;
  display: block;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 21px;
  line-height: 1;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li a.menu-banner {
  margin-top: 37px;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li a.menu-banner img {
  width: 100%;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li ul {
  margin-top: 30px;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li ul li {
  display: block;
  margin-bottom: 32px;
  margin-right: 0;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li ul li:last-child {
  margin-bottom: 0px;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li ul li a {
  font-size: 14px;
  color: #000000;
  letter-spacing: 0.2px;
  display: block;
  line-height: 1;
  text-transform: capitalize;
}
.main-menu.menu-common-style nav ul li ul.mega-menu > li ul li a:hover {
  color: #ff5151;
}
.main-menu.menu-common-style nav ul li:hover > ul.mega-menu {
  transform: rotateX(0deg);
  visibility: visible;
}
.main-menu.menu-common-style nav ul li:hover > ul.submenu {
  transform: rotateX(0deg);
  visibility: visible;
}
.main-menu.menu-common-style nav ul li:hover a {
  color: #ff5151;
}
.main-menu.menu-common-style nav ul li:hover.angle-shape::before {
  opacity: 1;
  visibility: visible;
}
.main-menu.menu-common-style.menu-lh-1 nav ul li a {
  line-height: 112px;
}
.main-menu.menu-common-style.menu-lh-1 nav ul li ul.submenu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-1 nav ul li ul.mega-menu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-1 nav ul li ul.mega-menu li ul li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-white nav ul li a {
  color: #fff;
}
.main-menu.menu-common-style.menu-font-2 > nav > ul > li > a {
  font-size: 16px;
  font-weight: 500;
  color: #010211;
}
.main-menu.menu-common-style.menu-font-2.menu-font-2-white > nav > ul > li > a {
  color: #fff;
}
.main-menu.menu-common-style.menu-font-2.menu-font-2-white > nav > ul > li:hover > a {
  color: #010211;
}
.main-menu.menu-common-style.menu-font-3 > nav > ul > li > a {
  font-size: 16px;
  font-weight: 500;
  color: #1c1c1c;
}
.main-menu.menu-common-style.menu-margin-2 nav ul li {
  margin-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style.menu-margin-2 nav ul li {
    margin-right: 24px;
  }
}
.main-menu.menu-common-style.menu-margin-2 nav ul li:last-child {
  margin-right: 0;
}
.main-menu.menu-common-style.menu-margin-3 nav ul li {
  margin-right: 120px;
}
.main-menu.menu-common-style.menu-margin-3 nav ul li a {
  color: #010211;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style.menu-margin-3 nav ul li {
    margin-right: 40px;
  }
}
.main-menu.menu-common-style.menu-margin-3 nav ul li:last-child {
  margin-right: 0;
}
.main-menu.menu-common-style.menu-margin-4 {
  position: relative;
  z-index: 9;
}
.main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
  margin-right: 76px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
    margin-right: 38px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
    margin-right: 32px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style.menu-margin-4 > nav > ul > li {
    margin-right: 27px;
  }
}
.main-menu.menu-common-style.menu-margin-4 > nav > ul > li:last-child {
  margin-right: 0;
}
.main-menu.menu-common-style.menu-margin-4 > nav > ul > li:hover > a {
  color: #ff5151;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style.menu-margin-4.menu-others-page {
    text-align: center;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style.menu-margin-4.menu-others-page {
    text-align: center;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style.menu-margin-4.menu-others-page > nav > ul > li {
    margin-right: 65px;
  }
  .main-menu.menu-common-style.menu-margin-4.menu-others-page > nav > ul > li:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style.menu-margin-4.menu-others-page > nav > ul > li {
    margin-right: 50px;
  }
  .main-menu.menu-common-style.menu-margin-4.menu-others-page > nav > ul > li:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style.menu-margin-4.res-hm8-margin > nav > ul > li {
    margin-right: 55px;
  }
  .main-menu.menu-common-style.menu-margin-4.res-hm8-margin > nav > ul > li:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style.menu-margin-4.res-hm8-margin > nav > ul > li {
    margin-right: 60px;
  }
  .main-menu.menu-common-style.menu-margin-4.res-hm8-margin > nav > ul > li:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style.menu-margin-4.res-hm8-margin > nav > ul > li {
    margin-right: 50px;
  }
  .main-menu.menu-common-style.menu-margin-4.res-hm8-margin > nav > ul > li:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style.menu-margin-4.res-hm9-margin > nav > ul > li {
    margin-right: 60px;
  }
  .main-menu.menu-common-style.menu-margin-4.res-hm9-margin > nav > ul > li:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style.menu-margin-4.res-hm9-margin > nav > ul > li {
    margin-right: 55px;
  }
  .main-menu.menu-common-style.menu-margin-4.res-hm9-margin > nav > ul > li:last-child {
    margin-right: 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style.menu-margin-4.res-hm9-margin > nav > ul > li {
    margin-right: 30px;
  }
  .main-menu.menu-common-style.menu-margin-4.res-hm9-margin > nav > ul > li:last-child {
    margin-right: 0;
  }
}
.main-menu.menu-common-style.menu-margin-5 {
  position: relative;
  z-index: 9;
}
.main-menu.menu-common-style.menu-margin-5 > nav > ul > li {
  margin-right: 110px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style.menu-margin-5 > nav > ul > li {
    margin-right: 90px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style.menu-margin-5 > nav > ul > li {
    margin-right: 80px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style.menu-margin-5 > nav > ul > li {
    margin-right: 50px;
  }
}
.main-menu.menu-common-style.menu-margin-5 > nav > ul > li:last-child {
  margin-right: 0;
}
.main-menu.menu-common-style.menu-margin-5 > nav > ul > li:hover > a {
  color: #ff5151;
}
.main-menu.menu-common-style.menu-ngtv-mrg-1 {
  margin-right: -64px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .main-menu.menu-common-style.menu-ngtv-mrg-1 {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .main-menu.menu-common-style.menu-ngtv-mrg-1 {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu.menu-common-style.menu-ngtv-mrg-1 {
    margin-right: 0px;
  }
}
.main-menu.menu-common-style.menu-lh-2 nav ul li a {
  line-height: 100px;
}
.main-menu.menu-common-style.menu-lh-2 nav ul li ul.submenu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-2 nav ul li ul.mega-menu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-2 nav ul li ul.mega-menu li ul li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-3 nav ul li a {
  line-height: 127px;
}
.main-menu.menu-common-style.menu-lh-3 nav ul li ul.submenu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-3 nav ul li ul.mega-menu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-3 nav ul li ul.mega-menu li ul li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-4 nav ul li a {
  line-height: 92px;
}
.main-menu.menu-common-style.menu-lh-4 nav ul li ul.submenu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-4 nav ul li ul.mega-menu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-4 nav ul li ul.mega-menu li ul li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-5 nav ul li a {
  line-height: 55px;
}
.main-menu.menu-common-style.menu-lh-5 nav ul li ul.submenu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-5 nav ul li ul.mega-menu li a {
  line-height: 1;
}
.main-menu.menu-common-style.menu-lh-5 nav ul li ul.mega-menu li ul li a {
  line-height: 1;
}

.stick .main-menu.menu-common-style.menu-lh-1 nav ul li a {
  line-height: 84px;
}
.stick .main-menu.menu-common-style.menu-lh-2 nav ul li a {
  line-height: 84px;
}
.stick .main-menu.menu-common-style.menu-lh-3 nav ul li a {
  line-height: 84px;
}
.stick .main-menu.menu-common-style.menu-font-2.menu-font-2-white > nav > ul > li > a {
  color: #010211;
}
.stick .main-menu.menu-common-style.menu-font-2.menu-font-2-white > nav > ul > li:hover > a {
  color: #ff5151;
}
.stick .logo.mt-40 {
  margin-top: 25px;
}
.stick .logo.mt-45 {
  margin-top: 25px;
}
.stick .logo.logo-mrg-2 {
  margin-top: 24px;
}
.stick .header-right-wrap.mt-40 {
  margin-top: 27px;
}
.stick .header-right-wrap.mt-40.stick-mt-40 {
  margin-top: 20px;
}
.stick .header-right-wrap.mt-35 {
  margin-top: 27px;
}
.stick .category-menu-wrap h3 a {
  color: #373737;
}
.stick .category-menu-wrap h3 a i {
  color: #373737;
}
.stick .category-menu-wrap h3 a img.category-menu-non-stick {
  display: none;
}
.stick .category-menu-wrap h3 a img.category-menu-stick {
  display: inline-block;
}
.stick .category-menu {
  display: none;
}
.stick .sidebar-main-menu a.clickable-mainmenu-active {
  height: 100px;
  line-height: 100px;
}
.stick.pt-10 {
  padding-top: 0;
}
.stick .sidebar-main-menu a.clickable-mainmenu-button {
  width: 120px;
  height: 80px;
  line-height: 80px;
}

.menu-others-page {
  margin-right: -70px;
  margin-left: 48px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .menu-others-page {
    margin-left: 0px;
    margin-right: -65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .menu-others-page {
    margin-left: 0px;
    margin-right: -65px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .menu-others-page {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.header-right-wrap {
  display: flex;
  justify-content: flex-end;
}
.header-right-wrap .common-style button {
  background-color: transparent;
  border: none;
  font-size: 30px;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .header-right-wrap .common-style button {
    font-size: 25px;
  }
}
.header-right-wrap .common-style:hover button {
  color: #ff5151;
}
.header-right-wrap .cart-wrap button {
  display: flex;
  align-items: center;
}
.header-right-wrap .cart-wrap button i {
  line-height: 1;
}
.header-right-wrap .cart-wrap button span {
  font-size: 18px;
  display: block;
  line-height: 1;
  margin-left: 7px;
}
@media only screen and (max-width: 767px) {
  .header-right-wrap .cart-wrap button span {
    font-size: 16px;
    margin-left: 4px;
  }
}
.header-right-wrap .cart-wrap button span.count-theme-color {
  color: #ff5151;
}
.header-right-wrap .cart-wrap button span.mini-cart-price {
  color: #ff5151;
  font-weight: bold;
  letter-spacing: 0.2px;
  margin-right: 3px;
}
.header-right-wrap .cart-wrap button.cart-3 i {
  color: #010211;
  font-size: 22px;
}
.header-right-wrap .cart-wrap-2 button {
  border: none;
  padding: 0;
  background-color: transparent;
  position: relative;
}
.header-right-wrap .cart-wrap-2 button i {
  font-size: 40px;
  color: #010211;
  transition: all 0.3s ease 0s;
}
.header-right-wrap .cart-wrap-2 button span.mini-cart-price-2 {
  color: #ff5151;
  font-weight: bold;
  letter-spacing: 0.2px;
  font-size: 14px;
  margin: 9px 7px 0 0;
}
.header-right-wrap .cart-wrap-2 button span.count-style-2 {
  position: absolute;
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  display: block;
  bottom: -2px;
  right: -6px;
  width: 20px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
  background-color: #ff5151;
  font-weight: bold;
}
.header-right-wrap .cart-wrap-2 button:hover i {
  color: #ff5151;
}
.header-right-wrap .search-wrap-2 button {
  border: none;
  padding: 0;
  background-color: transparent;
}
.header-right-wrap .search-wrap-2 button i {
  color: #010211;
  font-size: 22px;
  transition: all 0.3s ease 0s;
}
.header-right-wrap .search-wrap-2 button i:hover {
  color: #ff5151;
}
.header-right-wrap .search-wrap-2.search-wrap-2-mrg {
  margin: 14px 0px 0 0px;
}
.header-right-wrap .setting-wrap a {
  display: flex;
  align-items: center;
  color: #010211;
  letter-spacing: 0.2px;
}
.header-right-wrap .setting-wrap a i {
  font-size: 22px;
  margin: 0 8px 0 0;
}
.header-right-wrap .setting-wrap a:hover {
  color: #ff5151;
}
.header-right-wrap .setting-wrap.setting-wrap-mrg {
  margin: 14px 80px 0 78px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .setting-wrap.setting-wrap-mrg {
    margin: 14px 35px 0 33px;
  }
}
.header-right-wrap .setting-wrap .setting-content {
  background: #fff none repeat scroll 0 0;
  padding: 26px 30px 32px;
  position: absolute;
  right: 0;
  top: 188%;
  width: 300px;
  z-index: 9999;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  max-height: 636px;
  overflow-y: auto;
  display: none;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-right-wrap .setting-wrap .setting-content {
    max-height: 510px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap .setting-wrap .setting-content {
    max-height: 400px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-wrap .setting-wrap .setting-content {
    max-height: 400px;
  }
}
.header-right-wrap .setting-wrap .setting-content ul li {
  padding-bottom: 30px;
}
.header-right-wrap .setting-wrap .setting-content ul li:last-child {
  padding-bottom: 0px;
}
.header-right-wrap .setting-wrap .setting-content ul li h4 {
  font-size: 16px;
  margin: 0;
  color: #000000;
  padding-bottom: 19px;
  border-bottom: 1px solid #cccccc;
  font-weight: 500;
}
.header-right-wrap .setting-wrap .setting-content ul li ul {
  padding-top: 18px;
}
.header-right-wrap .setting-wrap .setting-content ul li ul li {
  padding-bottom: 25px;
}
.header-right-wrap .setting-wrap .setting-content ul li ul li:last-child {
  padding-bottom: 0px;
}
.header-right-wrap .setting-wrap .setting-content ul li ul li a {
  letter-spacing: 0.2px;
  color: #000000;
  font-weight: 300;
  display: block;
  line-height: 1;
}
.header-right-wrap .setting-wrap .setting-content ul li ul li a:hover {
  color: #ff5151;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-wrap .search-wrap.mr-45 {
    margin-right: 25px;
  }
}
.header-right-wrap.header-right-white .common-style button {
  color: #fff;
}
.header-right-wrap .header-login {
  margin-top: -2px;
}
.header-right-wrap .header-login a {
  font-size: 22px;
  color: #010211;
}
.header-right-wrap .header-login a:hover {
  color: #ff5151;
}
.header-right-wrap .header-wishlist {
  margin-top: -2px;
}
.header-right-wrap .header-wishlist a {
  font-size: 22px;
  color: #010211;
}
.header-right-wrap .header-wishlist a:hover {
  color: #ff5151;
}

.border-style {
  position: relative;
}
.border-style::before {
  background-color: #9c9687;
  position: absolute;
  height: 20px;
  width: 1px;
  content: "";
  right: -39px;
  top: -1px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .border-style::before {
    right: -18px;
  }
}

.shopping-cart-content {
  background: #fff none repeat scroll 0 0;
  padding: 28px 20px 20px;
  position: absolute;
  right: 15px;
  top: 100%;
  width: 300px;
  z-index: 9999;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  max-height: 591px;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease 0s;
  transform-origin: center top 0;
  transform: rotateX(90deg);
  text-align: left;
}
.shopping-cart-content.show {
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .shopping-cart-content {
    max-height: 400px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shopping-cart-content {
    overflow-y: auto;
    height: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shopping-cart-content {
    overflow-y: auto;
    height: 350px;
    margin-top: 18px;
    top: 100%;
    margin-top: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .shopping-cart-content {
    width: 295px;
    right: 15px;
    padding: 31px 15px 10px;
    overflow-y: auto;
    height: 350px;
    top: 100%;
    margin-top: 14px;
  }
}
.shopping-cart-content .shopping-cart-top {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cccccc;
  padding-bottom: 12px;
  margin-bottom: 20px;
}
.shopping-cart-content .shopping-cart-top h4 {
  font-size: 12px;
  margin: 0;
  line-height: 1;
  color: #282828;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
}
.shopping-cart-content .shopping-cart-top a {
  color: #282828;
  font-size: 12px;
  margin-top: -6px;
}
.shopping-cart-content .shopping-cart-top a:hover {
  color: #ff5151;
}
.shopping-cart-content ul li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.shopping-cart-content ul li .shopping-cart-img {
  flex: 0 0 70px;
  margin-right: 10px;
}
.shopping-cart-content ul li .shopping-cart-img img {
  width: 100%;
}
.shopping-cart-content ul li .shopping-cart-title h4 {
  font-size: 14px;
  margin: 0 0 3px;
}
.shopping-cart-content ul li .shopping-cart-title h4 a {
  color: #000000;
  line-height: 1;
  display: block;
}
.shopping-cart-content ul li .shopping-cart-title h4 a:hover {
  color: #ff5151;
}
.shopping-cart-content ul li .shopping-cart-title span {
  font-size: 16px;
  font-weight: 500;
  color: #373737;
}
.shopping-cart-content ul li:last-child {
  margin-bottom: 0;
}
.shopping-cart-content ul li .shopping-cart-delete {
  display: flex;
  flex-grow: 100;
  justify-content: flex-end;
}
.shopping-cart-content ul li .shopping-cart-delete a {
  font-size: 16px;
  color: #282828;
  margin-top: -4px;
}
.shopping-cart-content ul li .shopping-cart-delete a:hover {
  color: #ff5151;
}
.shopping-cart-content .shopping-cart-total {
  margin: 50px 0 20px;
  border-bottom: 1px solid #cccccc;
  padding: 0px 0px 17px;
}
.shopping-cart-content .shopping-cart-total h4 {
  font-size: 20px;
  margin: 0;
}
.shopping-cart-content .shopping-cart-total h4 span {
  float: right;
}
.shopping-cart-content .shopping-cart-btn a {
  margin-bottom: 10px;
  padding: 16px 40px 17px;
  font-size: 16px;
  display: block;
  text-transform: capitalize;
  background-color: #ff7474;
  color: #ffffff;
  border: 1px solid transparent;
}
.shopping-cart-content .shopping-cart-btn a:hover {
  border: 1px solid #373737;
}

.header-right-border {
  position: relative;
}
.header-right-border:before {
  position: absolute;
  left: 19px;
  top: 0px;
  width: 1px;
  background-color: #fff;
  content: "";
  height: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-right-border:before {
    left: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-right-border:before {
    left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-right-border:before {
    left: 0px;
  }
}

.sticky-bar.stick {
  animation: 700ms ease-in-out 0s normal none 1 running fadeInDown;
  background-color: #fff;
  border-bottom: 0 solid #4a90e2;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  left: 0;
  transition: all 0.3s ease 0s;
}
.sticky-bar.bg-black {
  background-color: #010211;
}

.mobile-off-canvas-active {
  position: fixed;
  top: 0;
  width: 330px;
  min-height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -330px;
  visibility: hidden;
  opacity: 1;
  transition: all 400ms ease-out;
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}
@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active {
    width: 260px;
    right: -260px;
  }
}
.mobile-off-canvas-active.inside {
  visibility: visible;
  opacity: 1;
  right: 0px;
}
.mobile-off-canvas-active .mobile-aside-close {
  position: absolute;
  left: -40px;
  top: 0px;
  z-index: 9;
  width: 40px;
  height: 40px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  background-color: #343538;
}
.mobile-off-canvas-active .mobile-aside-close i {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  color: #fff;
}
.mobile-off-canvas-active .mobile-aside-close:hover i {
  color: #ff5151;
  transform: rotate(90deg);
}
.mobile-off-canvas-active .header-mobile-aside-wrap {
  padding: 40px 30px 50px;
  overflow: auto;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap {
    padding: 30px 20px 50px;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search {
  border-bottom: 1px solid #ddd;
  margin-bottom: 27px;
  padding-bottom: 40px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form {
  position: relative;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form input {
  background-color: #f6f6f6;
  border: none;
  border-radius: 0px;
  height: 50px;
  padding: 0 60px 0 15px;
  width: 100%;
  font-size: 14px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
  background-color: transparent;
  border-color: #ddd;
  border-image: none;
  border-radius: 5px 0 0 5px;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  color: #000000;
  font-size: 18px;
  height: 100%;
  padding: 0 15px 0 14px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button {
    font-size: 16px;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button:hover {
  color: #ff5151;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-search form button i {
  margin-top: 5px;
  display: inline-block;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 36px;
  padding-bottom: 27px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav {
  height: 100%;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li {
  display: block;
  position: relative;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -5px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 10px;
  margin-top: 25px;
  border-bottom: 1px solid #1f2226;
  transition: all 250ms ease-out;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #1f2226;
  transition: 0.4s;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li a {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 10px 0;
  font-family: "Roboto", sans-serif;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a {
  padding: 10px 15px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 300;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li a:hover {
  color: #ff5151;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-menu-wrap .mobile-navigation nav .mobile-menu li ul li ul li a {
  padding: 10px 30px 5px;
  font-size: 14px;
  color: #000000;
  font-weight: 300;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 31px;
  padding-bottom: 37px;
}
@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap {
    display: block;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
  position: relative;
  margin-bottom: 15px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
  margin-bottom: 0;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a {
  font-size: 16px;
  display: block;
  letter-spacing: 0.2px;
  font-family: "Roboto", sans-serif;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang a i {
  float: right;
  font-size: 10px;
  position: relative;
  top: 8px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown {
  margin-top: 5px;
  display: none;
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  padding: 22px 30px 26px;
  width: 100%;
  z-index: 11;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li {
  padding-bottom: 10px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li:last-child {
  padding-bottom: 0px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a {
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang .lang-curr-dropdown ul li a:hover {
  color: #ff5151;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:hover > a {
  color: #ff5151;
}
@media only screen and (max-width: 767px) {
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang {
    margin-bottom: 5px;
  }
  .mobile-off-canvas-active .header-mobile-aside-wrap .mobile-curr-lang-wrap .single-mobile-curr-lang:last-child {
    margin-bottom: 0;
  }
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap {
  margin-bottom: 20px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a {
  font-size: 16px;
  margin-right: 20px;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a:last-child {
  margin-right: 0;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.facebook {
  color: #3b5999;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.twitter {
  color: #55acee;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.pinterest {
  color: #bd081c;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.instagram {
  color: #e4405f;
}
.mobile-off-canvas-active .header-mobile-aside-wrap .mobile-social-wrap a.google {
  color: #dd4b39;
}

.header-small-mobile .header-right-wrap {
  align-items: center;
}
.header-small-mobile .header-right-wrap .mobile-off-canvas {
  margin-left: 20px;
  margin-top: 4px;
}
@media only screen and (max-width: 767px) {
  .header-small-mobile .header-right-wrap .mobile-off-canvas {
    margin-left: 12px;
  }
}
.header-small-mobile .header-right-wrap .mobile-off-canvas a {
  font-size: 12px;
}
.header-small-mobile.header-mobile-white .header-right-wrap .cart-wrap button i {
  color: #ffffff;
}
.header-small-mobile.header-mobile-white .header-right-wrap .cart-wrap button span {
  color: #ffffff;
}
.header-small-mobile.header-mobile-white .header-right-wrap .mobile-off-canvas a {
  color: #ffffff;
}

.main-wrapper .body-overlay, .main-wrapper .body-overlay-2, .main-wrapper-2 .body-overlay, .main-wrapper-2 .body-overlay-2 {
  background: rgba(35, 35, 36, 0.7) none repeat scroll 0 0;
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out 0s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}
.main-wrapper.overlay-active .body-overlay, .main-wrapper.overlay-active-2 .body-overlay-2, .main-wrapper-2.overlay-active .body-overlay, .main-wrapper-2.overlay-active-2 .body-overlay-2 {
  opacity: 1;
  visibility: visible;
}

.main-search-active {
  background: rgba(0, 0, 0, 0.95) none repeat scroll 0 0;
  color: #353535;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 32px 46px 39px;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(110%);
  transition: transform 0.5s ease-in-out 0s, -webkit-transform 0.5s ease-in-out 0s;
  width: 100%;
  z-index: 9999;
}
.main-search-active.inside {
  transform: translateX(0px);
  z-index: 9999;
}
.main-search-active .sidebar-search-icon {
  display: block;
  overflow: hidden;
  position: absolute;
  right: 50%;
  top: 50px;
  transform: translateX(50%);
}
.main-search-active .sidebar-search-icon button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 35px;
  line-height: 1;
  padding: 0;
  transition: all 0.3s ease 0s;
}
.main-search-active .sidebar-search-icon button:hover {
  color: #ff5151;
}
.main-search-active .sidebar-search-input {
  padding: 300px 0 0;
}
.main-search-active .sidebar-search-input .form-search {
  position: relative;
}
.main-search-active .sidebar-search-input .form-search input {
  background-color: transparent;
  border-color: #6e6e6e;
  border-style: solid;
  border-width: 0 0 1px;
  color: #c0c0c0;
  display: block;
  font-size: 20px;
  height: 50px;
  line-height: 62px;
  padding: 0;
  width: 770px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  padding: 0 55px 0 0;
}
.main-search-active .sidebar-search-input .form-search input::-moz-input-placeholder {
  color: #c0c0c0;
  opacity: 1;
}
.main-search-active .sidebar-search-input .form-search input::-webkit-input-placeholder {
  color: #c0c0c0;
  opacity: 1;
}
.main-search-active .sidebar-search-input .form-search button {
  background-color: transparent;
  border: medium none;
  color: #ffffff;
  cursor: pointer;
  font-size: 24px;
  padding: 0;
  position: absolute;
  right: 0;
  top: 13px;
  transition: all 0.3s ease 0s;
}
.main-search-active .sidebar-search-input .form-search button:hover {
  color: #ff5151;
}

.header-padding-1 .container-fluid {
  padding: 0 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-padding-1 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-1 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-1 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .header-padding-1 .container-fluid {
    padding: 0 15px;
  }
}

.header-padding-2 .container-fluid {
  padding: 0 100px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-padding-2 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-padding-2 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-2 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-2 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .header-padding-2 .container-fluid {
    padding: 0 15px;
  }
}

.header-padding-3 .container-fluid {
  padding: 0 150px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-padding-3 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-padding-3 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-3 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-3 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .header-padding-3 .container-fluid {
    padding: 0 15px;
  }
}

.header-padding-4 .container-fluid {
  padding: 0 250px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-padding-4 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-padding-4 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-4 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-4 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .header-padding-4 .container-fluid {
    padding: 0 15px;
  }
}

.header-padding-5 .container-fluid {
  padding: 0 120px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-padding-5 .container-fluid {
    padding: 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-padding-5 .container-fluid {
    padding: 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-5 .container-fluid {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-padding-5 .container-fluid {
    padding: 0 45px;
  }
}
@media only screen and (max-width: 767px) {
  .header-padding-5 .container-fluid {
    padding: 0 15px;
  }
}

.header-padding-6 .container-fluid {
  padding: 0 0px 0 100px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .header-padding-6 .container-fluid {
    padding: 0 0px 0 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .header-padding-6 .container-fluid {
    padding: 0 0px 0 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-padding-6 .container-fluid {
    padding: 0 0px 0 30px;
  }
}

.search-style-2 .form-search {
  position: relative;
}
.search-style-2 .form-search input {
  font-size: 14px;
  color: #8b8b8b;
  letter-spacing: 0.2px;
  background-color: transparent;
  border: none;
  padding: 2px 25px;
  height: 35px;
}
.search-style-2 .form-search button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  font-size: 18px;
  color: #010211;
  padding: 0;
  border: none;
  background-color: transparent;
  transition: all 0.3s ease 0s;
}
.search-style-2 .form-search button:hover {
  color: #ff5151;
}

.welcome p {
  font-size: 16px;
  color: #c7c7c7;
  margin: 0;
  letter-spacing: 0.2px;
}

.setting-wrap-2 {
  display: flex;
  justify-content: flex-end;
}
.setting-wrap-2 .setting-content2-left {
  position: relative;
  margin-right: 80px;
}
.setting-wrap-2 .setting-content2-left a {
  font-size: 16px;
  color: #c7c7c7;
  letter-spacing: 0.2px;
}
.setting-wrap-2 .setting-content2-left a i {
  color: #c7c7c7;
  font-size: 13px;
}
.setting-wrap-2 .setting-content2-left .currency-dropdown {
  background: #fff none repeat scroll 0 0;
  box-shadow: 0 1px 7px -2px rgba(0, 0, 0, 0.3);
  left: 0;
  padding: 8px 12px 10px;
  position: absolute;
  text-align: left;
  top: 160%;
  width: 100px;
  z-index: 220;
  display: none;
}
.setting-wrap-2 .setting-content2-left .currency-dropdown ul li {
  padding: 0 0 5px;
}
.setting-wrap-2 .setting-content2-left .currency-dropdown ul li:last-child {
  padding: 0 0 0px;
}
.setting-wrap-2 .setting-content2-left .currency-dropdown ul li a {
  font-size: 14px;
  color: #030512;
  text-transform: uppercase;
}
.setting-wrap-2 .setting-content2-left .currency-dropdown ul li a:hover {
  color: #ff5151;
}
.setting-wrap-2 .setting-content2-left:hover a {
  color: #ff5151;
}
.setting-wrap-2 .setting-content2-right a {
  font-size: 16px;
  color: #c7c7c7;
  letter-spacing: 0.2px;
  margin-left: 46px;
}
.setting-wrap-2 .setting-content2-right a:hover {
  color: #ff5151;
}
.setting-wrap-2 .setting-content2-right a:first-child {
  margin-left: 0;
}

.header-contact-search-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-contact-search-wrap .header-contact {
    margin-left: 20px;
  }
}
.header-contact-search-wrap .header-contact ul {
  display: flex;
}
.header-contact-search-wrap .header-contact ul li {
  color: #c7c7c7;
  font-size: 16px;
  letter-spacing: 0.2px;
  margin-right: 98px;
}
.header-contact-search-wrap .header-contact ul li:last-child {
  margin-right: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-contact-search-wrap .header-contact ul li {
    margin-right: 30px;
  }
}
.header-contact-search-wrap .header-contact ul li i {
  font-size: 22px;
  margin-right: 7px;
  top: 2px;
  position: relative;
}
.header-contact-search-wrap .header-contact ul li a {
  color: #c7c7c7;
}
.header-contact-search-wrap .header-contact ul li a:hover {
  color: #ff5151;
}
.header-contact-search-wrap .header-contact-2 {
  display: flex;
}
.header-contact-search-wrap .header-contact-2 .header-contact-2-icon {
  margin: 0 11px 0 0;
}
.header-contact-search-wrap .header-contact-2 .header-contact-2-icon i {
  font-size: 22px;
  color: #ea2e49;
}
.header-contact-search-wrap .header-contact-2 .header-contact-2-text span, .header-contact-search-wrap .header-contact-2 .header-contact-2-text p {
  font-size: 16px;
  color: #030512;
  display: block;
  line-height: 1;
  letter-spacing: 0.2px;
}
.header-contact-search-wrap .header-contact-2 .header-contact-2-text p {
  margin: 6px 0 0;
}
.header-contact-search-wrap .search-style-3 .form-search-3 {
  position: relative;
}
.header-contact-search-wrap .search-style-3 .form-search-3 input {
  background-color: #000;
  border: 1px solid #282828;
  height: 50px;
  border-radius: 50px;
  font-weight: 300;
  color: #c7c7c7;
  font-size: 13px;
  width: 400px;
  padding: 2px 60px 2px 29px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-contact-search-wrap .search-style-3 .form-search-3 input {
    width: 285px;
  }
}
.header-contact-search-wrap .search-style-3 .form-search-3 button {
  border: none;
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
  font-size: 22px;
  color: #c7c7c7;
  transition: all 0.3s ease 0s;
}
.header-contact-search-wrap .search-style-3 .form-search-3 button:hover {
  color: #ff5151;
}
.header-contact-search-wrap .search-style-4 .form-search-4 {
  position: relative;
}
.header-contact-search-wrap .search-style-4 .form-search-4 input {
  background-color: #f2f2f2;
  border: 1px solid #282828;
  height: 50px;
  border-radius: 50px;
  font-weight: 300;
  color: #030512;
  font-size: 13px;
  width: 400px;
  padding: 2px 60px 2px 29px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-contact-search-wrap .search-style-4 .form-search-4 input {
    width: 285px;
  }
}
.header-contact-search-wrap .search-style-4 .form-search-4 button {
  border: none;
  background-color: transparent;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
  font-size: 22px;
  color: #030512;
  transition: all 0.3s ease 0s;
}
.header-contact-search-wrap .search-style-4 .form-search-4 button:hover {
  color: #ff5151;
}
.header-contact-search-wrap.header-contact-search-mrg {
  margin: 0 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-contact-search-wrap.header-contact-search-mrg {
    margin: 0 30px;
  }
}

.cart-wrap-3 {
  text-align: right;
}
.cart-wrap-3 button {
  border: none;
  background-color: transparent;
  padding: 0;
  position: relative;
  transition: all 0.3s ease 0s;
}
.cart-wrap-3 button i {
  font-size: 30px;
  color: #fff;
  margin-right: 25px;
  transition: all 0.3s ease 0s;
}
.cart-wrap-3 button span.mini-cart-price-3 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
  color: #ff5151;
  letter-spacing: 0.2px;
}
.cart-wrap-3 button span.count-style-3 {
  position: absolute;
  font-size: 12px;
  height: 25px;
  line-height: 24px;
  color: #fff;
  display: block;
  top: 2px;
  right: 1px;
  width: 25px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #fff;
}
.cart-wrap-3 button:hover i {
  color: #ff5151;
}
.cart-wrap-3.cart-wrap-3-white button i {
  color: #030512;
}
.cart-wrap-3.cart-wrap-3-white button span.mini-cart-price-3 {
  color: #ea2e49;
}
.cart-wrap-3.cart-wrap-3-white button span.count-style-3 {
  color: #030512;
  border: 1px solid #030512;
}
.cart-wrap-3.cart-wrap-hm9 button i {
  margin-right: 36px;
}

.category-menu-wrap {
  position: relative;
}
.category-menu-wrap h3 {
  font-size: 14px;
  color: #fff;
  letter-spacing: 0.2px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
}
.category-menu-wrap h3 a {
  color: #fff;
}
.category-menu-wrap h3 a img {
  margin-right: 32px;
}
.category-menu-wrap h3 a img.category-menu-stick {
  display: none;
}
.category-menu-wrap h3 a i {
  margin-left: 11px;
}

.category-menu {
  border: 1px solid #77c4d3;
  width: 290px;
  position: absolute;
  top: 205%;
  left: 0;
  z-index: 9;
  background-color: #fff;
  border-radius: 5px;
  padding: 23px 0 25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 767px) {
  .category-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}
.category-menu nav > ul > li {
  margin-bottom: 23px;
  position: relative;
}
.category-menu nav > ul > li:last-child {
  margin-bottom: 0px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu nav > ul > li {
    margin-bottom: 8px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu nav > ul > li {
    margin-bottom: 8px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav > ul > li {
    margin-bottom: 6px;
  }
}
.category-menu nav > ul > li > a {
  color: #333745;
  padding: 0 40px;
  display: block;
}
.category-menu nav > ul > li > a span {
  float: right;
  font-size: 14px;
  color: #616161;
  position: relative;
  top: 1px;
}
.category-menu nav > ul > li .category-menu-dropdown {
  position: absolute;
  left: 100%;
  top: -22px;
  width: 570px;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px 52px 40px 30px;
  margin-left: 1px;
  transform: rotateX(90deg);
  transform-origin: center top 0;
  transition: all 0.5s ease 0s;
  visibility: hidden;
  z-index: 99;
  box-shadow: 0 0 30px 0.4px rgba(0, 0, 0, 0.1);
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu {
  display: inline-block;
  width: 50%;
  float: left;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.ct-menu-mrg-bottom {
  margin-bottom: 74px;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.ct-menu-mrg-left {
  padding-left: 38px;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.category-menu-border {
  position: relative;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu.category-menu-border:before {
  position: absolute;
  content: "";
  bottom: -36px;
  left: 0;
  background-color: #d6d7da;
  height: 1px;
  width: 180px;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu h4 {
  color: #333745;
  font-weight: 500;
  font-size: 14px;
  margin: 0 0 18px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li {
  padding: 0 0 9px;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li:last-child {
  padding: 0 0 0px;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li a {
  color: #333745;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu ul li a:hover {
  color: #ea2e49;
}
.category-menu nav > ul > li .category-menu-dropdown .single-category-menu > a img {
  width: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-menu nav > ul > li .category-menu-dropdown.ct-menu-res-height-1 {
    height: 280px;
    overflow-y: auto;
  }
}
.category-menu nav > ul > li .cr-menu-desktop-none {
  display: none;
  padding: 15px 0 0 60px;
}
.category-menu nav > ul > li .cr-menu-desktop-none li {
  padding: 0 0 6px;
}
.category-menu nav > ul > li .cr-menu-desktop-none li:last-child {
  padding: 0 0 0px;
}
.category-menu nav > ul > li .cr-menu-desktop-none li a {
  display: block;
  padding: 0 40px 0 0;
}
.category-menu nav > ul > li .cr-menu-desktop-none li a i {
  float: right;
  font-size: 12px;
}
.category-menu nav > ul > li .cr-menu-desktop-none li ul {
  padding: 10px 0 0;
}
.category-menu nav > ul > li .cr-menu-desktop-none li ul li a {
  padding: 0 0px 0 20px;
}
.category-menu nav > ul > li:hover > a, .category-menu nav > ul > li:hover > a span {
  color: #ea2e49;
}
.category-menu nav > ul > li:hover .category-menu-dropdown {
  transform: rotateX(0deg);
  visibility: visible;
}

.ct-menu-small-device {
  display: none;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ct-menu-small-device {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .ct-menu-small-device {
    display: block;
  }
}
.ct-menu-small-device .category-menu-wrap h3 {
  display: flex;
  justify-content: center;
}
.ct-menu-small-device .category-menu-wrap .category-menu {
  display: none;
}

.sidebar-main-menu {
  text-align: right;
}
.sidebar-main-menu a.clickable-mainmenu-button {
  width: 120px;
  height: 150px;
  background-color: #232323;
  line-height: 150px;
  display: inline-block;
  text-align: center;
}

.clickable-mainmenu-active {
  position: fixed;
  top: 0;
  width: 330px;
  height: 100vh;
  bottom: 0;
  z-index: 111;
  right: -330px;
  visibility: hidden;
  opacity: 1;
  transition: all 400ms ease-out;
  background-color: #fff;
  box-shadow: 0 0 87px 0 rgba(0, 0, 0, 0.09);
  z-index: 9999;
}
@media only screen and (max-width: 767px) {
  .clickable-mainmenu-active {
    width: 260px;
    right: -260px;
  }
}
.clickable-mainmenu-active.inside {
  visibility: visible;
  opacity: 1;
  right: 0px;
}
.clickable-mainmenu-active .clickable-mainmenu-close {
  position: absolute;
  left: -60px;
  top: 0px;
  z-index: 9;
  width: 60px;
  height: 60px;
  color: #fff;
  line-height: 60px;
  text-align: center;
  font-size: 25px;
  background-color: #343538;
}
.clickable-mainmenu-active .clickable-mainmenu-close i {
  transition: all 0.3s ease-in-out;
  display: inline-block;
  color: #fff;
}
.clickable-mainmenu-active .clickable-mainmenu-close:hover i {
  color: #ff5151;
  transform: rotate(90deg);
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap {
  padding: 70px 30px 80px;
  overflow: auto;
  height: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .clickable-mainmenu-active .click-mainmenu-aside-wrap {
    padding: 30px 20px 80px;
  }
}
@media only screen and (max-width: 767px) {
  .clickable-mainmenu-active .click-mainmenu-aside-wrap {
    padding: 30px 20px 50px;
  }
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 36px;
  padding-bottom: 27px;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav {
  height: 100%;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li {
  display: block;
  position: relative;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li.menu-item-has-children .menu-expand {
  line-height: 50;
  top: -1px;
  left: 95%;
  width: 30px;
  position: absolute;
  height: 50px;
  text-align: center;
  cursor: pointer;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li.menu-item-has-children .menu-expand i {
  display: block;
  position: relative;
  width: 15px;
  margin-top: 25px;
  border-bottom: 1px solid #000;
  transition: all 250ms ease-out;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li.menu-item-has-children .menu-expand i::before {
  top: 0;
  width: 100%;
  content: "";
  display: block;
  position: absolute;
  transform: rotate(90deg);
  border-bottom: 1px solid #000;
  transition: 0.4s;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li.menu-item-has-children.active > .menu-expand i::before {
  transform: rotate(0);
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li a {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 18px;
  position: relative;
  display: inline-block;
  padding: 15px 0;
  font-family: "Roboto", sans-serif;
  color: #000;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li a:hover {
  color: #ff5151;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li ul li a {
  padding: 7px 20px 7px;
  font-size: 14px;
  color: #222;
  font-weight: 400;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li ul li a:hover {
  color: #ff5151;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-wrap nav .sidebar-menu li ul li ul li a {
  padding: 10px 20px 5px 40px;
  font-size: 14px;
  color: #000000;
  font-weight: 300;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap {
  margin-bottom: 20px;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap a {
  font-size: 16px;
  margin-right: 20px;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap a:last-child {
  margin-right: 0;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap a.facebook {
  color: #3b5999;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap a.twitter {
  color: #55acee;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap a.pinterest {
  color: #bd081c;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap a.instagram {
  color: #e4405f;
}
.clickable-mainmenu-active .click-mainmenu-aside-wrap .click-mainmenu-social-wrap a.google {
  color: #dd4b39;
}

/*-------- 3. Slider style ---------*/
.slider-area {
  position: relative;
}
.slider-area .single-slider {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .slider-area .single-slider {
    display: block;
    align-items: inherit;
    padding: 0px 0 70px;
  }
  .slider-area .single-slider.height-100vh {
    height: auto;
  }
}
.slider-area .nav-style-1 .owl-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}
.slider-area .nav-style-1 .owl-nav button {
  display: block;
}
.slider-area .nav-style-1 .owl-nav .owl-prev {
  margin-bottom: 62px;
  transition: all 0.3s ease 0s;
}
.slider-area .nav-style-1 .owl-nav .owl-prev:hover {
  color: #ff5151;
}
@media only screen and (max-width: 767px) {
  .slider-area .nav-style-1 .owl-nav .owl-prev {
    margin-bottom: 35px;
  }
}
.slider-area .nav-style-1 .owl-nav .owl-next {
  margin-top: 63px;
  transition: all 0.3s ease 0s;
}
.slider-area .nav-style-1 .owl-nav .owl-next:hover {
  color: #ff5151;
}
@media only screen and (max-width: 767px) {
  .slider-area .nav-style-1 .owl-nav .owl-next {
    margin-top: 35px;
  }
}
.slider-area .nav-style-1.nav-dot-left .owl-nav {
  left: 50px;
}
@media only screen and (max-width: 767px) {
  .slider-area .nav-style-1.nav-dot-left .owl-nav {
    left: 15px;
  }
}
.slider-area .dot-style-1 .owl-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 50px;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .slider-area .dot-style-1 .owl-dots {
    left: 15px;
  }
}
.slider-area .dot-style-1 .owl-dots .owl-dot {
  display: none;
  font-size: 20px;
}
.slider-area .dot-style-1 .owl-dots .owl-dot.active {
  display: block;
}
.slider-area .dot-style-1 .owl-dots.nav-dot-left .owl-dots {
  left: 50px;
}
.slider-area .nav-style-3 .owl-nav {
  position: absolute;
  left: 0;
  bottom: 13px;
}
.slider-area .nav-style-3 .owl-nav button {
  display: inline-block;
  font-size: 30px;
  width: 63px;
  height: 75px;
  background-color: #ffffff;
  margin-right: 4px;
  line-height: 75px;
  color: #010211;
  text-align: center;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 767px) {
  .slider-area .nav-style-3 .owl-nav button {
    line-height: 50px;
    height: 50px;
    width: 48px;
    font-size: 25px;
  }
}
.slider-area .nav-style-3 .owl-nav button:hover {
  color: #ff5151;
}
.slider-area .nav-style-4 .owl-nav {
  position: absolute;
  right: 7px;
  bottom: 8px;
}
.slider-area .nav-style-4 .owl-nav button {
  display: inline-block;
  font-size: 24px;
  width: 65px;
  height: 50px;
  margin: 0 1px;
  line-height: 50px;
  color: #585e66;
  text-align: center;
  transition: all 0.3s ease 0s;
  background-color: #d1d9e9;
}
@media only screen and (max-width: 767px) {
  .slider-area .nav-style-4 .owl-nav button {
    line-height: 50px;
    height: 50px;
    width: 48px;
    font-size: 25px;
  }
}
.slider-area .nav-style-4 .owl-nav button:hover {
  color: #ff5151;
}
.slider-area .nav-style-5 .owl-nav {
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.slider-area .nav-style-5 .owl-nav button {
  display: inline-block;
  font-size: 24px;
  width: 65px;
  height: 50px;
  margin: 0 1px;
  line-height: 50px;
  color: #585e66;
  text-align: center;
  transition: all 0.3s ease 0s;
  background-color: #edf5f4;
}
@media only screen and (max-width: 767px) {
  .slider-area .nav-style-5 .owl-nav button {
    line-height: 50px;
    height: 50px;
    width: 48px;
    font-size: 25px;
  }
}
.slider-area .nav-style-5 .owl-nav button:hover {
  color: #ff5151;
}
.slider-content-1 {
  margin-top: 65px;
}
.slider-content-1 h3 {
  font-size: 80px;
  font-family: "Marck Script", cursive;
  color: #ff5151;
  margin: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 h3 {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 h3 {
    font-size: 65px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-1 h3 {
    font-size: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-1 h3 {
    font-size: 45px;
  }
}
.slider-content-1 h1 {
  font-size: 70px;
  font-weight: bold;
  margin: 29px 0 14px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 h1 {
    font-size: 55px;
    margin: 20px 0 14px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1 h1 {
    font-size: 50px;
    margin: 10px 0 14px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-1 h1 {
    font-size: 40px;
    margin: 16px 0 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-1 h1 {
    font-size: 32px;
    margin: 10px 0 10px;
  }
}
.slider-content-1 p {
  font-size: 22px;
  margin: 0px 0 44px;
  font-family: "Poppins", sans-serif;
  color: #010225;
  letter-spacing: 0.2px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-1 p {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-1 p {
    font-size: 18px;
    margin: 0px 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-1.ml-70 {
    margin-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-1 {
    margin-top: 35px;
    text-align: center;
  }
  .slider-content-1.ml-70 {
    margin-left: 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-1 {
    margin-top: 70px;
    text-align: left;
  }
}

.slider-sin-img-hm1.slider-sin-mrg1 {
  margin-left: -205px;
  margin-right: -35px;
  margin-top: 140px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-sin-img-hm1.slider-sin-mrg1 {
    margin-left: -80px;
    margin-right: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-sin-img-hm1.slider-sin-mrg1 {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-sin-img-hm1.slider-sin-mrg1 {
    margin-left: -80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-hm1.slider-sin-mrg1 {
    margin-left: -122px;
    margin-right: 0;
    margin-top: 95px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-sin-img-hm1.slider-sin-mrg1 {
    margin-left: 0px;
    margin-right: 0;
    margin-top: 130px;
  }
}

.slider-sin-img-hm2.slider-sin-mrg2 {
  margin-right: 70px;
  margin-left: -56px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-sin-img-hm2.slider-sin-mrg2 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-sin-img-hm2.slider-sin-mrg2 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-sin-img-hm2.slider-sin-mrg2 {
    margin-right: 50px;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-hm2.slider-sin-mrg2 {
    margin-left: 0px;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-sin-img-hm2.slider-sin-mrg2 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-sin-img-hm2.slider-sin-mrg2 {
    margin-top: 0px;
  }
}

.slider-height-2 {
  padding: 67px 0 0 0;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-2 {
    padding: 50px 0 0 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-2 {
    padding: 30px 0 0 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-2 {
    padding: 30px 0 0 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-2 {
    padding: 30px 0 0 0;
  }
}

.single-slider-2 {
  display: flex;
  align-items: flex-end;
}

.slider-content-2 {
  position: relative;
  margin-top: -30px;
}
.slider-content-2:before {
  position: absolute;
  left: -20px;
  top: -39px;
  content: "";
  width: 30px;
  height: 407px;
  background-color: #ffe9e2;
}
@media only screen and (max-width: 767px) {
  .slider-content-2:before {
    top: -10px;
    height: 380px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2:before {
    top: -20px;
    height: 380px;
  }
}
.slider-content-2 h1 {
  font-weight: bold;
  color: #010211;
  letter-spacing: 0.2px;
  line-height: 70px;
  font-size: 60px;
  margin: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-2 h1 {
    line-height: 60px;
    font-size: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 h1 {
    line-height: 50px;
    font-size: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-2 h1 {
    line-height: 43px;
    font-size: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 h1 {
    line-height: 36px;
    font-size: 24px;
  }
}
.slider-content-2 p {
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 26px;
  color: #010211;
  width: 70%;
  margin: 9px 0 23px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 p {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-2 p {
    width: 100%;
    margin: 9px 0 15px;
  }
}
.slider-content-2 .slider-price-video {
  display: flex;
  align-items: center;
  margin: 0 0 28px;
  z-index: 9;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .slider-content-2 .slider-price-video {
    margin: 0 0 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 .slider-price-video {
    margin: 0 0 15px;
  }
}
.slider-content-2 .slider-price-video .slider-price span {
  font-weight: bold;
  font-size: 40px;
  color: #010211;
  letter-spacing: 0.2px;
  display: block;
  line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 .slider-price-video .slider-price span {
    font-size: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-2 .slider-price-video .slider-price span {
    font-size: 22px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 .slider-price-video .slider-price span {
    font-size: 20px;
  }
}
.slider-content-2 .slider-price-video .slider-video {
  margin-left: 45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-2 .slider-price-video .slider-video {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-2 .slider-price-video .slider-video {
    margin-left: 15px;
  }
}
.slider-content-2 .slider-price-video .slider-video a {
  font-size: 16px;
  color: #010211;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-2 .slider-price-video .slider-video a {
    font-size: 15px;
    letter-spacing: 0px;
  }
}
.slider-content-2 .slider-price-video .slider-video a i {
  font-size: 36px;
  color: #ff5151;
  margin-right: 10px;
}
.slider-content-2 .slider-price-video .slider-video a:hover {
  color: #ff5151;
}

.owl-item .slider-content * {
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.owl-item.active .slider-animated-1 h3 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.owl-item.active .slider-animated-1 h5 {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.owl-item.active .slider-animated-1 h1 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.owl-item.active .slider-animated-1 img {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.owl-item.active .slider-animated-1 p {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.owl-item.active .slider-animated-1 a {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.owl-item.active .slider-animated-1.slider-sin-img-hm1 img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.owl-item.active .slider-animated-1.slider-sin-img-hm9 img {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
.owl-item.active .slider-animated-2 h1 {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.owl-item.active .slider-animated-2 p {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.owl-item.active .slider-animated-2 span {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.owl-item.active .slider-animated-2 .slider-video a {
  -webkit-animation-delay: 1.6s;
  animation-delay: 1.6s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.owl-item.active .slider-animated-2 a {
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.owl-item.active .slider-animated-2.slider-sin-img-hm2 img {
  -webkit-animation-delay: 1.9s;
  animation-delay: 1.9s;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.slider-height-3 {
  height: 800px;
  display: flex;
  align-items: center;
  margin: 0 100px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-3 {
    margin: 0 65px;
    height: 650px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-3 {
    margin: 0 15px;
    height: 650px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-3 {
    margin: 0 30px;
    height: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-3 {
    margin: 0 45px;
    height: 450px;
    background-position: 43%;
  }
}
@media only screen and (max-width: 767px) {
  .slider-height-3 {
    margin: 0 15px;
    height: 420px;
    background-position: 20%;
  }
}

.slider-content-3 {
  margin-top: -12px;
  position: relative;
  z-index: 9;
}
.slider-content-3 h1 {
  font-size: 48px;
  line-height: 65px;
  font-weight: bold;
  color: #ff5151;
  margin: 0;
  letter-spacing: 0.2px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-3 h1 {
    font-size: 40px;
    line-height: 55px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-3 h1 {
    font-size: 32px;
    line-height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-3 h1 {
    font-size: 32px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-3 h1 {
    font-size: 24px;
    line-height: 35px;
  }
}
.slider-content-3 p {
  font-size: 16px;
  color: #535252;
  line-height: 30px;
  letter-spacing: 0.2px;
  margin: 16px 0 34px;
  font-family: "Roboto", sans-serif;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-3 p {
    margin: 10px 0 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-3 p {
    margin: 7px 0 25px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-3 p {
    margin: 6px 0 19px;
    color: #000;
  }
}

.slider-sin-img-hm4 {
  height: 100vh;
}
@media only screen and (max-width: 767px) {
  .slider-sin-img-hm4 {
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-hm4 {
    height: auto;
    margin: 85px 0 0;
  }
}
.slider-sin-img-hm4 img {
  width: auto;
  margin-left: -124px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-sin-img-hm4 img {
    width: 100%;
    margin-left: -78px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-sin-img-hm4 img {
    width: 100%;
    margin-left: -68px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-sin-img-hm4 img {
    width: 100%;
    margin-left: -55px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-hm4 img {
    width: 100%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-sin-img-hm4 img {
    width: 100%;
    margin-left: 15px;
  }
}

.slider-content-4 {
  padding: 90px 0 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4 {
    padding: 77px 0 0px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-4 {
    padding: 100px 0 30px;
  }
}
.slider-content-4 h1 {
  font-size: 72px;
  color: #663e27;
  letter-spacing: 0.2px;
  font-weight: bold;
  line-height: 90px;
  font-family: "Roboto Slab", serif;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-4 h1 {
    font-size: 65px;
    line-height: 75px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-4 h1 {
    font-size: 52px;
    line-height: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-4 h1 {
    font-size: 45px;
    line-height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4 h1 {
    font-size: 35px;
    line-height: 46px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-4 h1 {
    font-size: 35px;
    line-height: 45px;
  }
}
.slider-content-4 p {
  color: #a1a1a1;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin: 11px 0 38px;
  width: 68%;
  font-family: "Roboto", sans-serif;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-4 p {
    width: 77%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-4 p {
    width: 90%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4 p {
    width: 90%;
    margin: 11px 0 17px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-4 p {
    width: 100%;
    margin: 11px 0 20px;
  }
}
.slider-content-4 .slider-btn-4 > a {
  padding: 5px 8px 5px 48px;
  border: 1px solid #663e27;
  border-radius: 50px;
  line-height: 1;
  display: inline-block;
  font-size: 16px;
  color: #663e27;
  letter-spacing: 0.2px;
}
.slider-content-4 .slider-btn-4 > a span {
  position: relative;
  top: -2px;
  right: 18px;
}
.slider-content-4 .slider-btn-4 > a > i {
  font-size: 20px;
  color: #ffffff;
  background-color: #663e27;
  width: 30px;
  height: 30px;
  line-height: 29px;
  display: inline-block;
  border-radius: 100%;
  text-align: center;
  transition: all 0.3s ease 0s;
}
.slider-content-4 .slider-btn-4 > a:hover {
  color: #ffffff;
  border: 1px solid #ff5151;
  background-color: #ff5151;
}
.slider-content-4 .slider-btn-4 > a:hover i {
  color: #ff5151;
  background-color: #ffffff;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-4.ml-150 {
    margin-left: 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-4.ml-150 {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-4.ml-150 {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-4.ml-150 {
    margin-left: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-4.ml-150 {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.slider-height-5 {
  height: 570px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-5 {
    height: 454px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-5 {
    height: 430px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-height-5 {
    height: 400px;
    background-position: 85%;
  }
}

.slider-content-5 {
  margin-left: 60px;
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  .slider-content-5 {
    margin-left: 20px;
  }
}
.slider-content-5 h1 {
  font-size: 40px;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .slider-content-5 h1 {
    font-size: 28px;
  }
}
.slider-content-5 h1 span {
  font-weight: bold;
}
.slider-content-5 h1 span.text-red {
  color: #ff5151;
}
.slider-content-5 p {
  color: #373737;
  font-size: 16px;
  line-height: 26px;
  margin: 12px 0 19px;
  width: 58%;
  font-family: "Roboto", sans-serif;
}
@media only screen and (max-width: 767px) {
  .slider-content-5 p {
    width: 95%;
    color: #000;
  }
}
.slider-content-5 .slider-btn-5 a {
  font-weight: bold;
  color: #373737;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  border: 1px solid #5f5f5f;
  border-radius: 50px;
  line-height: 1;
  padding: 14px 30px 12px;
  z-index: 9;
}
.slider-content-5 .slider-btn-5 a:hover {
  border: 1px solid #ff5151;
  color: #ffffff;
}

.slider-height-6 {
  height: 700px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-6 {
    height: 600px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-6 {
    height: 600px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-6 {
    height: 454px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-6 {
    height: 430px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-height-6 {
    height: auto;
    background-position: 85%;
    padding: 50px 0 30px;
  }
}

.slider-content-6 {
  margin-top: -60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-6 {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-6 {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-6 {
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-6 {
    text-align: left;
  }
}
.slider-content-6 h1 {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  margin: 0;
  line-height: 1;
}
.slider-content-6 p {
  font-size: 16px;
  color: #d1d1d1;
  margin: 26px 0 26px;
  line-height: 26px;
}
.slider-content-6 .slider-btn-6 a {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: #fff;
  background-color: #ff5151;
  display: inline-block;
  line-height: 1;
  padding: 12px 30px 11px;
  z-index: 1;
}
.slider-content-6 .slider-btn-6 a:hover {
  border: none;
  color: #ff5151;
}

.slider-sin-img-hm6 {
  margin-left: 70px;
  position: relative;
  margin-top: 10px;
}
.slider-sin-img-hm6 img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .slider-sin-img-hm6 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-sin-img-hm6 {
    margin-top: 0px;
  }
}
.slider-sin-img-hm6 .slider-price {
  position: absolute;
  top: 95px;
  left: 57px;
  animation-iteration-count: infinite;
  animation-name: bounce-img;
  animation-duration: 2.2s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-hm6 .slider-price {
    top: 60px;
    left: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-sin-img-hm6 .slider-price {
    top: 60px;
    left: 6px;
  }
}
.slider-sin-img-hm6 .slider-price h4 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  color: #fff;
  width: 78px;
  height: 78px;
  background-color: #141135;
  border-radius: 100%;
  line-height: 78px;
  text-align: center;
  position: relative;
}
.slider-sin-img-hm6 .slider-price h4:before {
  width: 93px;
  height: 93px;
  position: absolute;
  content: "";
  top: -8px;
  left: -8px;
  border: 8px solid #462a63;
  border-radius: 100%;
}
.slider-sin-img-hm6 .slider-price h4:after {
  width: 107px;
  height: 107px;
  position: absolute;
  content: "";
  top: -15px;
  left: -15px;
  border: 8px solid #2a1440;
  border-radius: 100%;
}

/* slider text bounce */
@-webkit-keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes bounce-img {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.slider-height-8 {
  height: 570px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-8 {
    height: 450px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-height-8 {
    height: 370px;
    background-position: 100%;
  }
}

.slider-content-8 {
  margin-left: 113px;
  z-index: 9;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-8 {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-8 {
    margin-left: 15px;
  }
}
.slider-content-8 h1 {
  font-weight: bold;
  font-size: 50px;
  color: #333745;
  margin: 0;
  line-height: 41px;
}
@media only screen and (max-width: 767px) {
  .slider-content-8 h1 {
    font-size: 30px;
    line-height: 35px;
  }
}
.slider-content-8 p {
  font-size: 16px;
  color: #333745;
  margin: 21px 0 30px;
  line-height: 25px;
  width: 58%;
}
@media only screen and (max-width: 767px) {
  .slider-content-8 p {
    margin: 10px 0 25px;
    width: 90%;
    color: #000;
  }
}
.slider-content-8 .slider-btn-8 a {
  color: #fff;
  letter-spacing: 0.2px;
  display: inline-block;
  border-radius: 5px;
  background-color: #ea2e49;
  padding: 12px 30px;
}

.dot-style-2 {
  position: relative;
}
.dot-style-2 .owl-dots {
  position: absolute;
  bottom: 30px;
  left: 29.4%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dot-style-2 .owl-dots {
    left: 7%;
  }
}
@media only screen and (max-width: 767px) {
  .dot-style-2 .owl-dots {
    left: 4%;
  }
}
.dot-style-2 .owl-dots .owl-dot {
  background-color: #e9b2bb;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  margin: 0 7px;
  transition: all 0.3s ease 0s;
}
.dot-style-2 .owl-dots .owl-dot.active, .dot-style-2 .owl-dots .owl-dot:hover {
  background-color: #ea2e49;
}

.slider-height-9 {
  padding-top: 130px;
}

.slider-sin-img-hm9 {
  margin: 0 95px 0 48px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-sin-img-hm9 {
    margin: 0 130px 0 48px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-sin-img-hm9 {
    margin: 0 130px 0 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-sin-img-hm9 {
    margin: 0 60px 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-sin-img-hm9 {
    margin: 25px 0px 0 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-sin-img-hm9 {
    margin: 0px 0px 0 0px;
  }
}

.slider-content-9 {
  margin-left: 70px;
  margin-top: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-9 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-9 {
    margin-left: 0px;
  }
}
.slider-content-9 img {
  width: 291px;
}
@media only screen and (max-width: 767px) {
  .slider-content-9 img {
    width: 200px;
  }
}
.slider-content-9 p {
  font-size: 18px;
  color: #000000;
  line-height: 30px;
  font-weight: 300;
  margin: 15px 0 35px;
  width: 85%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-9 p {
    margin: 10px 0 15px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-9 p {
    margin: 10px 0 15px;
    width: 100%;
  }
}
.slider-content-9 .slider-btn-9 a {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  display: inline-block;
  border: 1px solid #060606;
  border-radius: 50px;
  line-height: 1;
  padding: 12px 40px 11px;
}
.slider-content-9 .slider-btn-9 a i {
  font-size: 18px;
  margin-left: 10px;
}
.slider-content-9 .slider-btn-9 a:hover {
  color: #fff;
  border: 1px solid #ff5151;
}

.owl-carousel .owl-item .slider-content-9 img {
  width: 291px;
}
@media only screen and (max-width: 767px) {
  .owl-carousel .owl-item .slider-content-9 img {
    width: 200px;
  }
}

.slider-height-10 {
  height: 830px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-height-10 {
    height: 650px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-height-10 {
    height: 650px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-height-10 {
    height: 500px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-10 {
    height: 450px;
    background-position: 30%;
  }
}
@media only screen and (max-width: 767px) {
  .slider-height-10 {
    height: 400px;
    background-position: 30%;
  }
}

.slider-content-10 {
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  .slider-content-10 {
    text-align: center;
  }
}
.slider-content-10 h5 {
  font-size: 18px;
  color: #010225;
  font-weight: 300;
  line-height: 30px;
  margin: 0;
}
.slider-content-10 h1 {
  font-size: 50px;
  color: #010225;
  font-weight: 500;
  line-height: 40px;
  margin: 13px 0 38px;
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-10 h1 {
    font-size: 45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-10 h1 {
    font-size: 40px;
    margin: 13px 0 28px;
    line-height: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-10 h1 {
    font-size: 40px;
    margin: 10px 0 25px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-10 h1 {
    font-size: 26px;
    margin: 8px 0 18px;
    line-height: 28px;
  }
}
.slider-content-10 .slider-btn-10 a {
  line-height: 1;
  display: inline-block;
  font-size: 18px;
  font-weight: 300;
  color: #010225;
  border: 1px solid #ff5151;
  padding: 18px 40px 17px;
}
@media only screen and (max-width: 767px) {
  .slider-content-10 .slider-btn-10 a {
    padding: 15px 25px 14px;
    font-size: 15px;
  }
}
.slider-content-10 .slider-btn-10 a:hover {
  color: #ffffff;
}

.slider-content-border2 {
  position: relative;
}
.slider-content-border2::before {
  position: absolute;
  content: "";
  left: -23px;
  top: 8px;
  height: 103px;
  width: 1px;
  background-color: #ff5151;
}
@media only screen and (max-width: 767px) {
  .slider-content-border2::before {
    left: 50px;
  }
}

.slider-height-7 {
  height: 100vh;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-height-7 {
    height: auto;
    padding: 100px 0 200px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-height-7 {
    height: auto;
    padding: 100px 0 270px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-height-7 {
    padding: 100px 0 230px;
  }
}

.slider-content-7 {
  padding-left: 86px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-7 {
    padding-left: 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-7 {
    padding-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 {
    padding-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-7 {
    padding-left: 0px;
  }
}
.slider-content-7 h1 {
  font-size: 80px;
  color: #282828;
  line-height: 90px;
  margin: 0;
  font-family: "Roboto Mono", monospace;
  font-weight: bold;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-content-7 h1 {
    font-size: 55px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-7 h1 {
    font-size: 55px;
    line-height: 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 h1 {
    font-size: 44px;
    line-height: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 h1 {
    font-size: 55px;
    line-height: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-7 h1 {
    font-size: 33px;
    line-height: 45px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .slider-content-7 h1 {
    font-size: 40px;
    line-height: 55px;
  }
}
.slider-content-7 p {
  font-size: 18px;
  color: #282828;
  line-height: 30px;
  margin: 19px 0 0px;
  font-weight: 300;
  width: 86%;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-content-7 p {
    font-size: 17px;
    width: 100%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content-7 p {
    font-size: 16px;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content-7 p {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .slider-content-7 p {
    width: 100%;
    font-size: 16px;
  }
}

.slider-video-7 {
  position: absolute;
  bottom: 40px;
  left: 86px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .slider-video-7 {
    left: 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .slider-video-7 {
    left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-video-7 {
    left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-video-7 {
    left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .slider-video-7 {
    left: 0px;
    bottom: 80px;
  }
}
.slider-video-7 a {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #ff5151;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .slider-video-7 a {
    font-size: 14px;
  }
}
.slider-video-7 a i {
  font-size: 80px;
  color: #c3c3c3;
}
@media only screen and (max-width: 767px) {
  .slider-video-7 a i {
    font-size: 50px;
  }
}

.slider-social-wrap {
  position: absolute;
  bottom: 208px;
  right: -113px;
  transform: rotate(-90deg);
}
@media only screen and (max-width: 767px) {
  .slider-social-wrap {
    bottom: 40px;
    left: 15px;
    right: inherit;
    transform: inherit;
  }
}
.slider-social-wrap a {
  color: #ababab;
  display: inline-block;
  margin: 0 30px 0 0;
}
@media only screen and (max-width: 767px) {
  .slider-social-wrap a {
    margin: 0 12px 0 0;
  }
}
.slider-social-wrap a:hover {
  color: #ff5151;
}

.slider7-border {
  position: relative;
  overflow: hidden;
}
.slider7-border:before {
  position: absolute;
  right: 120px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: #d5d4d4;
  content: "";
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider7-border:before {
    content: none;
  }
}
@media only screen and (max-width: 767px) {
  .slider7-border:before {
    content: none;
  }
}

.scroll-down {
  position: absolute;
  bottom: 70px;
  left: 0;
  right: 0;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .scroll-down {
    bottom: 105px;
  }
}
.scroll-down a {
  position: relative;
}
.scroll-down a:before {
  position: absolute;
  border: 3px solid #8c8c8c;
  width: 34px;
  height: 60px;
  border-radius: 50px;
  content: "";
  left: -16px;
  top: -10px;
}
.scroll-down a:hover:before {
  border: 3px solid #ff5151;
}

.home7-img-wrap {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home7-img-wrap {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .home7-img-wrap {
    margin-top: 90px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .home7-img-wrap {
    margin-top: 66px;
  }
}
.home7-img-wrap .drone {
  width: 100%;
}
.home7-img-wrap .drone .layer {
  margin-right: 75px;
}
@media only screen and (max-width: 767px) {
  .home7-img-wrap .drone .layer {
    margin-right: 0px;
  }
}
.home7-img-wrap .drone .layer img {
  width: 100%;
}

.drone-shape-1 {
  position: absolute;
  top: 10%;
  left: 31%;
  z-index: 1;
  width: 100%;
}
.drone-shape-1 .layer.layer-border {
  position: relative;
}
.drone-shape-1 .layer.layer-border::before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #ff5151;
  bottom: -17px;
  left: 185px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .drone-shape-1 .layer.layer-border::before {
    left: 110px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .drone-shape-1 .layer.layer-border::before {
    left: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .drone-shape-1 .layer.layer-border::before {
    left: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .drone-shape-1 .layer.layer-border::before {
    left: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .drone-shape-1 .layer.layer-border::before {
    left: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .drone-shape-1 .layer.layer-border::before {
    left: 95px;
  }
}
.drone-shape-1 .layer.layer-border::after {
  animation: 1500ms ease-out 0s normal none infinite running border-jump;
  background: #ff5151;
  border-radius: 100%;
  content: "";
  display: block;
  height: 25px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  z-index: -1;
  bottom: -32px;
  left: 195px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .drone-shape-1 .layer.layer-border::after {
    left: 120px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .drone-shape-1 .layer.layer-border::after {
    left: 120px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .drone-shape-1 .layer.layer-border::after {
    left: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .drone-shape-1 .layer.layer-border::after {
    left: 110px;
  }
}
@media only screen and (max-width: 767px) {
  .drone-shape-1 .layer.layer-border::after {
    left: 50px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .drone-shape-1 .layer.layer-border::after {
    left: 105px;
  }
}
.drone-shape-1 .layer img {
  width: 185px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .drone-shape-1 .layer img {
    width: 110px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .drone-shape-1 .layer img {
    width: 110px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .drone-shape-1 .layer img {
    width: 80px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .drone-shape-1 .layer img {
    width: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .drone-shape-1 .layer img {
    width: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .drone-shape-1 .layer img {
    width: 95px;
  }
}

.drone-shape-2 {
  position: absolute;
  bottom: 16%;
  left: 50%;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .drone-shape-2 {
    bottom: 7%;
    left: 48%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .drone-shape-2 {
    bottom: 2%;
    left: 47%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .drone-shape-2 {
    bottom: 0%;
    left: 47%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .drone-shape-2 {
    left: 48%;
    bottom: 8%;
  }
}
@media only screen and (max-width: 767px) {
  .drone-shape-2 {
    left: 53%;
    bottom: -15%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .drone-shape-2 {
    left: 53%;
    bottom: -2%;
  }
}
.drone-shape-2 .layer.layer-border {
  position: relative;
}
.drone-shape-2 .layer.layer-border:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #ff5151;
  top: -18px;
  left: -14px;
}
.drone-shape-2 .layer.layer-border::after {
  animation: 1500ms ease-out 0s normal none infinite running border-jump;
  background: #ff5151;
  border-radius: 100%;
  content: "";
  display: block;
  height: 25px;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  width: 25px;
  z-index: -1;
  top: -8px;
  left: -5px;
}
.drone-shape-2 .layer img {
  width: 57px;
}
@media only screen and (max-width: 767px) {
  .drone-shape-2 .layer img {
    width: 40px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .drone-shape-2 .layer img {
    width: 57px;
  }
}

.drone-content-1 {
  position: absolute;
  top: 10%;
  left: 19.6%;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .drone-content-1 {
    left: 12%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .drone-content-1 {
    left: 12%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .drone-content-1 {
    left: 7%;
    top: 5%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .drone-content-1 {
    left: 7%;
    top: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .drone-content-1 {
    left: 0%;
    top: -36%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .drone-content-1 {
    left: 0%;
    top: -12%;
  }
}
.drone-content-1 .layer h5 {
  margin: 0 0 7px;
  font-size: 14px;
  color: #282828;
  font-weight: 500;
  line-height: 1;
}
.drone-content-1 .layer p {
  margin: 0;
  font-size: 14px;
  color: #282828;
  font-weight: 300;
  line-height: 22px;
}

.drone-content-2 {
  position: absolute;
  bottom: 7%;
  left: 55%;
  z-index: 1;
  width: 100%;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .drone-content-2 {
    bottom: -6%;
    left: 56%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .drone-content-2 {
    bottom: -13%;
    left: 56%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .drone-content-2 {
    bottom: -60px;
    left: 57%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .drone-content-2 {
    left: 56%;
    bottom: -9%;
  }
}
@media only screen and (max-width: 767px) {
  .drone-content-2 {
    bottom: -60%;
    left: 40%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .drone-content-2 {
    bottom: -22%;
    left: 66%;
  }
}
.drone-content-2 .layer h5 {
  margin: 0 0 7px;
  font-size: 14px;
  color: #282828;
  font-weight: 500;
  line-height: 1;
}
.drone-content-2 .layer p {
  margin: 0;
  font-size: 14px;
  color: #282828;
  font-weight: 300;
  line-height: 22px;
}

#scene.main-wrapper {
  -webkit-backface-visibility: unset !important;
  backface-visibility: unset !important;
  -webkit-transform: unset !important;
  -ms-transform: unset !important;
  transform: unset !important;
  -webkit-transform-style: unset !important;
  transform-style: unset !important;
}

@-webkit-keyframes border-jump {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    border-radius: 100%;
  }
}
@keyframes border-jump {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
    border-radius: 100%;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
    border-radius: 100%;
  }
}
/*-------- 4. Banner style ---------*/
.banner-content {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .banner-content {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-content {
    margin-right: 0px;
  }
}
.banner-content h2 {
  color: #7c5e48;
  font-size: 48px;
  line-height: 55px;
  margin: 0 0 20px;
  font-family: "Poppins", sans-serif;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-content h2 {
    font-size: 40px;
    line-height: 45px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-content h2 {
    font-size: 40px;
    line-height: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-content h2 {
    font-size: 20px;
    line-height: 25px;
  }
}
.banner-content a {
  color: #7c5e48;
  text-transform: uppercase;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid #a29083;
  padding-bottom: 2px;
}
.banner-content a:hover {
  border-bottom: 1px solid #ff5151;
  color: #ff5151;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .learn-banner.pt-100 {
    padding-top: 72px;
  }
  .learn-banner.pb-100 {
    padding-bottom: 72px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .learn-banner.pt-100 {
    padding-top: 72px;
  }
  .learn-banner.pb-100 {
    padding-bottom: 72px;
  }
}
@media only screen and (max-width: 767px) {
  .learn-banner {
    background-position: 17%;
  }
  .learn-banner.pt-100 {
    padding-top: 50px;
  }
  .learn-banner.pb-100 {
    padding-bottom: 50px;
  }
}

.banner-wrap {
  position: relative;
  overflow: hidden;
}
.banner-wrap a img {
  width: 100%;
  transition: all 0.5s ease 0s;
}
.banner-wrap .banner-position-1 {
  position: absolute;
  right: 37px;
  top: 48px;
  z-index: 99;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-position-1 {
    right: 20px;
    top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-position-1 {
    right: 10px;
    top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-1 {
    right: 10px;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}
.banner-wrap .banner-position-2 {
  position: absolute;
  right: 28px;
  top: 48px;
  z-index: 99;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-position-2 {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.banner-wrap .banner-position-3 {
  position: absolute;
  left: 45px;
  bottom: 34px;
  z-index: 99;
}
.banner-wrap .banner-content-2 h3 {
  font-size: 30px;
  font-weight: 300;
  color: #292624;
  letter-spacing: 3.7px;
  margin: 0 0 5px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-2 h3 {
    font-size: 20px;
    letter-spacing: 2.7px;
    margin: 0 0 3px;
  }
}
.banner-wrap .banner-content-2 p {
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  color: #27262e;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-2 p {
    font-size: 15px;
  }
}
.banner-wrap .banner-content-3 {
  text-align: center;
}
.banner-wrap .banner-content-3 h3 {
  font-size: 22px;
  font-weight: 500;
  color: #27262e;
  margin: 0px;
  line-height: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 16px;
    line-height: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-3 h3 {
    font-size: 16px;
    line-height: 25px;
  }
}
.banner-wrap .banner-content-4 {
  position: absolute;
  right: 44px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 {
    right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 {
    right: 20px;
  }
}
.banner-wrap .banner-content-4 h2 {
  font-size: 30px;
  font-weight: bold;
  color: #010211;
  line-height: 35px;
  letter-spacing: 0.2px;
  margin: 0 0 9px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 25px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 22px;
    line-height: 28px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 5px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 18px;
    line-height: 22px;
    margin: 0 0 5px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-4 h2 {
    font-size: 25px;
    line-height: 30px;
    margin: 0 0 12px;
  }
}
.banner-wrap .banner-content-4 p {
  color: #010211;
  line-height: 22px;
  font-size: 14px;
  letter-spacing: 0.2px;
  margin: 0;
  font-family: "Roboto", sans-serif;
}
.banner-wrap .banner-content-5 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 40px;
  justify-content: space-between;
  display: flex;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-5 {
    padding: 10px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-5 {
    padding: 10px 10px;
  }
}
.banner-wrap .banner-content-5 .banner-title-img {
  display: flex;
  align-items: center;
}
.banner-wrap .banner-content-5 .banner-title-img .banner-img {
  flex: 0 0 40px;
  margin: 0 13px 0 0;
}
.banner-wrap .banner-content-5 .banner-title-img .banner-img img {
  width: 100%;
}
.banner-wrap .banner-content-5 .banner-title-img .banner-title h4 {
  color: #ffffff;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-family: "Roboto Slab", serif;
}
.banner-wrap .banner-content-5 .banner-zoom a {
  width: 40px;
  height: 40px;
  color: #ffffff;
  border: 1px solid #ffffff;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  border-radius: 100%;
}
.banner-wrap .banner-content-5 .banner-zoom a i {
  line-height: 38px;
}
.banner-wrap .banner-content-5 .banner-zoom a:hover {
  border: 1px solid #ff5151;
  color: #ffffff;
  background-color: #ff5151;
}
.banner-wrap .banner-content-6 {
  position: absolute;
  top: 18px;
  left: 35px;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 {
    top: 50%;
    transform: translateY(-50%);
  }
}
.banner-wrap .banner-content-6 h3 {
  font-weight: bold;
  font-size: 26px;
  color: #000000;
  margin: 0 0 41px;
  line-height: 34px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h3 {
    margin: 0 0 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 h3 {
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h3 {
    margin: 0 0 20px;
    font-size: 20px;
    line-height: 25px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-6 h3 {
    margin: 0 0 25px;
    font-size: 25px;
    line-height: 30px;
  }
}
.banner-wrap .banner-content-6 span {
  font-size: 13px;
  color: #ff5151;
  display: block;
  line-height: 1;
}
.banner-wrap .banner-content-6 h4 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  margin: 0 0 41px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-6 h4 {
    margin: 0 0 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-6 h4 {
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-6 h4 {
    margin: 0 0 20px;
    font-size: 25px;
  }
}
.banner-wrap .banner-content-6 a {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  line-height: 1;
  border-bottom: 1px solid #a7a6a6;
}
.banner-wrap .banner-content-6 a:hover {
  border-bottom: 1px solid #ff5151;
}
.banner-wrap .banner-content-7 {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
}
.banner-wrap .banner-content-7 h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}
.banner-wrap .banner-content-7 h3 span {
  color: #ff5151;
}
.banner-wrap .banner-content-7 p {
  color: #000000;
  margin: 0px 0 6px;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-7 p {
    margin: 0px 0 0px;
  }
}
.banner-wrap .banner-content-7 a {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  line-height: 1;
  border-bottom: 1px solid #a7a6a6;
}
.banner-wrap .banner-content-7 a:hover {
  border-bottom: 1px solid #ff5151;
}
.banner-wrap .banner-content-8 {
  position: absolute;
  top: 23px;
  right: 40px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 {
    top: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-8 {
    top: 30px;
  }
}
.banner-wrap .banner-content-8 h2 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.25px;
  margin: 0 0 5px;
  color: #000000;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 12px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-8 h2 {
    font-size: 18px;
  }
}
.banner-wrap .banner-content-8 h5 {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.25px;
  margin: 0 0 81px;
  color: #000000;
  line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-8 h5 {
    margin: 0 0 30px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h5 {
    margin: 0 0 10px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h5 {
    margin: 0 0 12px;
    font-size: 22px;
  }
}
.banner-wrap .banner-content-8 h3 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.25px;
  margin: 0 0 12px;
  color: #000000;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-8 h3 {
    font-size: 24px;
    margin: 0 0 8px;
  }
}
.banner-wrap .banner-content-8 h3 span {
  color: #ff5151;
}
.banner-wrap .banner-content-8 h4 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.25px;
  margin: 0 0 0px;
  color: #fff;
  text-shadow: 1px 1px #000, -1px 1px #000, -1px -1px #000, 1px -1px #000;
}
.banner-wrap .banner-content-8 h4 span {
  margin: 0 5px;
}
.banner-wrap .banner-content-9 {
  position: absolute;
  top: 50px;
  left: 20px;
  z-index: 9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 {
    top: 30px;
  }
}
.banner-wrap .banner-content-9 h3 {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.25px;
  margin: 0 0 72px;
  color: #000000;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h3 {
    margin: 0 0 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 h3 {
    margin: 0 0 25px;
  }
}
.banner-wrap .banner-content-9 p {
  font-size: 13px;
  color: #000000;
  letter-spacing: 0.25px;
  line-height: 16px;
  margin: 0 0 9px;
  width: 60%;
}
.banner-wrap .banner-content-9 a {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  line-height: 1;
  border-bottom: 1px solid #a7a6a6;
}
.banner-wrap .banner-content-9 a:hover {
  border-bottom: 1px solid #ff5151;
}
.banner-wrap .banner-content-9 h1 {
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0.25px;
  margin: 0 0 4px;
  color: #fff;
  text-shadow: 2px 2px #22e2d6, -2px 2px #22e2d6, -2px -2px #22e2d6, 2px -2px #22e2d6;
  line-height: 33px;
}
.banner-wrap .banner-content-9 h4 {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.25px;
  margin: 0 0 71px;
  color: #333333;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9 h4 {
    margin: 0 0 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9 h4 {
    margin: 0 0 25px;
  }
}
.banner-wrap .banner-content-9 h4 span {
  font-weight: 500;
}
.banner-wrap .banner-content-9.banner-content-9-mrg2 h4 {
  margin: 28px 0 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-9.banner-content-9-mrg2 h4 {
    margin: 28px 0 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-9.banner-content-9-mrg2 h4 {
    margin: 28px 0 20px;
  }
}
.banner-wrap .banner-content-10 {
  position: absolute;
  top: 62px;
  left: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 {
    top: 35px;
  }
}
.banner-wrap .banner-content-10 h2 {
  font-size: 26px;
  font-weight: bold;
  color: #393939;
  margin: 0;
  line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-10 h2 {
    font-size: 20px;
  }
}
.banner-wrap .banner-content-10-btn {
  position: absolute;
  left: 30px;
  bottom: 37px;
}
.banner-wrap .banner-content-10-btn a {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  line-height: 1;
  border-bottom: 1px solid #a7a6a6;
}
.banner-wrap .banner-content-10-btn a:hover {
  border-bottom: 1px solid #ff5151;
}
.banner-wrap .banner-content-11 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}
.banner-wrap .banner-content-11 h2 {
  font-size: 92px;
  color: #ffffff;
  margin: 0;
  font-weight: bold;
  line-height: 68px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h2 {
    font-size: 50px;
    line-height: 40px;
  }
}
.banner-wrap .banner-content-11 h3 {
  font-size: 28px;
  color: #000000;
  margin: 9px 0 27px;
  font-weight: 300;
  letter-spacing: 7.4px;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h3 {
    margin: 5px 0 10px;
    line-height: 1;
  }
}
.banner-wrap .banner-content-11 h4 {
  font-size: 18px;
  color: #000000;
  margin: 0;
  font-weight: 500;
  letter-spacing: 6.4px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-11 h4 {
    letter-spacing: 3.4px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-11 h4 {
    letter-spacing: 1.4px;
  }
}
.banner-wrap .banner-content-12 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 60px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 {
    right: 30px;
  }
}
.banner-wrap .banner-content-12 h2 {
  font-size: 30px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 0.25px;
  margin: 0;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 h2 {
    font-size: 23px;
  }
}
.banner-wrap .banner-content-12 h5 {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  letter-spacing: 0.25px;
  margin: 7px 0 12px;
  line-height: 1;
}
.banner-wrap .banner-content-12 h3 {
  font-size: 30px;
  font-weight: 300;
  color: #000000;
  letter-spacing: 0.25px;
  margin: 0;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-12 h3 {
    font-size: 25px;
  }
}
.banner-wrap .banner-content-13 {
  position: absolute;
  top: 60px;
  left: 50px;
  z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-13 {
    top: 35px;
    left: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 {
    top: 15px;
    left: 15px;
  }
}
.banner-wrap .banner-content-13 h3 {
  font-size: 26px;
  font-weight: bold;
  color: #333745;
  margin: 0 0 36px;
  line-height: 20px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-wrap .banner-content-13 h3 {
    margin: 0 0 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-13 h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 h3 {
    font-size: 20px;
    margin: 0 0 10px;
  }
}
.banner-wrap .banner-content-13 p {
  font-size: 16px;
  color: #333745;
  margin: 0;
  line-height: 25px;
  width: 63%;
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-13 p {
    width: 80%;
    color: #000;
  }
}
.banner-wrap .banner-content-14 {
  position: absolute;
  left: 40px;
  bottom: 39%;
  z-index: 9;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-14 {
    left: 15px;
    bottom: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-14 {
    left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-14 {
    left: 25px;
  }
}
.banner-wrap .banner-content-14 h4 {
  font-size: 24px;
  color: #000000;
  line-height: 30px;
  margin: 0;
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-wrap .banner-content-14 h4 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-14 h4 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-wrap .banner-content-14 h4 {
    font-size: 20px;
  }
}
.banner-wrap .banner-content-14 h4 span {
  color: #960000;
}
.banner-wrap .banner-content-15 {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
}
.banner-wrap .banner-content-15 h2 {
  font-size: 40px;
  color: #bd7a4c;
  line-height: 50px;
  font-weight: 300;
  margin: 0;
}
.banner-wrap .banner-content-16 {
  position: absolute;
  left: 50px;
  bottom: 35px;
  z-index: 9;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-wrap .banner-content-16 {
    left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-wrap .banner-content-16 {
    left: 20px;
  }
}
.banner-wrap .banner-content-16 h3 {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin: 0 0 5px;
  line-height: 1;
}
.banner-wrap .banner-content-16 h3 a {
  color: #333333;
}
.banner-wrap .banner-content-16 h3 a:hover {
  color: #ff5151;
}
.banner-wrap .banner-content-16 span {
  color: #333333;
  font-size: 13px;
  display: block;
  line-height: 1;
}
.banner-wrap:hover a img {
  transform: scale(1.1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-h5-wrap {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-h5-wrap {
    margin-top: 30px;
  }
}
.banner-slider-active {
  overflow: hidden;
}
.banner-slider-active .slick-list {
  margin: 0 -10px;
}
.banner-slider-active .slick-list .slick-slide {
  margin: 0 10px;
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .res-white-overly-xl {
    position: relative;
  }
  .res-white-overly-xl:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: 0.5;
    z-index: 1;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .res-white-overly-lg {
    position: relative;
  }
  .res-white-overly-lg:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: 0.5;
    z-index: 1;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res-white-overly-md {
    position: relative;
  }
  .res-white-overly-md:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: 0.5;
    z-index: 1;
  }
}
@media only screen and (max-width: 767px) {
  .res-white-overly-xs {
    position: relative;
  }
  .res-white-overly-xs:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff;
    opacity: 0.5;
    z-index: 1;
  }
}
.banner-pattern-wrap {
  position: relative;
}
.banner-pattern-wrap .banner-pattern-1 {
  position: absolute;
  top: -36px;
  left: -36px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-pattern-wrap .banner-pattern-1 {
    left: -10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-pattern-wrap .banner-pattern-1 {
    left: -20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-pattern-wrap .banner-pattern-1 {
    left: -20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-pattern-wrap .banner-pattern-1 {
    left: 0px;
  }
}
.banner-pattern-wrap .banner-pattern-1 img {
  width: 99px;
}
.banner-pattern-wrap .banner-pattern-2 {
  position: absolute;
  bottom: -7px;
  right: -128px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .banner-pattern-wrap .banner-pattern-2 {
    right: -25px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .banner-pattern-wrap .banner-pattern-2 {
    right: -10px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-pattern-wrap .banner-pattern-2 {
    right: -10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-pattern-wrap .banner-pattern-2 {
    right: -20px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-pattern-wrap .banner-pattern-2 {
    right: 0px;
    bottom: 14px;
  }
}
.banner-pattern-wrap .banner-pattern-2 img {
  width: 99px;
}

@media only screen and (max-width: 767px) {
  .banner-area.pb-75 {
    padding-bottom: 20px;
  }
  .banner-area.pt-50 {
    padding-top: 20px;
  }
  .banner-area.pb-40 {
    padding-bottom: 20px;
  }
}

/*-------- 5. Section title style ---------*/
.section-title h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 21px;
  line-height: 30px;
}
@media only screen and (max-width: 767px) {
  .section-title h2 {
    margin-bottom: 14px;
    font-size: 30px;
    line-height: 26px;
  }
}
.section-title p {
  width: 41%;
  margin: 0 auto;
  line-height: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title p {
    width: 52%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title p {
    width: 69%;
  }
}
@media only screen and (max-width: 767px) {
  .section-title p {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title p {
    width: 95%;
  }
}
@media only screen and (max-width: 767px) {
  .section-title.mb-40 {
    margin-bottom: 30px;
  }
}

.section-title-2 {
  margin-bottom: 33px;
}
@media only screen and (max-width: 767px) {
  .section-title-2 {
    margin-bottom: 25px;
  }
}
.section-title-2 h2 {
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 9px;
  line-height: 30px;
  color: #292323;
}
@media only screen and (max-width: 767px) {
  .section-title-2 h2 {
    margin-bottom: 14px;
    font-size: 28px;
    margin-bottom: 6px;
    line-height: 26px;
  }
}

.section-title-3 {
  margin-bottom: 47px;
}
@media only screen and (max-width: 767px) {
  .section-title-3 {
    margin-bottom: 25px;
  }
}
.section-title-3 h2 {
  font-size: 33px;
  font-weight: bold;
  margin-bottom: 22px;
  line-height: 30px;
  color: #292323;
}
@media only screen and (max-width: 767px) {
  .section-title-3 h2 {
    margin-bottom: 14px;
  }
}
.section-title-3 p {
  font-size: 18px;
  color: #666666;
  letter-spacing: 2px;
}
.section-title-3 p span {
  color: #ff5151;
}

.section-title-4 {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .section-title-4 {
    margin-bottom: 25px;
  }
}
.section-title-4 h2 {
  font-size: 30px;
  font-weight: bold;
  margin: 0;
  line-height: 25px;
  font-family: "Roboto Slab", serif;
}
@media only screen and (max-width: 767px) {
  .section-title-4 h2 {
    margin-bottom: 14px;
    font-size: 22px;
    line-height: 20px;
  }
}

.section-title-5 h2 {
  font-size: 26px;
  font-weight: bold;
  margin: 0;
  line-height: 21px;
  color: #333745;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-5 h2 {
    font-size: 19px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title-5 h2 {
    margin-bottom: 14px;
    font-size: 20px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-5 h2 {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 1px;
  }
}

.section-title-6 h2, .section-title-7 h2 {
  font-size: 33px;
  font-weight: 500;
  margin: 0;
  line-height: 28px;
  color: #292323;
  letter-spacing: 0.25px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title-6 h2, .section-title-7 h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .section-title-6 h2, .section-title-7 h2 {
    margin-bottom: 14px;
    font-size: 25px;
    line-height: 20px;
    text-align: center;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .section-title-6 h2, .section-title-7 h2 {
    font-size: 25px;
    line-height: 20px;
    margin-bottom: 1px;
    text-align: inherit;
  }
}
@media only screen and (max-width: 767px) {
  .section-title-6.mb-50, .section-title-7.mb-50 {
    margin-bottom: 30px;
  }
}

/*-------- 6. Product style ---------*/
.product-wrap {
  position: relative;
}
.product-wrap .product-img {
  position: relative;
  overflow: hidden;
}
.product-wrap .product-img a img {
  width: 100%;
}
.product-wrap .product-img > span {
  position: absolute;
  width: 45px;
  height: 45px;
  text-align: center;
  color: #fff;
  border-radius: 100%;
  background-color: #ff5151;
  top: 10px;
}
.product-wrap .product-img > span.price-dec {
  line-height: 45px;
  right: 10px;
}
.product-wrap .product-img > span.font-dec {
  font-size: 12px;
  left: 10px;
}
.product-wrap .product-img > span.new-stock {
  font-size: 12px;
  line-height: 58px;
  right: 10px;
}
.product-wrap .product-img > span.new-stock > span {
  line-height: 1;
  display: inline-block;
}
.product-wrap .product-img .product-action {
  position: absolute;
  left: 20px;
  bottom: 15px;
  display: flex;
  flex-direction: column;
}
.product-wrap .product-img .product-action a {
  font-size: 22px;
  color: #737373;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  text-align: center;
  margin: 5px 0 5px -20px;
  opacity: 0;
  visibility: hidden;
}
.product-wrap .product-img .product-action a:nth-child(1) {
  transition-delay: 0.2s;
}
.product-wrap .product-img .product-action a:nth-child(2) {
  transition-delay: 0.1s;
}
.product-wrap .product-img .product-action a:nth-child(3) {
  transition-delay: 0s;
}
.product-wrap .product-img .product-action a:hover {
  border: 1px solid #ff5151;
  background-color: #ff5151;
  color: #ffffff;
}
.product-wrap .product-img .product-action-3 {
  position: absolute;
  right: 20px;
  top: 13px;
  display: flex;
  flex-direction: column;
}
.product-wrap .product-img .product-action-3 a {
  font-size: 22px;
  color: #373737;
  border: 1px solid #ff5151;
  background-color: #fff;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 100%;
  text-align: center;
  margin: -7px 0px -7px 0px;
  opacity: 0;
  visibility: hidden;
}
.product-wrap .product-img .product-action-3 a:nth-child(1) {
  transition-delay: 0.2s;
}
.product-wrap .product-img .product-action-3 a:nth-child(2) {
  transition-delay: 0.1s;
}
.product-wrap .product-img .product-action-3 a:nth-child(3) {
  transition-delay: 0s;
}
.product-wrap .product-img .product-action-3 a:hover {
  border: 1px solid #ff5151;
  background-color: #ff5151;
  color: #ffffff;
}
.product-wrap .product-img .product-action-2 {
  position: absolute;
  left: 0px;
  bottom: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #f8e7e8;
  padding: 9px 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}
.product-wrap .product-img .product-action-2 a {
  font-size: 22px;
  color: #ff5151;
  margin: 0 20px;
}
.product-wrap .product-img .product-action-2 a:hover {
  color: #373737;
}
.product-wrap .product-img .product-action-4 {
  bottom: 0px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  transition: all 0.6s ease 0s;
}
.product-wrap .product-img .product-action-4 .product-action-4-style {
  background-color: #fff;
  box-shadow: 0 0 20px 0.4px rgba(0, 0, 0, 0.1);
  display: inline-block;
  padding: 8px 17px 6px;
  border-radius: 50px;
}
.product-wrap .product-img .product-action-4 .product-action-4-style a {
  color: #333745;
  font-size: 20px;
  line-height: 1;
  padding: 0 15px;
  position: relative;
}
.product-wrap .product-img .product-action-4 .product-action-4-style a:hover {
  color: #ff5151;
}
.product-wrap .product-img .product-action-4 .product-action-4-style a::before {
  background: #eff0f1 none repeat scroll 0 0;
  content: "";
  height: 30px;
  position: absolute;
  right: 2px;
  top: -3px;
  width: 1px;
}
.product-wrap .product-img .product-action-4 .product-action-4-style a:last-child::before {
  display: none;
}
.product-wrap .product-img .product-action-4 .product-action-4-style a::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-bottom: 8px;
  transform: translateX(-50%);
  font-size: 12px;
  background-color: transparent;
  color: #333745;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  letter-spacing: 0.2px;
}
.product-wrap .product-img .product-action-4 .product-action-4-style a:hover::after {
  opacity: 1;
  visibility: visible;
}
.product-wrap .product-img .product-badge {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #ffffff;
  background-color: #d6d6d6;
  font-weight: 900;
  position: absolute;
  top: 10px;
}
.product-wrap .product-img .product-badge.badge-right {
  right: 10px;
}
.product-wrap .product-img .product-badge.badge-left {
  left: 10px;
}
.product-wrap .product-content > span {
  color: #737373;
  font-size: 14px;
}
.product-wrap .product-content h4 {
  font-size: 16px;
  margin: 0px 0 5px;
}
.product-wrap .product-content .price-addtocart {
  display: flex;
  justify-content: space-between;
}
.product-wrap .product-content .price-addtocart .product-price span {
  color: #373737;
  font-size: 16px;
  font-weight: 500;
}
.product-wrap .product-content .price-addtocart .product-price span.old {
  font-weight: 300;
  font-size: 14px;
  text-decoration: line-through;
  margin-left: 9px;
}
.product-wrap .product-content .price-addtocart .product-price span.old2 {
  font-weight: 500;
  font-size: 14px;
  text-decoration: line-through;
  margin-left: 9px;
}
.product-wrap .product-content .price-addtocart .product-addtocart a {
  color: #ff5151;
  opacity: 0;
  visibility: hidden;
  margin-right: 0;
  transition: all 0.5s ease 0s;
}
.product-wrap .product-content .price-addtocart .product-addtocart a:hover {
  color: #212529;
}
.product-wrap .product-content.product-content-padding {
  padding: 21px 20px;
}
.product-wrap .product-content .timer-style-2 {
  border-top: 1px solid #f1f1f1;
  margin-top: 14px;
  padding-top: 16px;
}
.product-wrap .product-content .timer-style-2 div {
  display: flex;
  flex-wrap: wrap;
}
.product-wrap .product-content .timer-style-2 div > span {
  text-align: center;
  font-size: 16px;
  color: #000000;
  position: relative;
  margin-right: 26px;
}
.product-wrap .product-content .timer-style-2 div > span:last-child {
  margin-right: 0px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-content .timer-style-2 div > span {
    margin-right: 15px;
  }
}
.product-wrap .product-content .timer-style-2 div > span::before {
  background: #ffeded none repeat scroll 0 0;
  content: "";
  height: 32px;
  position: absolute;
  right: -14px;
  top: 5px;
  width: 1px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap .product-content .timer-style-2 div > span::before {
    right: -8px;
  }
}
.product-wrap .product-content .timer-style-2 div > span:last-child::before {
  display: none;
}
.product-wrap .product-content .timer-style-2 div > span p {
  margin: 10px 0 0;
  font-size: 16px;
  color: #000000;
  margin: 2px 0 0;
  line-height: 1;
}
.product-wrap .product-content-2 {
  padding: 0 40px 30px 40px;
}
.product-wrap .product-content-2 h4 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
  font-family: "Roboto Slab", serif;
}
.product-wrap .product-content-2 > span {
  font-weight: 300;
  color: #9c9b9b;
  display: block;
  line-height: 1;
  margin: 6px 0 5px;
}
.product-wrap .product-content-2 .pro-price-2 {
  display: flex;
  align-items: center;
}
.product-wrap .product-content-2 .pro-price-2 span {
  color: #373737;
  font-weight: bold;
  font-family: "Roboto Slab", serif;
  font-size: 22px;
  line-height: 1;
}
.product-wrap .product-content-2 .pro-price-2 span.old-price {
  font-weight: 400;
  text-decoration: line-through;
  font-size: 16px;
  margin: 0 0 0 10px;
}
.product-wrap:hover .product-content .product-addtocart a {
  opacity: 1;
  visibility: visible;
  margin-right: 30px;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-wrap:hover .product-content .product-addtocart a {
    margin-right: 15px;
  }
}
.product-wrap:hover .product-action a {
  opacity: 1;
  visibility: visible;
  margin: 5px 0 5px 0px;
}
.product-wrap:hover .product-action-3 a {
  opacity: 1;
  visibility: visible;
  margin: 7px 0 7px 0px;
}
.product-wrap:hover .product-action a:nth-child(1) {
  transition-delay: 0s;
}
.product-wrap:hover .product-action a:nth-child(2) {
  transition-delay: 0.2s;
}
.product-wrap:hover .product-action a:nth-child(3) {
  transition-delay: 0.3s;
}
.product-wrap:hover .product-action-3 a:nth-child(1) {
  transition-delay: 0s;
}
.product-wrap:hover .product-action-3 a:nth-child(2) {
  transition-delay: 0.2s;
}
.product-wrap:hover .product-action-3 a:nth-child(3) {
  transition-delay: 0.3s;
}
.product-wrap:hover .product-action-2 {
  opacity: 1;
  visibility: visible;
  bottom: 0px;
}
.product-wrap:hover .product-action-4 {
  opacity: 1;
  visibility: visible;
  bottom: 20px;
}
.product-wrap.product-border-1 {
  border: 1px solid #f7f8f9;
}
.product-wrap.product-border-2 {
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
}
.product-wrap.product-border-2:hover {
  border-bottom: 0px solid #f1f1f1;
}
.product-wrap.product-border-3 {
  border: 1px solid #f2f2f2;
}
.product-wrap.pro-hover-shadow {
  transition: all 0.3s ease 0s;
}
.product-wrap.pro-hover-shadow:hover {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}
.product-wrap.product-img-zoom .product-img a img {
  transition: all 0.5s ease 0s;
  transform: scale(1);
}
.product-wrap.product-img-zoom:hover .product-img a img {
  transform: scale(1.1);
}
@media only screen and (max-width: 767px) {
  .product-wrap.mb-40 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .product-area.pb-60 {
    padding-bottom: 10px;
  }
  .product-area.pt-60 {
    padding-top: 20px;
  }
  .product-area.pb-85 {
    padding-bottom: 45px;
  }
  .product-area.pb-40 {
    padding-bottom: 20px;
  }
  .product-area.pb-95 {
    padding-bottom: 45px;
  }
}

.modal-dialog {
  margin: 2% auto 8%;
  max-width: 960px;
  width: 960px;
  padding: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .modal-dialog {
    width: 720px;
    max-width: 720px;
  }
}
@media only screen and (max-width: 767px) {
  .modal-dialog {
    width: 100%;
    max-width: 100%;
    padding: 35px 0;
  }
}
.modal-dialog .modal-header .close {
  color: #333;
  cursor: pointer;
  opacity: 1;
}
.modal-dialog .modal-header .close:hover {
  color: #ff5151;
}
.modal-dialog .modal-body {
  padding: 35px 15px;
}
.modal-dialog .quickview-big-img img {
  width: 100%;
}

.product-details-content > span {
  font-size: 18px;
  color: #333333;
  margin: 0 0 10px;
}
.product-details-content h2 {
  font-weight: bold;
  color: #333333;
  font-size: 28px;
  margin: 13px 0 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-content h2 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content h2 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content h2 {
    font-size: 20px;
    margin: 7px 0 10px;
  }
}
.product-details-content .product-ratting-review {
  display: flex;
  align-items: center;
  margin: 0 0 38px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .product-ratting-review {
    margin: 0 0 18px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content .product-ratting-review {
    margin: 0 0 15px;
  }
}
.product-details-content .product-ratting-review .product-ratting {
  margin-right: 20px;
}
.product-details-content .product-ratting-review .product-ratting i {
  font-size: 18px;
  color: #ff5151;
  opacity: 0.4;
}
.product-details-content .product-ratting-review .product-review span {
  font-size: 14px;
  color: #959595;
  letter-spacing: 0.2px;
}
.product-details-content .pro-details-color-wrap {
  margin: 0 0 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-color-wrap {
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-color-wrap {
    margin: 0 0 15px;
  }
}
.product-details-content .pro-details-color-wrap span {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 0 0 14px;
  display: block;
}
.product-details-content .pro-details-color-wrap .pro-details-color-content {
  display: block;
  overflow: hidden;
}
.product-details-content .pro-details-color-wrap .pro-details-color-content ul li {
  border-radius: 100%;
  cursor: pointer;
  display: block;
  float: left;
  height: 28px;
  margin-right: 15px;
  transition: all 0.4s ease 0s;
  width: 28px;
}
.product-details-content .pro-details-color-wrap .pro-details-color-content ul li.green {
  background-color: #75ffba;
}
.product-details-content .pro-details-color-wrap .pro-details-color-content ul li.yellow {
  background-color: #ffcd75;
}
.product-details-content .pro-details-color-wrap .pro-details-color-content ul li.red {
  background-color: #ff4f4f;
}
.product-details-content .pro-details-color-wrap .pro-details-color-content ul li.blue {
  background-color: #5666ff;
}
.product-details-content .pro-details-size {
  margin-bottom: 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-size {
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-size {
    margin: 0 0 15px;
  }
}
.product-details-content .pro-details-size span {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin: 0 0 8px;
  display: block;
}
.product-details-content .pro-details-size .pro-details-size-content ul li {
  display: inline-block;
  margin-right: 38px;
}
.product-details-content .pro-details-size .pro-details-size-content ul li:last-child {
  margin-right: 0px;
}
.product-details-content .pro-details-size .pro-details-size-content ul li a {
  font-size: 16px;
  color: #333333;
  text-transform: uppercase;
}
.product-details-content .pro-details-size .pro-details-size-content ul li a:hover {
  color: #ff5151;
}
.product-details-content .pro-details-price-wrap {
  display: flex;
  margin-bottom: 36px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-price-wrap {
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-price-wrap {
    margin: 0 0 15px;
  }
}
.product-details-content .pro-details-price-wrap .product-price span {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-price-wrap .product-price span {
    font-size: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-price-wrap .product-price span {
    font-size: 20px;
  }
}
.product-details-content .pro-details-price-wrap .product-price span.old {
  font-size: 16px;
  text-decoration: line-through;
  font-weight: 400;
  margin-left: 10px;
}
.product-details-content .pro-details-price-wrap .dec-rang span {
  color: #fff;
  display: inline-block;
  background-color: #333333;
  padding: 3px 6px;
  line-height: 1;
  margin-left: 20px;
}
.product-details-content .pro-details-quality .cart-plus-minus {
  border: 1px solid #ffb9b9;
  display: inline-block;
  height: 41px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 120px;
  border-radius: 100px;
}
.product-details-content .pro-details-quality .cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}
.product-details-content .pro-details-quality .cart-plus-minus .qtybutton.dec {
  height: 60px;
  left: 21px;
  padding-top: 9px;
  top: 0;
}
.product-details-content .pro-details-quality .cart-plus-minus .qtybutton.inc {
  height: 60px;
  padding-top: 9px;
  right: 18px;
  top: 0;
}
.product-details-content .pro-details-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #333333;
  float: left;
  font-size: 18px;
  height: 39px;
  margin: 0;
  padding: 0;
  text-align: center;
  width: 120px;
}
.product-details-content .pro-details-compare-wishlist {
  display: flex;
  border-bottom: 1px solid #f1f1f1;
  margin: 37px 0 30px;
  padding-bottom: 16px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-content .pro-details-compare-wishlist {
    margin: 20px 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-content .pro-details-compare-wishlist {
    margin: 15px 0 15px;
  }
}
.product-details-content .pro-details-compare-wishlist .pro-details-compare {
  margin-right: 11px;
}
.product-details-content .pro-details-compare-wishlist .pro-details-compare a {
  font-size: 14px;
  color: #333333;
}
.product-details-content .pro-details-compare-wishlist .pro-details-compare a:hover {
  color: #ff5151;
}
.product-details-content .pro-details-compare-wishlist .pro-details-compare a i {
  font-size: 16px;
  color: #333333;
  margin-left: 5px;
}
.product-details-content .pro-details-compare-wishlist .pro-details-wishlist a {
  font-size: 14px;
  color: #333333;
}
.product-details-content .pro-details-compare-wishlist .pro-details-wishlist a:hover {
  color: #ff5151;
}
.product-details-content .pro-details-compare-wishlist .pro-details-wishlist a i {
  font-size: 16px;
  color: #333333;
  margin-left: 5px;
}
.product-details-content .pro-details-buy-now a {
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  color: #333;
  border: 1px solid #ffb9b9;
  transition: all 0.5s ease-in-out 0s;
  padding: 12px 30px 12px;
  border-radius: 50px;
  z-index: 1;
}
.product-details-content .pro-details-buy-now a:hover {
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .product-details-content.quickview-content {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-content.quickview-content {
    margin-top: 0px;
  }
}
.product-details-content.pro-details-center {
  margin-top: 30px;
}
.product-details-content.pro-details-center .product-ratting-review {
  justify-content: center;
}
.product-details-content.pro-details-center .pro-details-color-wrap .pro-details-color-content ul {
  display: inline-block;
}
.product-details-content.pro-details-center .pro-details-price-wrap {
  justify-content: center;
}
.product-details-content.pro-details-center .pro-details-compare-wishlist {
  justify-content: center;
}

.nav-style-2.owl-carousel > .owl-nav button {
  color: #1f2226;
  display: inline-block;
  font-size: 13px;
  left: 0px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
}
.nav-style-2.owl-carousel > .owl-nav button:hover {
  color: #ffffff;
  background-color: #ff5151;
  border: 1px solid #ff5151;
}
.nav-style-2.owl-carousel > .owl-nav button.owl-next {
  right: 0px;
  left: auto;
}
.nav-style-2.owl-carousel:hover > .owl-nav button {
  opacity: 1;
  visibility: visible;
}

.product-tab-list {
  justify-content: center;
}
.product-tab-list a {
  position: relative;
  border: 1px solid #f5f5f5;
  flex: 0 0 200px;
  padding: 40px 30px 35px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list a {
    flex: 0 0 160px;
    padding: 40px 15px 35px;
  }
}
@media only screen and (max-width: 767px) {
  .product-tab-list a {
    flex: 0 0 50%;
    padding: 40px 15px 35px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list a {
    flex: 0 0 25%;
    padding: 40px 15px 35px;
  }
}
.product-tab-list a span {
  position: relative;
  display: block;
}
.product-tab-list a span img.fixed-img {
  opacity: 1;
  visibility: visible;
}
.product-tab-list a span img.hover-img {
  position: absolute;
  top: 20px;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
.product-tab-list a h5 {
  font-size: 18px;
  color: #373737;
  line-height: 25px;
  font-family: "Roboto Slab", serif;
  margin: 24px 0 0;
}
@media only screen and (max-width: 767px) {
  .product-tab-list a h5 {
    font-size: 16px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list a h5 {
    font-size: 15px;
  }
}
.product-tab-list a.active {
  background-color: #ff5151;
}
.product-tab-list a.active h5 {
  color: #fff;
}
.product-tab-list a.active span img.fixed-img {
  opacity: 0;
  visibility: hidden;
}
.product-tab-list a.active span img.hover-img {
  opacity: 1;
  visibility: visible;
  top: 0px;
}
@media only screen and (max-width: 767px) {
  .product-tab-list.pb-60 {
    padding-bottom: 30px;
  }
}

.tab-content.jump .tab-pane {
  display: block;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.tab-content.jump .tab-pane.active {
  display: block;
  height: auto;
  opacity: 1;
  overflow: visible;
}

.product-tab-list-2 {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #ffb9b9;
  padding-bottom: 7px;
}
.product-tab-list-2 a {
  margin: 0 34px;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-2 a {
    margin: 0 6px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-2 a {
    margin: 0 15px;
  }
}
.product-tab-list-2 a h5 {
  font-size: 24px;
  font-weight: 500;
  color: #c4c4c4;
  letter-spacing: 0.25px;
  line-height: 24px;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-2 a h5 {
    font-size: 18px;
    line-height: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-2 a h5 {
    font-size: 22px;
  }
}
.product-tab-list-2 a.active {
  position: relative;
}
.product-tab-list-2 a.active::before {
  border-bottom: 6px solid #ff9797;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -7px;
  width: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease 0s;
  z-index: 99;
}
.product-tab-list-2 a.active h5 {
  color: #373737;
}

.product-tab-list-3 {
  display: flex;
  justify-content: center;
  padding-bottom: 55px;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-3 {
    padding-bottom: 20px;
  }
}
.product-tab-list-3 a {
  margin: 0 10px;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-3 a {
    margin: 0 7px 15px;
  }
}
.product-tab-list-3 a h5 {
  font-size: 16px;
  font-weight: 300;
  color: #17062a;
  text-transform: uppercase;
  border: 1px solid #17062a;
  display: inline-block;
  padding: 11px 40px 10px;
  margin: 0;
  border-radius: 5px;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-3 a h5 {
    padding: 9px 20px 8px;
  }
}
.product-tab-list-3 a.active h5 {
  background-color: #17062a;
  color: #ffffff;
}

.product-tab-list-4 {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-4 {
    justify-content: flex-start;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-4 {
    justify-content: flex-end;
  }
}
.product-tab-list-4 a {
  margin: 0 0px 0 45px;
}
.product-tab-list-4 a:first-child {
  margin: 0 0px 0 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-4 a {
    margin: 0 0px 0 30px;
  }
}
@media only screen and (max-width: 767px) {
  .product-tab-list-4 a {
    margin: 0 0px 0 9px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-4 a {
    margin: 0 0px 0 12px;
  }
}
.product-tab-list-4 a h5 {
  font-size: 16px;
  color: #333745;
  letter-spacing: 0.25px;
  line-height: 21px;
  margin: 0;
  transition: all 0.5s ease 0s;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-4 a h5 {
    font-size: 14px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-4 a h5 {
    font-size: 15px;
  }
}
.product-tab-list-4 a.active, .product-tab-list-4 a:hover {
  position: relative;
}
.product-tab-list-4 a.active::before, .product-tab-list-4 a:hover::before {
  border-bottom: 6px solid #ff9797;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  height: 0;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -10px;
  width: 0;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease 0s;
  z-index: 9999;
}
.product-tab-list-4 a.active h5, .product-tab-list-4 a:hover h5 {
  color: #ea2e49;
}

.section-title-tab-wrap {
  border-bottom: 1px solid #ffb9b9;
  padding-bottom: 10px;
  margin-bottom: 33px;
}

.single-recent-selling {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
}
.single-recent-selling .recent-selling-img {
  flex: 0 0 130px;
}
.single-recent-selling .recent-selling-img a img {
  width: 100%;
}
.single-recent-selling .recent-selling-content {
  padding: 0 10px 0 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-recent-selling .recent-selling-content {
    padding: 0 10px 0 16px;
  }
}
@media only screen and (max-width: 767px) {
  .single-recent-selling .recent-selling-content {
    padding: 0 10px 0 16px;
  }
}
.single-recent-selling .recent-selling-content h4 {
  font-size: 18px;
  color: #434343;
  font-weight: 500;
  margin: 0 0 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
}
.single-recent-selling .recent-selling-content .price span {
  color: #ff5151;
  font-size: 18px;
  letter-spacing: 0.2px;
}
.single-recent-selling .recent-selling-content .price span.old {
  font-size: 14px;
  color: #9e9e9e;
  text-decoration: line-through;
  margin-left: 3px;
}

@media only screen and (max-width: 767px) {
  .recent-selling-area.pb-60 {
    padding-bottom: 20px;
  }
}
.product-tab-list-5 {
  display: flex;
  justify-content: flex-end;
  margin: 13px 0 0;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-5 {
    justify-content: center;
    margin: 0px 0 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-5 {
    justify-content: flex-end;
  }
}
.product-tab-list-5 a {
  margin: 0 0px 0 60px;
  transition: all 0.5s ease 0s;
}
.product-tab-list-5 a:first-child {
  margin: 0 0px 0 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-tab-list-5 a {
    margin: 0 0px 0 30px;
  }
}
@media only screen and (max-width: 767px) {
  .product-tab-list-5 a {
    margin: 0 0px 0 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-5 a {
    margin: 0 0px 0 28px;
  }
}
.product-tab-list-5 a h5 {
  font-size: 16px;
  color: #373737;
  line-height: 15px;
  margin: 0;
  transition: all 0.5s ease 0s;
}
@media only screen and (max-width: 767px) {
  .product-tab-list-5 a h5 {
    font-size: 14px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-tab-list-5 a h5 {
    font-size: 15px;
  }
}
.product-tab-list-5 a.active, .product-tab-list-5 a:hover {
  position: relative;
  transition: all 0.5s ease 0s;
}
.product-tab-list-5 a.active::before, .product-tab-list-5 a:hover::before {
  position: absolute;
  left: -10px;
  bottom: 2px;
  background-color: #ffb9b9;
  width: 37px;
  height: 8px;
  content: "";
  z-index: -1;
  transition: all 0.5s ease 0s;
}

.section-title-tab-wrap2 {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .section-title-tab-wrap2 {
    margin-bottom: 30px;
  }
}

.shop-topbar-wrapper {
  border: 1px solid #e9e9e9;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding: 15px 24px;
}
@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper {
    display: block;
    padding: 15px 15px;
    justify-content: inherit;
  }
}
.shop-topbar-wrapper .shop-topbar-left .view-mode {
  display: inline-block;
  float: left;
  margin-right: 33px;
  display: inline-block;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-topbar-wrapper .shop-topbar-left .view-mode {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-topbar-wrapper .shop-topbar-left .view-mode {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .shop-topbar-left .view-mode {
    margin-right: 7px;
  }
}
.shop-topbar-wrapper .shop-topbar-left .view-mode a {
  color: #333;
  font-size: 25px;
  display: inline-block;
  margin: 0 10px 0 0;
}
.shop-topbar-wrapper .shop-topbar-left .view-mode a.active {
  color: #ff5151;
}
.shop-topbar-wrapper .shop-topbar-left p {
  display: inline-block;
  margin: 0px 0 0;
  color: #333;
}
.shop-topbar-wrapper .product-sorting-wrapper {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper {
    display: block;
    flex-wrap: inherit;
    overflow: hidden;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
}
.shop-topbar-wrapper .product-sorting-wrapper .shorting-style {
  margin-left: 20px;
  display: flex;
}
@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style {
    margin-left: 0px;
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style {
    margin-left: 15px;
    margin-bottom: 0px;
  }
}
.shop-topbar-wrapper .product-sorting-wrapper .shorting-style label {
  color: #242424;
  cursor: pointer;
  margin: 3px 11px 0 0;
  font-size: 14px;
}
.shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url("../../assets/images/icon-img/shop.png") no-repeat scroll right 10px center;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  box-shadow: none;
  color: #242424;
  cursor: pointer;
  font-size: 14px;
  height: 30px;
  padding-left: 10px;
  width: 210px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
    width: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
    width: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style select {
    width: 150px;
  }
}
.shop-topbar-wrapper .product-sorting-wrapper .shorting-style select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  font-size: 14px;
}
.shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting {
  margin-left: 0;
}
.shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
  width: 110px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
    width: 75px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
    width: 75px;
  }
}
@media only screen and (max-width: 767px) {
  .shop-topbar-wrapper .product-sorting-wrapper .shorting-style.product-shorting select {
    width: 163px;
  }
}

.shop-list-wrap .product-list-img {
  position: relative;
  transition: all 0.3s ease 0s;
}
.shop-list-wrap .product-list-img a img {
  width: 100%;
}
.shop-list-wrap .product-list-img .product-list-quickview {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
  margin-top: 20px;
}
.shop-list-wrap .product-list-img .product-list-quickview a {
  font-size: 22px;
  color: #737373;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  text-align: center;
}
.shop-list-wrap .product-list-img .product-list-quickview a:hover {
  color: #fff;
  border: 1px solid #ff5151;
  background-color: #ff5151;
}
.shop-list-wrap .product-list-img.product-border-2 {
  border: 1px solid #f1f1f1;
}
.shop-list-wrap .shop-list-content span {
  color: #737373;
  font-size: 15px;
}
.shop-list-wrap .shop-list-content h4 {
  font-size: 20px;
  margin: 5px 0 17px;
  font-weight: 500;
}
.shop-list-wrap .shop-list-content .pro-list-price span {
  color: #373737;
  font-weight: bold;
  font-size: 22px;
  line-height: 1;
}
.shop-list-wrap .shop-list-content .pro-list-price span.old-price {
  font-weight: 400;
  text-decoration: line-through;
  font-size: 16px;
  margin: 0 0 0 10px;
}
.shop-list-wrap .shop-list-content p {
  font-size: 15px;
  color: #333333;
  line-height: 25px;
  margin: 10px 0 20px;
}
.shop-list-wrap .shop-list-content .product-list-action a {
  font-size: 22px;
  color: #222;
  border: 1px solid #e6e6e6;
  background-color: #F7F8FA;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  text-align: center;
  margin-right: 10px;
}
.shop-list-wrap .shop-list-content .product-list-action a:last-child {
  margin-right: 0;
}
.shop-list-wrap .shop-list-content .product-list-action a:hover {
  color: #fff;
  border: 1px solid #ff5151;
  background-color: #ff5151;
}
@media only screen and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .shop-list-wrap .shop-list-content {
    margin-top: 0px;
  }
}
.shop-list-wrap:hover .product-list-img .product-list-quickview {
  opacity: 1;
  visibility: visible;
  margin-top: 0px;
}
.shop-list-wrap:hover .product-list-img.pro-hover-shadow {
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.07);
}

/*-------- 7. Discount style ---------*/
.discount-img {
  margin: 0px 0 0 70px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .discount-img {
    margin: 0px 0 0 30px;
  }
}
.discount-img:before {
  background-color: #fff6f6;
  content: "";
  height: calc(100% - 30px);
  left: -70px;
  position: absolute;
  top: -40px;
  width: 100%;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  .discount-img:before {
    top: -30px;
    left: -30px;
  }
}
.discount-img a img {
  width: 100%;
}

.discount-content {
  margin-left: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .discount-content {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .discount-content {
    margin-left: 0px;
    margin-top: 30px;
  }
}
.discount-content p.bright-color {
  color: #373737;
  width: 83%;
  margin: 0 0 8px;
}
@media only screen and (max-width: 767px) {
  .discount-content p.bright-color {
    width: 100%;
  }
}
.discount-content h2 {
  font-size: 36px;
  color: #383838;
  font-weight: bold;
  margin: 21px 0 34px;
  line-height: 46px;
  letter-spacing: 0.5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .discount-content h2 {
    margin: 15px 0 20px;
    line-height: 40px;
    font-size: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .discount-content h2 {
    margin: 15px 0 20px;
    line-height: 40px;
    font-size: 26px;
  }
}

/*-------- 8. Footer style ---------*/
.footer-widget .footer-social {
  margin-top: 21px;
}
.footer-widget .footer-social span {
  font-size: 16px;
  color: #737373;
}
.footer-widget .footer-social ul {
  margin-top: 23px;
}
.footer-widget .footer-social ul li {
  display: inline-block;
  margin-right: 23px;
}
.footer-widget .footer-social ul li:last-child {
  margin-right: 0;
}
.footer-widget .footer-social ul li a {
  font-size: 16px;
  color: #9d9d9d;
}
.footer-widget .footer-social ul li a:hover {
  color: #ff5151;
}
.footer-widget .footer-title {
  margin: 0 0 30px;
}
@media only screen and (max-width: 767px) {
  .footer-widget .footer-title {
    margin: 0 0 18px;
  }
}
.footer-widget .footer-title h3 {
  font-size: 16px;
  color: #373737;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}
.footer-widget .footer-title-2 {
  margin: 0 0 40px;
}
@media only screen and (max-width: 767px) {
  .footer-widget .footer-title-2 {
    margin: 0 0 20px;
  }
}
.footer-widget .footer-title-2 h3 {
  color: #ffffff;
  font-weight: bold;
  font-size: 18px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-title-2 h3 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-widget .footer-title-2 h3 {
    font-size: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-widget .footer-title-2 h3 {
    font-size: 16px;
  }
}
.footer-widget .footer-title-3 {
  margin: 0 0 35px;
}
.footer-widget .footer-title-3 h3 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 0px;
}
.footer-widget .footer-title-4 {
  margin: 0 0 35px;
}
.footer-widget .footer-title-4 h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 0px;
  color: #ababab;
  text-transform: uppercase;
  position: relative;
  padding: 0 0 10px;
}
.footer-widget .footer-title-4 h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #ff5151;
}
.footer-widget .footer-list ul li {
  margin: 0 0 10px;
}
.footer-widget .footer-list ul li:last-child {
  margin-right: 0;
}
.footer-widget .footer-list ul li a {
  color: #737373;
  font-size: 16px;
}
.footer-widget .footer-list ul li a:hover {
  color: #ff5151;
}
.footer-widget .footer-list-2 ul li {
  margin: 0px 0 16px;
}
.footer-widget .footer-list-2 ul li:last-child {
  margin-right: 0;
}
.footer-widget .footer-list-2 ul li a {
  color: #999999;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}
.footer-widget .footer-list-2 ul li a:hover {
  color: #ff5151;
}
.footer-widget .footer-list-3 ul li {
  margin: 0px 0 18px;
}
.footer-widget .footer-list-3 ul li:last-child {
  margin-right: 0;
}
.footer-widget .footer-list-4 ul li {
  margin: 0px 0 16px;
}
.footer-widget .footer-list-4 ul li:last-child {
  margin: 0px 0 0px;
}
.footer-widget .footer-list-4 ul li a {
  font-size: 16px;
  font-weight: 500;
  color: #ababab;
}
.footer-widget .footer-list-4 ul li a:hover {
  color: #ff5151;
}
.footer-widget .footer-contact-wrap p {
  font-family: "Poppins", sans-serif;
  color: #999999;
  margin: 0;
}
.footer-widget .footer-contact-wrap .subscribe-form-2 {
  margin: 27px 0 37px;
}
.footer-widget .footer-contact-wrap .subscribe-form-2 .mc-form {
  position: relative;
}
.footer-widget .footer-contact-wrap .subscribe-form-2 .mc-form > input {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  height: 45px;
  padding: 2px 60px 2px 30px;
  color: #999999;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
}
.footer-widget .footer-contact-wrap .subscribe-form-2 .mc-form .mc-news {
  display: none;
}
.footer-widget .footer-contact-wrap .subscribe-form-2 .mc-form .clear {
  display: inline-block;
  position: absolute;
  right: 2px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 50px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/footer-mail.png") no-repeat scroll right 22px center;
}
.footer-widget .footer-contact-wrap .subscribe-form-2 .mc-form .clear input {
  background-color: transparent;
  border: none;
}
.footer-widget .footer-contact-wrap .subscribe-form-2 .mc-form .clear:hover {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/footer-mail-hover.png") no-repeat scroll right 22px center;
}
.footer-widget .footer-contact-wrap .footer-contact-content p {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  color: #999999;
  margin: 0 0 16px;
}
.footer-widget .footer-contact-wrap .footer-contact-content p:last-child {
  margin: 0;
}
.footer-widget .footer-contact-wrap .footer-contact-content p i {
  color: #999999;
  font-size: 20px;
  margin-right: 12px;
}
.footer-widget .subscribe-style > p {
  color: #373737;
  font-size: 16px;
  margin: 0;
}
.footer-widget .subscribe-style .subscribe-form .mc-form {
  position: relative;
}
.footer-widget .subscribe-style .subscribe-form .mc-form input {
  font-size: 14px;
  color: #737373;
  letter-spacing: 0.02px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e3e3e3;
  padding: 2px 60px 2px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form input {
    padding: 2px 40px 2px 0;
  }
}
.footer-widget .subscribe-style .subscribe-form .mc-form input::-webkit-input-placeholder {
  color: #737373;
  opacity: 1;
}
.footer-widget .subscribe-style .subscribe-form .mc-form .mc-news {
  display: none;
}
.footer-widget .subscribe-style .subscribe-form .mc-form .clear {
  display: inline-block;
  position: absolute;
  right: 2px;
  top: 0;
  transition: all 0.3s ease 0s;
  width: 50px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/footer-search.png") no-repeat scroll right 18px center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .subscribe-style .subscribe-form .mc-form .clear {
    background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/footer-search.png") no-repeat scroll right 0px center;
  }
}
.footer-widget .subscribe-style .subscribe-form .mc-form .clear input {
  width: 50px;
  cursor: pointer;
  z-index: -1;
}
.footer-widget .subscribe-style .subscribe-form .mc-form .clear:hover {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/footer-search-hover.png") no-repeat scroll right 18px center;
}
@media only screen and (max-width: 767px) {
  .footer-widget .subscribe-style.mt-45 {
    margin-top: 18px;
  }
}
.footer-widget .footer-about p {
  color: #373737;
  font-family: "Roboto", sans-serif;
  margin: 0;
  line-height: 25px;
  margin: 42px 0 0;
  width: 76%;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget .footer-about p {
    width: 90%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget .footer-about p {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget .footer-about p {
    width: 90%;
    margin: 25px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .footer-widget .footer-about p {
    width: 100%;
    margin: 20px 0 0;
  }
}
.footer-widget.footer-ngtv-mrg1 {
  margin-left: -12px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.footer-ngtv-mrg1 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.footer-ngtv-mrg1 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-widget.footer-ngtv-mrg1 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .footer-widget.ml-135 {
    margin-left: 55px;
  }
  .footer-widget.ml-200 {
    margin-left: 145px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .footer-widget.ml-135 {
    margin-left: 40px;
  }
  .footer-widget.ml-35 {
    margin-left: 0px;
  }
  .footer-widget.ml-200 {
    margin-left: 120px;
  }
  .footer-widget.ml-55 {
    margin-left: 60px;
  }
  .footer-widget.ml-55.mrg-md-footer {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-widget.ml-135 {
    margin-left: 0px;
  }
  .footer-widget.ml-35 {
    margin-left: 0px;
  }
  .footer-widget.ml-200 {
    margin-left: 70px;
  }
  .footer-widget.ml-55 {
    margin-left: 50px;
  }
  .footer-widget.ml-55.mrg-md-footer {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-widget.ml-135 {
    margin-left: 0px;
  }
  .footer-widget.ml-35 {
    margin-left: 0px;
  }
  .footer-widget.ml-55 {
    margin-left: 0px;
  }
  .footer-widget.ml-55.mrg-md-footer {
    margin-left: 30px;
  }
  .footer-widget.ml-200 {
    margin-left: 0px;
  }
  .footer-widget.f-right {
    float: left;
    margin-left: 30px;
  }
  .footer-widget.footer-mrg-hm1 {
    margin-left: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-widget.ml-135 {
    margin-left: 0px;
  }
  .footer-widget.ml-35 {
    margin-left: 0px;
  }
  .footer-widget.ml-55 {
    margin-left: 0px;
  }
  .footer-widget.ml-200 {
    margin-left: 0px;
  }
  .footer-widget.f-right {
    float: left;
  }
}

.copyright p {
  letter-spacing: 13px;
  letter-spacing: 1px;
}
.copyright p a {
  color: #737373;
}
.copyright p a:hover {
  color: #ff5151;
}

.copyright-2 p {
  letter-spacing: 1px;
  color: #999999;
}
.copyright-2 p a {
  color: #999999;
}
.copyright-2 p a:hover {
  color: #ff5151;
}

.copyright-3 p {
  color: #6d6d6d;
}
.copyright-3 p a {
  color: #6d6d6d;
}
.copyright-3 p a:hover {
  color: #ff5151;
}

.copyright-5 p {
  color: #919191;
  line-height: 1;
}
.copyright-5 p a {
  color: #919191;
}
.copyright-5 p a:hover {
  color: #ff5151;
}
@media only screen and (max-width: 767px) {
  .copyright-5 {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .copyright-5 {
    margin-bottom: 0px;
  }
}

.copyright-4 {
  margin-top: 33px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .copyright-4 {
    margin-top: 7px;
  }
}
@media only screen and (max-width: 767px) {
  .copyright-4 {
    margin-top: 0px;
  }
}
.copyright-4 p {
  color: #919191;
  line-height: 1;
}
.copyright-4 p a {
  color: #919191;
}
.copyright-4 p a:hover {
  color: #ff5151;
}

@media only screen and (max-width: 767px) {
  .footer-top.pb-75 {
    padding-bottom: 20px;
  }
  .footer-top.pb-85 {
    padding-bottom: 20px;
  }
  .footer-top.pb-40 {
    padding-bottom: 15px;
  }
  .footer-top.pb-60 {
    padding-bottom: 10px;
  }
}

.footer-overlay::before {
  opacity: 0.9;
  background-color: #000;
}

.footer-tag-wrap {
  display: flex;
  padding: 0 15px 35px;
}
.footer-tag-wrap .footer-tag-title {
  flex: 0 0 57px;
}
.footer-tag-wrap .footer-tag-title span {
  color: #373737;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}
.footer-tag-wrap .footer-tag-list ul li {
  display: inline-block;
  margin: 0 12px 5px 0;
  position: relative;
}
.footer-tag-wrap .footer-tag-list ul li:before {
  background-color: #6c6d6e;
  position: absolute;
  height: 12px;
  width: 1px;
  content: "";
  right: -9px;
  top: 5px;
  transform: rotate(20deg);
}
.footer-tag-wrap .footer-tag-list ul li:last-child:before {
  display: none;
}
.footer-tag-wrap .footer-tag-list ul li a {
  color: #373737;
  font-weight: 300;
  font-style: italic;
}
.footer-tag-wrap .footer-tag-list ul li a:hover {
  color: #ff5151;
}

.footer-menu {
  margin-left: 77px;
}
@media only screen and (max-width: 767px) {
  .footer-menu {
    margin-left: 0px;
    text-align: center;
  }
}
.footer-menu nav ul li {
  display: inline-block;
  margin-right: 75px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-menu nav ul li {
    margin-right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-menu nav ul li {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-menu nav ul li {
    margin-right: 18px;
  }
}
.footer-menu nav ul li:last-child {
  margin-right: 0;
}
.footer-menu nav ul li a {
  color: #010211;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
}
.footer-menu nav ul li a:hover {
  color: #ff5151;
}

.footer-social-2 {
  margin-top: 29px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-social-2 {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-social-2 {
    margin-top: 0px;
    text-align: center;
  }
}
.footer-social-2 ul {
  margin: 5px 0 0;
}
.footer-social-2 ul li {
  display: inline-block;
  margin-right: 27px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-social-2 ul li {
    margin-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-social-2 ul li {
    margin-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-social-2 ul li {
    margin-right: 15px;
  }
}
.footer-social-2 ul li:last-child {
  margin-right: 0px;
}
.footer-social-2 ul li a {
  font-size: 14px;
  color: #ababab;
}
.footer-social-2 ul li a:hover {
  color: #ff5151;
}

.footer-payment {
  text-align: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-payment a img {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .footer-payment {
    text-align: center;
  }
  .footer-payment a img {
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-payment a img {
    width: auto;
  }
  .footer-payment.footer-payment2 a img {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .footer-logo-4 {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .subscribe-bg.pb-50 {
    padding-bottom: 50px;
  }
}
.subscribe-content-3 h2 {
  font-size: 26px;
  font-weight: bold;
  color: #393939;
  margin: 0 0 28px;
}
@media only screen and (max-width: 767px) {
  .subscribe-content-3 h2 {
    font-size: 20px;
  }
}
.subscribe-content-3 .subscribe-form-3 {
  position: relative;
}
.subscribe-content-3 .subscribe-form-3 .mc-form {
  position: relative;
}
.subscribe-content-3 .subscribe-form-3 .mc-form input {
  height: 50px;
  border-radius: 5px;
  border: 1px solid #eeeff3;
  font-size: 14px;
  color: #393939;
  font-weight: 300;
  letter-spacing: 1px;
  background-color: transparent;
  padding: 0 70px 0 30px;
}
.subscribe-content-3 .subscribe-form-3 .mc-form input::-moz-placeholder {
  color: #393939;
  opacity: 1 !important;
}
.subscribe-content-3 .subscribe-form-3 .mc-form input::-webkit-input-placeholder {
  color: #393939;
  opacity: 1 !important;
}
.subscribe-content-3 .subscribe-form-3 .mc-form .mc-news {
  display: none;
}
.subscribe-content-3 .subscribe-form-3 .mc-form .clear {
  display: inline-block;
  position: absolute;
  right: 2px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  width: 50px;
  border: none;
  padding: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/send-hm5.png") no-repeat scroll right 33px center;
}
.subscribe-content-3 .subscribe-form-3 .mc-form .clear input {
  background-color: transparent;
  border: none;
  padding: 0 0px 0 0px;
}
.subscribe-content-3 .subscribe-form-3 .mc-form .clear:hover {
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/send-hm5-white.png") no-repeat scroll right 33px center;
}

.footer-contact-wrap-2 .footer-contact-content-2 {
  margin: 39px 0 0;
}
.footer-contact-wrap-2 .footer-contact-content-2 h5 {
  font-size: 22px;
  font-weight: 500;
  color: #ababab;
  margin: 0 0 10px;
  line-height: 1;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-info-hm5 {
  margin: 0 0 41px;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-info-hm5 span {
  font-size: 22px;
  color: #ababab;
  display: block;
  line-height: 1;
  margin: 0 0 9px;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-info-hm5 p {
  font-size: 22px;
  color: #ababab;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-info-hm5 p a {
  color: #ababab;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-info-hm5 p a:hover {
  color: #ff5151;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 span {
  font-size: 22px;
  color: #ababab;
  display: block;
  line-height: 1;
  margin: 0 0 17px;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 ul li {
  display: inline-block;
  margin-right: 28px;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 ul li:last-child {
  margin-right: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 ul li {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 ul li {
    margin-right: 10px;
  }
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 ul li a {
  color: #ababab;
}
.footer-contact-wrap-2 .footer-contact-content-2 .footer-social-hm5 ul li a:hover {
  color: #ff5151;
}

/*-------- 9. Overview style ---------*/
.overview-img {
  margin: 0 -36px 0 70px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .overview-img {
    margin: 0 0px 0 70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .overview-img {
    margin: 0 0px 0 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-img {
    margin: 0 0px 0 0px;
  }
}
@media only screen and (max-width: 767px) {
  .overview-img {
    margin: 0 0px 0 0px;
  }
}
.overview-img img {
  width: 100%;
}

.overview-content {
  padding-left: 70px;
  margin-top: -63px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .overview-content {
    padding-left: 0px;
    margin-top: -63px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-content {
    padding-left: 0px;
    margin-top: -43px;
  }
}
@media only screen and (max-width: 767px) {
  .overview-content {
    padding-left: 0px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.overview-content h2 {
  color: #292323;
  font-size: 50px;
  font-weight: bold;
  line-height: 55px;
  margin: 0;
  text-transform: uppercase;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .overview-content h2 {
    font-size: 40px;
    line-height: 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .overview-content h2 {
    font-size: 38px;
    line-height: 47px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-content h2 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .overview-content h2 {
    font-size: 25px;
    line-height: 35px;
  }
}
.overview-content p {
  font-size: 16px;
  color: #383838;
  line-height: 26px;
  width: 80%;
  margin: 23px 0 31px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .overview-content p {
    margin: 13px 0 21px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .overview-content p {
    width: 100%;
    margin: 15px 0 21px;
  }
}
@media only screen and (max-width: 767px) {
  .overview-content p {
    width: 100%;
  }
}
.overview-content .overview-video {
  display: inline-block;
}
.overview-content .overview-video a {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #010211;
  letter-spacing: 0.2px;
}
.overview-content .overview-video a i {
  font-size: 36px;
  color: #ff5151;
  margin-right: 8px;
}
.overview-content .overview-video a:hover {
  color: #ff5151;
}

/*-------- 10. Instagram style ---------*/
.instafeed-style {
  display: flex;
}

.instagram-item {
  overflow: hidden;
}
.instagram-item a img {
  width: 100%;
  transform: scale(1);
  transition: all 0.5s ease 0s;
}
.instagram-item a img:hover {
  transform: scale(1.1);
}

/*-------- 11. Category style ---------*/
.category-wrap {
  position: relative;
}
.category-wrap:before {
  background-color: #1d1d26;
  opacity: 0.6;
  pointer-events: none;
  transition: all 0.3s ease 0s;
}
.category-wrap > a {
  display: block;
}
.category-wrap > a img {
  width: 100%;
}
.category-wrap .category-content {
  position: absolute;
  top: 83px;
  left: 44px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-wrap .category-content {
    top: 30px;
    left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .category-wrap .category-content {
    top: 30px;
    left: 30px;
  }
}
.category-wrap .category-content h3 {
  color: #ffffff;
  font-size: 30px;
  margin: 14px 0 0;
  font-weight: bold;
  line-height: 35px;
  letter-spacing: 0.2px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .category-wrap .category-content h3 {
    font-size: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-wrap .category-content h3 {
    font-size: 23px;
    line-height: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-wrap .category-content h3 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .category-wrap .category-content h3 {
    font-size: 25px;
  }
}
.category-wrap .category-icon {
  position: absolute;
  bottom: 56px;
  left: 44px;
  overflow: hidden;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .category-wrap .category-icon {
    bottom: 30px;
    left: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .category-wrap .category-icon {
    bottom: 30px;
    left: 30px;
  }
}
.category-wrap .category-icon a {
  display: inline-block;
}
.category-wrap .category-icon a img {
  position: relative;
}
.category-wrap .category-icon a img.category-normal-icon {
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease 0s;
  left: 0px;
}
.category-wrap .category-icon a img.category-hover-icon {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: -20px;
  transition: all 0.4s ease 0s;
}
.category-wrap .category-icon:hover a img.category-hover-icon {
  opacity: 1;
  visibility: visible;
  left: 0px;
}
.category-wrap .category-icon:hover a img.category-normal-icon {
  opacity: 0;
  visibility: hidden;
  left: 20px;
}
.category-wrap:hover:before {
  opacity: 0.9;
}
.category-wrap:hover .category-content img {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

/*-------- 12. Deal style ---------*/
.deal-img {
  margin: 0 100px 0 -30px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-img {
    margin: 0 100px 0 0px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-img {
    margin: 0 100px 0 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-img {
    margin: 0 50px 0 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-img {
    margin: 0 0px 0 0px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-img {
    margin: 0 0px 0 0px;
    position: relative;
    z-index: 9;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .deal-img {
    margin: 0 30px 0 30px;
  }
}
.deal-img a img {
  width: 100%;
}

.deal-content {
  margin-top: 15px;
  position: relative;
  z-index: 9;
}
.deal-content h2 {
  color: #141517;
  font-size: 48px;
  font-weight: bold;
  margin: 0 0 19px;
  letter-spacing: 0.2px;
  line-height: 42px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content h2 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content h2 {
    font-size: 34px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content h2 {
    font-size: 24px;
    margin: 0 0 10px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content h2 {
    font-size: 25px;
    margin: 20px 0 10px;
    line-height: 33px;
  }
}
.deal-content span {
  font-size: 18px;
  font-weight: bold;
  color: #141517;
  letter-spacing: 0.2px;
  display: block;
  line-height: 1;
}
.deal-content .deal-price {
  display: flex;
  align-items: flex-end;
  margin: 26px 0 22px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content .deal-price {
    margin: 16px 0 12px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content .deal-price {
    margin: 18px 0 14px;
  }
}
.deal-content .deal-price span {
  font-size: 30px;
  font-weight: bold;
  color: #141517;
  letter-spacing: 0.2px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content .deal-price span {
    font-size: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content .deal-price span {
    font-size: 24px;
  }
}
.deal-content .deal-price span.old {
  font-size: 20px;
  color: #898989;
  text-decoration: line-through;
  margin: 0 0 0 18px;
}
.deal-content p {
  color: #373737;
  font-size: 16px;
  line-height: 26px;
  font-family: "Roboto", sans-serif;
  margin: 0;
  width: 81%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content p {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content p {
    width: 90%;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content p {
    width: 100%;
  }
}
.deal-content .timer-style-1 {
  margin: 32px 0 39px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content .timer-style-1 {
    margin: 22px 0 29px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content .timer-style-1 {
    margin: 20px 0 20px;
  }
}
.deal-content .timer-style-1 div {
  display: flex;
  flex-wrap: wrap;
}
.deal-content .timer-style-1 div > span {
  display: flex;
  align-items: flex-end;
  margin: 0 30px 0 0;
}
.deal-content .timer-style-1 div > span span {
  font-size: 30px;
  color: #141517;
  font-weight: 300;
  letter-spacing: 0.2px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content .timer-style-1 div > span {
    margin: 0 15px 0 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content .timer-style-1 div > span {
    margin: 0 10px 10px 0;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content .timer-style-1 div > span {
    margin: 0 30px 20px 0;
  }
}
.deal-content .timer-style-1 div > span > p {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin: 0 0 0 10px;
}

.deal-area {
  position: relative;
  overflow: hidden;
}
.deal-area img.deal-leaf-1 {
  position: absolute;
  bottom: 0;
  left: 5px;
  width: 353px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-area img.deal-leaf-1 {
    width: 220px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-area img.deal-leaf-1 {
    width: 200px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area img.deal-leaf-1 {
    width: 150px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area img.deal-leaf-1 {
    width: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-area img.deal-leaf-1 {
    width: 150px;
  }
}
.deal-area img.deal-leaf-2 {
  position: absolute;
  right: 0;
  top: 17px;
  width: 191px;
  z-index: 1;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-area img.deal-leaf-2 {
    width: 145px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-area img.deal-leaf-2 {
    width: 135px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-area img.deal-leaf-2 {
    width: 100px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-area img.deal-leaf-2 {
    width: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-area img.deal-leaf-2 {
    width: 120px;
    top: 0px;
  }
}
.deal-area img.deal-leaf-3 {
  position: absolute;
  bottom: 0;
  right: 78px;
  width: 74px;
}
@media only screen and (max-width: 767px) {
  .deal-area img.deal-leaf-3 {
    right: 20px;
  }
}

.deal-content-2 {
  margin-left: -13px;
  position: relative;
  z-index: 9;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content-2 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-2 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-2 {
    margin-left: 0px;
    text-align: center;
  }
}
.deal-content-2 h2 {
  font-size: 40px;
  font-weight: 300;
  color: #3c3d40;
  letter-spacing: 1px;
  margin: 0px;
}
@media only screen and (max-width: 767px) {
  .deal-content-2 h2 {
    font-size: 28px;
  }
}
.deal-content-2 p {
  color: #232323;
  font-size: 16px;
  line-height: 26px;
  margin: 15px 0 31px;
  width: 80%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 p {
    width: 62%;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-2 p {
    width: 100%;
    color: #000;
  }
}
.deal-content-2 .deal-5-btn {
  margin-top: 45px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2 .deal-5-btn {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-2 .deal-5-btn {
    margin-top: 10px;
  }
}
.deal-content-2 .deal-5-btn a {
  font-weight: bold;
  background-color: #ff5151;
  color: #fff;
  display: inline-block;
  line-height: 1;
  letter-spacing: 1px;
  font-size: 14px;
  z-index: 1;
  padding: 12px 30px 15px;
}
.deal-content-2.deal-content-5 p {
  width: 95%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-2.deal-content-5 p {
    width: 62%;
    margin: 15px auto 31px;
  }
}

.timer-style-3 div {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 767px) {
  .timer-style-3 div {
    justify-content: center;
  }
}
.timer-style-3 div > span {
  text-align: center;
  margin-right: 4px;
}
.timer-style-3 div > span:last-child {
  margin-right: 0px;
}
@media only screen and (max-width: 767px) {
  .timer-style-3 div > span {
    margin: 0 2px 20px;
  }
}
.timer-style-3 div > span span {
  font-size: 50px;
  color: #9390a0;
  font-weight: 300;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: #fff;
  display: block;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  z-index: 9;
  position: relative;
}
.timer-style-3 div > span p {
  font-size: 12px;
  text-transform: uppercase;
  color: #1c1c1c;
  letter-spacing: 1px;
  margin: 4px 0 0;
  line-height: 1;
  background-color: #fff;
  min-width: 100px;
  padding: 9px 0 8px;
  z-index: 1;
  position: relative;
}

.deal-img-2 {
  margin: 0 -20px 0 -33px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-img-2 {
    margin: 0 0px 0 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-img-2 {
    margin: 0 0px 0 0px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-img-2 {
    margin: 0 0px 0 0px;
  }
}
.deal-img-2 img {
  width: 100%;
}
.deal-img-2 .deal-btn {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 175px;
}
@media only screen and (max-width: 767px) {
  .deal-img-2 .deal-btn {
    bottom: 50px;
  }
}
.deal-img-2 .deal-btn a {
  display: inline-block;
  border: 1px solid #000;
  background-color: #ea2e49;
  color: #fff;
  text-transform: uppercase;
  border-radius: 50px;
  line-height: 1;
  z-index: 1;
  padding: 14px 40px 13px;
}
@media only screen and (max-width: 767px) {
  .deal-img-2 .deal-btn a {
    padding: 14px 15px 13px;
  }
}

.deal-content-3 {
  margin-left: 70px;
  margin-top: -55px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-3 {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-3 {
    margin-left: 0px;
    margin-top: 35px;
    margin-bottom: 50px;
    text-align: center;
  }
  .deal-content-3 .timer-style-3 div {
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-3 {
    margin-left: 0px;
    margin-top: 35px;
    margin-bottom: 50px;
    text-align: center;
  }
  .deal-content-3 .timer-style-3 div {
    justify-content: center;
  }
}
.deal-content-3 h2 {
  font-weight: bold;
  font-size: 50px;
  color: #292323;
  margin: 0 0 30px;
  text-transform: uppercase;
  line-height: 55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content-3 h2 {
    font-size: 36px;
    margin: 0 0 20px;
    line-height: 45px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-3 h2 {
    font-size: 30px;
    margin: 0 0 20px;
    line-height: 38px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-3 h2 {
    font-size: 30px;
    margin: 0 0 20px;
    line-height: 38px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-3 h2 {
    font-size: 24px;
    margin: 0 0 20px;
    line-height: 32px;
  }
}

.deal-content-4 {
  margin-left: 70px;
}
@media only screen and (max-width: 767px) {
  .deal-content-4 {
    margin-left: 0px;
  }
}
.deal-content-4 img {
  width: 381px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-4 img {
    width: 330px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-4 img {
    width: 280px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-4 img {
    width: 180px;
  }
}
.deal-content-4 h2 {
  font-size: 50px;
  font-weight: 500;
  color: #010225;
  margin: -25px 0 0px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content-4 h2 {
    font-size: 42px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-4 h2 {
    font-size: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-4 h2 {
    font-size: 37px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-4 h2 {
    font-size: 25px;
    margin: -15px 0 0px;
  }
}
.deal-content-4 p {
  font-size: 16px;
  color: #010225;
  margin: 16px 0 23px;
  line-height: 26px;
  width: 67%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-4 p {
    width: 78%;
    margin: 10px 0 13px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-4 p {
    width: 73%;
    margin: 10px 0 13px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-4 p {
    width: 100%;
    margin: 6px 0 13px;
  }
}
.deal-content-4 .deal-btn4 a {
  display: inline-block;
  line-height: 1;
  color: #ff5151;
  font-size: 16px;
  border: 1px solid #ff5151;
  border-radius: 50px;
  padding: 15px 30px;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .deal-content-4 .deal-btn4 a {
    padding: 10px 30px 12px;
  }
}
.deal-content-4 .deal-btn4 a:hover {
  color: #fff;
}

.deal-bg {
  padding: 150px 0;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-bg {
    padding: 72px 0;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-bg {
    padding: 60px 0;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-bg {
    padding: 50px 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-bg {
    padding: 25px 0;
    background-position: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .deal-bg {
    padding: 20px 0;
    background-position: 2%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .deal-bg {
    background-position: 94%;
  }
}

.deal-content-5 {
  margin-left: 370px;
  margin-top: -14px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .deal-content-5 {
    margin-left: 65px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-content-5 {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-content-5 {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-content-5 {
    margin-left: 0px;
    text-align: center;
  }
  .deal-content-5 .timer-style-3 div {
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .deal-content-5 {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.deal-5-img {
  margin-left: 86px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .deal-5-img {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .deal-5-img {
    margin-left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .deal-5-img {
    margin-left: 40px;
    margin-top: 40px;
    margin-right: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .deal-5-img {
    margin-left: 15px;
    margin-top: 30px;
    margin-right: 15px;
  }
}
.deal-5-img img {
  width: 100%;
}

/*-------- 13. Feature style ---------*/
.feature-border {
  border: 1px solid #f1f1f1;
  padding: 63px 0 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-border {
    padding: 43px 20px 10px 20px;
  }
  .feature-border.feature-border-about {
    padding: 43px 20px 10px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .feature-border {
    padding: 43px 20px 10px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .feature-wrap {
    text-align: center;
  }
}
.feature-wrap h5 {
  font-size: 16px;
  margin: 18px 0 6px;
}
.feature-wrap p {
  margin: 0;
}
.feature-wrap:hover img {
  animation: 500ms ease-in-out 0s normal none 1 running flip;
}
.feature-wrap.feature-mrg-1 {
  margin-left: 148px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .feature-wrap.feature-mrg-1 {
    margin-left: 44px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .feature-wrap.feature-mrg-1 {
    margin-left: 44px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap.feature-mrg-1 {
    margin-left: 35px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap.feature-mrg-1 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .feature-wrap.feature-mrg-1 {
    margin-left: 0px;
  }
}
.feature-wrap.feature-mrg-2 {
  margin-left: 135px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .feature-wrap.feature-mrg-2 {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .feature-wrap.feature-mrg-2 {
    margin-left: 60px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap.feature-mrg-2 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap.feature-mrg-2 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .feature-wrap.feature-mrg-2 {
    margin-left: 0px;
  }
}
.feature-wrap.feature-mrg-3 {
  margin-left: 121px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .feature-wrap.feature-mrg-3 {
    margin-left: 85px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .feature-wrap.feature-mrg-3 {
    margin-left: 85px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap.feature-mrg-3 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap.feature-mrg-3 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .feature-wrap.feature-mrg-3 {
    margin-left: 0px;
  }
}
.feature-wrap.feature-mrg-4 {
  margin-left: 110px;
}
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .feature-wrap.feature-mrg-4 {
    margin-left: 95px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-wrap.feature-mrg-4 {
    margin-left: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-wrap.feature-mrg-4 {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .feature-wrap.feature-mrg-4 {
    margin-left: 0px;
  }
}

/*------- 14. Brand logo style  -------*/
.brand-logo-active.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}
.brand-logo-active .single-brand-logo {
  text-align: center;
}
.brand-logo-active .single-brand-logo img {
  filter: grayscale(100%);
  transition: all 0.5s ease 0s;
}
.brand-logo-active .single-brand-logo:hover img {
  filter: grayscale(0%);
}

.brand-logo-border {
  border: 1px solid #f1f1f1;
  padding: 60px 50px 60px 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .brand-logo-border {
    padding: 60px 20px 60px 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .brand-logo-border {
    padding: 60px 20px 60px 50px;
  }
}
@media only screen and (max-width: 767px) {
  .brand-logo-border {
    padding: 40px 0px 40px 0px;
  }
}

.brand-logo-active-2.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}
.brand-logo-active-2 .single-brand-logo {
  height: 48px;
  display: flex;
  align-items: center;
}
.brand-logo-active-2 .single-brand-logo img {
  opacity: 0.2;
}
.brand-logo-active-2 .single-brand-logo:hover img {
  opacity: 1;
}

.brand-logo-padding {
  padding: 75px 70px;
}
@media only screen and (max-width: 767px) {
  .brand-logo-padding {
    padding: 40px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .brand-logo-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 15. Blog style ---------*/
@media only screen and (max-width: 767px) {
  .blog-area.pb-50 {
    padding-bottom: 10px;
  }
}
.blog-wrap {
  position: relative;
}
.blog-wrap .blog-img {
  position: relative;
  overflow: hidden;
}
.blog-wrap .blog-img a img {
  width: 100%;
  transition: all 0.5s ease 0s;
  transform: scale(1);
}
@media only screen and (max-width: 767px) {
  .blog-wrap .blog-img.mb-20 {
    margin-bottom: 10px;
  }
  .blog-wrap .blog-img.mb-25 {
    margin-bottom: 15px;
  }
}
.blog-wrap .blog-content {
  margin: 0 10px 0 67px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content {
    margin: 0 10px 0 58px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content {
    margin: 0 10px 0 55px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-content {
    margin: 0 10px 0 37px;
  }
}
.blog-wrap .blog-content h3 {
  font-size: 18px;
  color: #373737;
  line-height: 30px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 500;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content h3 {
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content h3 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    font-size: 15px;
    line-height: 28px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-content h3 {
    font-size: 15px;
    line-height: 25px;
  }
}
.blog-wrap .blog-content-2 h3 {
  font-size: 28px;
  font-weight: bold;
  color: #333333;
  margin: 0;
}
.blog-wrap .blog-content-2 h3 a {
  color: #333;
}
.blog-wrap .blog-content-2 h3 a:hover {
  color: #ff5151;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content-2 h3 {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-wrap .blog-content-2 h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-2 h3 {
    font-size: 20px;
  }
}
.blog-wrap .blog-content-2 p {
  font-size: 16px;
  color: #333333;
  line-height: 25px;
  margin: 10px 0 20px;
}
@media only screen and (max-width: 767px) {
  .blog-wrap .blog-content-2 p {
    margin: 10px 0 12px;
  }
}
.blog-wrap .blog-content-2 .blog-meta {
  display: flex;
}
.blog-wrap .blog-content-2 .blog-meta .blog-author {
  margin-right: 52px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content-2 .blog-meta .blog-author {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-content-2 .blog-meta .blog-author {
    margin-right: 20px;
  }
}
.blog-wrap .blog-content-2 .blog-meta .blog-author a {
  font-size: 16px;
  color: #333;
  line-height: 1;
  display: inline-block;
  position: relative;
  padding-left: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content-2 .blog-meta .blog-author a {
    padding-left: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-content-2 .blog-meta .blog-author a {
    padding-left: 30px;
  }
}
.blog-wrap .blog-content-2 .blog-meta .blog-author a:hover {
  color: #ff5151;
}
.blog-wrap .blog-content-2 .blog-meta .blog-author a:before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  background-color: #ff5151;
  width: 40px;
  height: 2px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-wrap .blog-content-2 .blog-meta .blog-author a:before {
    width: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-content-2 .blog-meta .blog-author a:before {
    width: 20px;
  }
}
.blog-wrap .blog-content-2 .blog-meta .blog-like a {
  color: #333333;
  font-weight: 300;
}
.blog-wrap .blog-content-2 .blog-meta .blog-like a i {
  font-size: 20px;
  color: #ff5151;
  position: relative;
  top: 2px;
}
.blog-wrap .blog-content-2 .blog-meta .blog-like a:hover {
  color: #ff5151;
}
.blog-wrap .blog-border {
  position: relative;
}
.blog-wrap .blog-border::before {
  position: absolute;
  content: "";
  left: -38px;
  top: -48px;
  height: 60px;
  width: 8px;
  background-color: #ff5151;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog-wrap .blog-border::before {
    left: -23px;
  }
}
.blog-wrap:hover .blog-img a img {
  transform: scale(1.1);
}
@media only screen and (max-width: 767px) {
  .blog-wrap.mb-45 {
    margin-bottom: 30px;
  }
}

.grid-sizer {
  width: 25%;
}

.pagination-style ul li {
  display: inline-block;
}
.pagination-style ul li a {
  font-size: 18px;
  color: #706c6c;
  width: 35px;
  height: 35px;
  line-height: 37px;
  border-radius: 100%;
  background-color: transparent;
  text-align: center;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .pagination-style ul li a {
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 15px;
  }
}
.pagination-style ul li a.active, .pagination-style ul li a:hover {
  color: #fff;
  background-color: #ff5151;
}
.pagination-style ul li a.next {
  width: 48px;
  color: #fff;
  background-color: #ff5151;
  height: 35px;
  border-radius: 0 50px 50px 0px;
  margin: 0 0px 0 20px;
}
@media only screen and (max-width: 767px) {
  .pagination-style ul li a.next {
    width: 35px;
    margin: 0 0px 0 5px;
    line-height: 35px;
  }
}
.pagination-style ul li a.next:hover {
  background-color: #333;
}
.pagination-style ul li a.prev {
  width: 48px;
  color: #fff;
  background-color: #ff5151;
  height: 35px;
  border-radius: 50px 0px 0px 50px;
  margin: 0 20px 0 0;
}
@media only screen and (max-width: 767px) {
  .pagination-style ul li a.prev {
    width: 35px;
    margin: 0 5px 0 0;
    line-height: 35px;
  }
}
.pagination-style ul li a.prev:hover {
  background-color: #333;
}
@media only screen and (max-width: 767px) {
  .pagination-style.mt-40 {
    margin-top: 0;
  }
}

/*-------- 16. Breadcrumb style ---------*/
.breadcrumb-area {
  height: 420px;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area {
    height: 300px;
    background-position: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumb-area {
    height: 280px;
    background-position: 100%;
  }
}

.breadcrumb-content {
  margin: 73px 0 0;
}
.breadcrumb-content h2 {
  font-size: 34px;
  text-transform: capitalize;
  font-weight: bold;
  margin: 0 0 10px;
}
@media only screen and (max-width: 767px) {
  .breadcrumb-content h2 {
    font-size: 23px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-content h2 {
    font-size: 28px;
  }
}
.breadcrumb-content ul li {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  margin-right: 18px;
  text-transform: capitalize;
  position: relative;
  color: #ff5151;
}
.breadcrumb-content ul li::before {
  position: absolute;
  width: 18px;
  height: 1px;
  background-color: #1f2226;
  content: "";
  right: -21px;
  top: 12px;
  z-index: 9;
  transform: rotate(115deg);
}
.breadcrumb-content ul li:last-child::before {
  display: none;
}
.breadcrumb-content ul li:last-child {
  margin-right: 0px;
}
.breadcrumb-content ul li a {
  font-weight: 500;
  color: #373737;
}
.breadcrumb-content ul li a:hover {
  color: #ff5151;
}

/*-------- 17. Product details style ---------*/
.product-details-img-left {
  margin-right: -30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img-left {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-img-left {
    margin-right: 0px;
  }
}
.product-details-img-left img {
  width: 100%;
}

.pro-details-content-modify {
  margin-left: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pro-details-content-modify {
    margin-left: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pro-details-content-modify {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .pro-details-content-modify {
    margin-left: 0px;
    margin-top: 20px;
  }
}

.dec-review-topbar {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #f1f1f1;
  border-top: 1px solid #f1f1f1;
  padding: 11px 0 14px;
}
.dec-review-topbar a {
  font-size: 14px;
  color: #bababa;
  font-weight: 500;
  margin: 0 49px;
}
@media only screen and (max-width: 767px) {
  .dec-review-topbar a {
    margin: 0 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .dec-review-topbar a {
    margin: 0 30px;
  }
}
.dec-review-topbar a.active {
  color: #333333;
}

.dec-review-bottom .description-wrap p {
  line-height: 26px;
  font-size: 16px;
  margin: 0;
  color: #666666;
}
.dec-review-bottom .specification-wrap table {
  width: 100%;
}
.dec-review-bottom .specification-wrap table tbody {
  border: 1px solid #f0f0f0;
  width: 100%;
}
.dec-review-bottom .specification-wrap table tbody tr {
  border-bottom: 1px solid #f0f0f0;
}
.dec-review-bottom .specification-wrap table tbody tr td {
  border-right: 1px solid #f0f0f0;
  font-size: 14px;
  color: #333333;
  padding: 17px 70px;
}
@media only screen and (max-width: 767px) {
  .dec-review-bottom .specification-wrap table tbody tr td {
    padding: 17px 15px;
  }
}
.dec-review-bottom .specification-wrap table tbody tr td.width1 {
  width: 300px;
}
@media only screen and (max-width: 767px) {
  .dec-review-bottom .specification-wrap table tbody tr td.width1 {
    width: 150px;
  }
}
.dec-review-bottom .dec-review-wrap .dec-review-img-wrap {
  display: flex;
  align-items: center;
}
.dec-review-bottom .dec-review-wrap .dec-review-img-wrap .dec-review-img {
  flex: 0 0 70px;
  margin-right: 30px;
}
.dec-review-bottom .dec-review-wrap .dec-review-img-wrap .dec-review-img img {
  width: 100%;
}
.dec-review-bottom .dec-review-wrap .dec-review-img-wrap .dec-client-name h4 {
  margin: 0 0 2px;
  font-size: 18px;
  color: #333333;
}
.dec-review-bottom .dec-review-wrap .dec-review-img-wrap .dec-client-name .dec-client-rating i {
  font-size: 16px;
  color: #ff5151;
  opacity: 0.4;
  margin: 0 0px 0 0;
}
.dec-review-bottom .dec-review-wrap .dec-review-content {
  margin-left: 52px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dec-review-bottom .dec-review-wrap .dec-review-content {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .dec-review-bottom .dec-review-wrap .dec-review-content {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.dec-review-bottom .dec-review-wrap .dec-review-content p {
  color: #666666;
  line-height: 26px;
  margin: 0;
}
.dec-review-bottom .dec-review-wrap .dec-review-content .review-content-bottom {
  display: flex;
  justify-content: space-between;
  margin: 7px 0 0;
}
.dec-review-bottom .dec-review-wrap .dec-review-content .review-content-bottom .review-like span {
  font-size: 13px;
  font-weight: 300;
  color: #ff5151;
  display: block;
  line-height: 1;
}
.dec-review-bottom .dec-review-wrap .dec-review-content .review-content-bottom .review-like span i {
  font-size: 16px;
  margin: 0 7px 0 0;
  position: relative;
  top: 2px;
}
.dec-review-bottom .dec-review-wrap .dec-review-content .review-content-bottom .review-date span {
  font-size: 13px;
  font-weight: 300;
  color: #ff5151;
  display: block;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .dec-review-bottom .dec-review-wrap.mb-50 {
    margin-bottom: 30px;
  }
}

.single-pro-details2-wrap {
  overflow: hidden;
}
.single-pro-details2-wrap .single-pro-details2-content {
  padding: 20px 118px 20px 100px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pro-details2-wrap .single-pro-details2-content {
    padding: 20px 60px 20px 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-pro-details2-wrap .single-pro-details2-content {
    padding: 20px 30px 20px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .single-pro-details2-wrap .single-pro-details2-content {
    padding: 0px 0px 30px 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-pro-details2-wrap .single-pro-details2-content {
    padding: 0px 15px 0px 15px;
  }
}
.single-pro-details2-wrap .single-pro-details2-content h3 {
  font-weight: bold;
  font-size: 28px;
  color: #333333;
  line-height: 28px;
  margin: 0 0 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pro-details2-wrap .single-pro-details2-content h3 {
    font-size: 26px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-pro-details2-wrap .single-pro-details2-content h3 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .single-pro-details2-wrap .single-pro-details2-content h3 {
    font-size: 21px;
    margin: 0 0 10px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single-pro-details2-wrap .single-pro-details2-content h3 {
    font-size: 16px;
    line-height: 24px;
  }
}
.single-pro-details2-wrap .single-pro-details2-content p {
  font-size: 16px;
  color: #333333;
  line-height: 25px;
  margin: 0 0 0px;
}
.single-pro-details2-wrap .single-pro-details2-img {
  overflow: hidden;
  transition: all 0.5s ease 0s;
}
.single-pro-details2-wrap .single-pro-details2-img img {
  width: 100%;
  transform: scale(1);
  transition: all 0.5s ease 0s;
}
.single-pro-details2-wrap:hover .single-pro-details2-img img {
  transform: scale(1.2);
}

.product-details-img {
  position: relative;
}
.product-details-img .zoompro-span {
  position: relative;
}
.product-details-img .zoompro-span .zoompro {
  width: 100%;
}
.product-details-img .zoompro-span > span {
  background-color: #ff5151;
  color: #fff;
  left: 20px;
  line-height: 1;
  padding: 4px 10px 5px;
  position: absolute;
  top: 20px;
  z-index: 99;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
}
.product-details-img .product-dec-slider {
  padding: 0 50px 0 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider {
    padding: 0 0px 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider {
    padding: 0 30px 0 45px;
    margin-bottom: 20px;
  }
}
.product-details-img .product-dec-slider .product-dec-icon {
  color: #1f2226;
  display: inline-block;
  font-size: 13px;
  left: 30px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 99;
  width: 30px;
  height: 30px;
  line-height: 28px;
  border-radius: 100%;
  border: 1px solid #e1e2e2;
  text-align: center;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider .product-dec-icon {
    left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider .product-dec-icon {
    left: 0px;
  }
}
.product-details-img .product-dec-slider .product-dec-icon:hover {
  background-color: #ff5151;
  border: 1px solid #ff5151;
  color: #ffffff;
}
.product-details-img .product-dec-slider .product-dec-icon.product-dec-next {
  left: auto;
  right: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider .product-dec-icon.product-dec-next {
    right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider .product-dec-icon.product-dec-next {
    right: 0px;
  }
}
.product-details-img .product-dec-slider-2 {
  display: inline-block;
  padding: 0;
  width: 20%;
  margin: 12px 0 0px;
  float: left;
}
@media only screen and (max-width: 767px) {
  .product-details-img .product-dec-slider-2 {
    width: 30%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-img .product-dec-slider-2 {
    width: 20%;
  }
}
.product-details-img .product-dec-slider-2 .slick-slide {
  margin-bottom: 3px;
  margin-top: 3px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .product-details-img .product-dec-slider-2 .slick-slide {
    margin-bottom: 5px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-details-img .product-dec-slider-2 .slick-slide {
    margin-bottom: 10px;
  }
}
.product-details-img .product-dec-slider-2 .slick-slide img {
  width: 100%;
}
.product-details-img .product-dec-slider-2 .product-dec-icon {
  cursor: pointer;
  display: inline-block;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  z-index: 99;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
  border: 1px solid #e1e2e2;
  text-align: center;
  font-size: 13px;
  color: #1f2226;
}
.product-details-img .product-dec-slider-2 .product-dec-icon:hover {
  background-color: #ff5151;
  border: 1px solid #ff5151;
  color: #ffffff;
}
.product-details-img .product-dec-slider-2 .product-dec-icon.product-dec-prev {
  top: -13px;
}
.product-details-img .product-dec-slider-2 .product-dec-icon.product-dec-next {
  bottom: -7px;
}
.product-details-img .product-zoom-right {
  display: inline-block;
  position: relative;
  width: 80%;
  float: left;
}
@media only screen and (max-width: 767px) {
  .product-details-img .product-zoom-right {
    width: 70%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .product-details-img .product-zoom-right {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .sidebar-active .product-details-content {
    margin-top: 0;
  }
}
.sidebar-active.col-lg-6 {
  padding-left: 15px;
  padding-right: 15px;
}
.sidebar-active.col-lg-6.is-affixed {
  padding-left: 0px;
  padding-right: 0px;
}
.sidebar-active.col-lg-6.is-affixed .inner-wrapper-sticky {
  padding-left: 15px;
  padding-right: 15px;
}

.box-slider-active.owl-carousel .owl-nav button {
  color: #1f2226;
  display: inline-block;
  font-size: 18px;
  left: 10px;
  opacity: 1;
  visibility: visible;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease 0s;
  border: 1px solid #e1e2e2;
  background-color: #ffffff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-slider-active.owl-carousel .owl-nav button {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .box-slider-active.owl-carousel .owl-nav button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .box-slider-active.owl-carousel .owl-nav button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
  }
}
.box-slider-active.owl-carousel .owl-nav button:hover {
  background-color: #ff5151;
  color: #ffffff;
  border: 1px solid #ff5151;
}
.box-slider-active.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 10px;
}

/*-------- 18. Blog details style ---------*/
.blog-details-wrap .blog-details-img img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-img.mb-40 {
    margin-bottom: 20px;
  }
}
.blog-details-wrap .blog-details-author h4 {
  font-size: 13px;
  color: #333333;
  font-style: italic;
  letter-spacing: 0.2px;
  line-height: 24px;
  margin: 0;
}
.blog-details-wrap .blog-details-author h4 span {
  display: block;
  color: #999999;
}
.blog-details-wrap .blog-details-author .author-social {
  border-top: 2px solid #eeeeee;
  padding-top: 30px;
  margin-top: 27px;
}
.blog-details-wrap .blog-details-author .author-social ul li {
  display: inline-block;
  margin-right: 19px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrap .blog-details-author .author-social ul li {
    margin-right: 10px;
  }
}
.blog-details-wrap .blog-details-author .author-social ul li:last-child {
  margin-right: 0px;
}
.blog-details-wrap .blog-details-author .author-social ul li a {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 100%;
  color: #cccccc;
  font-size: 12px;
  border: 1px solid #cccccc;
  text-align: center;
}
.blog-details-wrap .blog-details-author .author-social ul li a:hover {
  color: #ff5151;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-author {
    margin-bottom: 30px;
  }
}
.blog-details-wrap .blog-details-content p {
  color: #333333;
  font-size: 16px;
  line-height: 34px;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-content p.mb-80 {
    margin-bottom: 30px;
  }
  .blog-details-wrap .blog-details-content p.mb-35 {
    margin-bottom: 20px;
  }
}
.blog-details-wrap .blog-details-content blockquote {
  text-align: center;
  margin: 77px 0 93px;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-content blockquote {
    margin: 30px 0 30px;
  }
}
.blog-details-wrap .blog-details-content blockquote h3 {
  font-size: 28px;
  color: #191a1d;
  margin: 28px 0 0;
  letter-spacing: 0.2px;
  line-height: 38px;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-content blockquote h3 {
    font-size: 20px;
    line-height: 30px;
  }
}
.blog-details-wrap .blog-details-content .place h3 {
  font-size: 24px;
  color: #333333;
  font-weight: bold;
  margin: 0 0 23px;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-details-content .place h3 {
    margin: 0 0 10px;
  }
}
.blog-details-wrap .blog-tag {
  margin: 36px 0 30px;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-tag {
    margin: 20px 0 30px;
  }
}
.blog-details-wrap .blog-tag ul li {
  display: inline-block;
  margin-right: 8px;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-tag ul li {
    margin-bottom: 10px;
  }
}
.blog-details-wrap .blog-tag ul li:last-child {
  margin-right: 0px;
}
.blog-details-wrap .blog-tag ul li a {
  display: inline-block;
  background-color: #f0f0f0;
  border-radius: 50px;
  color: #74797e;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 1;
  padding: 5px 11px;
}
.blog-details-wrap .blog-tag ul li a:hover {
  background-color: #ff5151;
  color: #fff;
}
.blog-details-wrap .blog-share {
  display: flex;
}
.blog-details-wrap .blog-share span {
  color: #333333;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 4px 57px 0 0;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-share span {
    margin: 4px 5px 0 0;
  }
}
.blog-details-wrap .blog-share ul li {
  display: inline-block;
  margin-right: 27px;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-share ul li {
    margin-right: 5px;
  }
}
.blog-details-wrap .blog-share ul li:last-child {
  margin-right: 0;
}
.blog-details-wrap .blog-share ul li a {
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  padding: 9px 10px 10px;
  display: inline-block;
  line-height: 1;
  border: 1px solid transparent;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-share ul li a {
    padding: 9px 8px 10px;
  }
}
.blog-details-wrap .blog-share ul li a i {
  margin-right: 18px;
  position: relative;
  top: 1px;
}
.blog-details-wrap .blog-share ul li a.facebook {
  background-color: #4c68b3;
}
.blog-details-wrap .blog-share ul li a.facebook:hover {
  border: 1px solid #4c68b3;
  background-color: transparent;
  color: #4c68b3;
}
.blog-details-wrap .blog-share ul li a.twitter {
  background-color: #00abed;
}
.blog-details-wrap .blog-share ul li a.twitter:hover {
  border: 1px solid #00abed;
  background-color: transparent;
  color: #00abed;
}
.blog-details-wrap .blog-share ul li a.google {
  background-color: #d73d32;
}
.blog-details-wrap .blog-share ul li a.google:hover {
  border: 1px solid #d73d32;
  background-color: transparent;
  color: #d73d32;
}
.blog-details-wrap .blog-comment-area {
  margin-top: 45px;
}
.blog-details-wrap .blog-comment-area h4 {
  font-size: 20px;
  color: #757a7f;
  letter-spacing: 0.4px;
  margin: 0;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  padding-bottom: 27px;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 30px;
  padding-bottom: 27px;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment:last-child {
  margin-bottom: 0px;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-img {
  flex: 0 0 50px;
  margin: 0 50px 0 0;
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-img {
    margin: 0 15px 0 0;
  }
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-img img {
  width: 100%;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-content .blog-comment-top {
  display: flex;
  margin: 0 0 14px;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-content .blog-comment-top h5 {
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 0;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-content .blog-comment-top span {
  font-size: 14px;
  color: #aaaaaa;
  letter-spacing: 0.4px;
  display: block;
  line-height: 1;
  margin-left: 21px;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-content .blog-comment-top span img {
  margin-right: 5px;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-content p {
  color: #999999;
  letter-spacing: 0.2px;
  margin: 0;
}
.blog-details-wrap .blog-comment-area .blog-comment-wrap .single-blog-comment .blog-comment-reply textarea {
  background-color: transparent;
  border: none;
  height: 50px;
  resize: none;
  width: 400px;
  padding: 0;
  font-size: 13px;
  color: #666;
  font-style: italic;
  letter-spacing: 0.2px;
  padding: 12px 0 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrap .pl-215 {
    padding-left: 175px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrap .pl-215 {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .pl-215 {
    padding-left: 15px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-wrap .pr-445 {
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-wrap .pr-445 {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .blog-details-wrap .pr-445 {
    padding-right: 15px;
  }
}

/*-------- 19. Sidebar style ---------*/
.sidebar-wrapper {
  border: 1px solid #efefef;
  padding: 17px 18px 29px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar-wrapper {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .sidebar-wrapper {
    margin-top: 40px;
  }
}

.sidebar-widget h4.sidebar-title {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  line-height: 1;
  color: #222;
}
.sidebar-widget .sidebar-search .sidebar-search-form {
  position: relative;
}
.sidebar-widget .sidebar-search .sidebar-search-form input {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  height: 43px;
  padding: 2px 55px 2px 18px;
  color: #444;
}
.sidebar-widget .sidebar-search .sidebar-search-form button {
  background: transparent none repeat scroll 0 0;
  border-color: #a1a5aa;
  border-image: none;
  border-style: none none none solid;
  border-width: medium medium medium 1px;
  padding: 1px 15px 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #000;
  transition: all 0.3s ease 0s;
  font-size: 17px;
}
.sidebar-widget .sidebar-search .sidebar-search-form button:hover {
  color: #ff5151;
}
.sidebar-widget .shop-catigory > ul > li {
  padding: 0 0 13px;
  display: block;
}
.sidebar-widget .shop-catigory > ul > li:last-child {
  padding: 0 0 0;
}
.sidebar-widget .shop-catigory > ul > li > a {
  font-size: 15px;
  display: block;
}
.sidebar-widget .shop-catigory > ul > li > a i {
  float: right;
  font-size: 12px;
}
.sidebar-widget .shop-catigory > ul > li ul li {
  padding: 7px 0 0 15px;
}
.sidebar-widget .shop-catigory > ul > li ul li a {
  display: block;
}
.sidebar-widget .price-filter span {
  display: block;
  margin: 0 0 21px;
}
.sidebar-widget .price-filter .price-slider-amount input {
  background: transparent;
  padding: 0;
  border: none;
  font-size: 15px;
  font-weight: 500;
}
.sidebar-widget .price-filter #slider-range {
  background: #dbdbdb none repeat scroll 0 0;
  border: medium none;
  border-radius: 0px;
  height: 5px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-top: 5px;
}
.sidebar-widget .price-filter #slider-range span {
  transition: all 0s ease 0s;
}
.sidebar-widget .price-filter #slider-range .ui-slider-range {
  position: absolute;
  display: block;
  border: 0;
  background: #ff5151 none repeat scroll 0 0;
  border: none;
  transition: all 0s ease 0s;
}
.sidebar-widget .price-filter #slider-range .ui-slider-handle {
  background: #fff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  height: 15px;
  margin-left: 0;
  width: 15px;
  border: 4px solid #ff5151;
}
.sidebar-widget .price-filter .price-slider-amount .label-input input {
  color: #333;
  height: 37px;
}
.sidebar-widget .price-filter .price-slider-amount button {
  background-color: #333;
  border: medium none;
  color: #fff;
  line-height: 1;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}
.sidebar-widget .sidebar-widget-list ul li {
  align-items: center;
  position: relative;
  padding: 0px 0 18px;
}
.sidebar-widget .sidebar-widget-list ul li:last-child {
  padding: 0px 0 0px;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left {
  position: relative;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  width: auto;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark {
  background-color: #ff5151;
  border: 2px solid #ff5151;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left input:checked ~ .checkmark::after {
  display: block;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid #888888;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}
.sidebar-widget .sidebar-widget-list ul li .sidebar-widget-list-left .checkmark::after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.sidebar-widget .sidebar-widget-list ul li a {
  margin-left: 30px;
  display: block;
}
.sidebar-widget .sidebar-widget-list ul li a span {
  background-color: #eeeeee;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  border-radius: 50px;
  float: right;
  transition: all 0.3s ease 0s;
  font-size: 13px;
}
.sidebar-widget .sidebar-widget-list ul li a:hover span {
  color: #fff;
  background-color: #ff5151;
}
.sidebar-widget .sidebar-widget-tag ul li {
  display: inline-block;
  margin: 0 10px 15px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-tag ul li {
    margin: 0 3px 15px 0;
  }
}
.sidebar-widget .sidebar-widget-tag ul li a {
  display: inline-block;
  padding: 10px 17px 10px;
  line-height: 1;
  border: 1px solid #ebebeb;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .sidebar-widget-tag ul li a {
    padding: 9px 7px 10px;
  }
}
.sidebar-widget .sidebar-widget-tag ul li a:hover {
  background-color: #ff5151;
  color: #fff;
}
.sidebar-widget .single-sidebar-blog {
  border-bottom: 1px solid #dee0e4;
  display: flex;
  margin: 0 0 20px;
  padding: 0 0 20px;
  align-items: center;
}
.sidebar-widget .single-sidebar-blog:last-child {
  border-bottom: none;
  margin: 0 0 0px;
  padding: 0 0 0px;
}
.sidebar-widget .single-sidebar-blog .sidebar-blog-img {
  flex: 0 0 70px;
  margin: 0 20px 0 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .single-sidebar-blog .sidebar-blog-img {
    margin: 0 8px 0 0;
  }
}
.sidebar-widget .single-sidebar-blog .sidebar-blog-img img {
  width: 100%;
}
.sidebar-widget .single-sidebar-blog .sidebar-blog-content span {
  display: block;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  margin: 0px;
  text-transform: capitalize;
  color: #666;
}
.sidebar-widget .single-sidebar-blog .sidebar-blog-content h4 {
  font-size: 14px;
  margin: 0px 0 5px;
  font-weight: 500;
  text-transform: capitalize;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar-widget .single-sidebar-blog .sidebar-blog-content h4 {
    font-size: 14px;
  }
}
.sidebar-widget .sidebar-widget-archives ul li {
  margin-bottom: 6px;
}
.sidebar-widget .sidebar-widget-archives ul li:last-child {
  margin-bottom: 0px;
}
.sidebar-widget .sidebar-widget-video {
  position: relative;
}
.sidebar-widget .sidebar-widget-video a {
  display: block;
}
.sidebar-widget .sidebar-widget-video a img {
  width: 100%;
}
.sidebar-widget .sidebar-widget-video .blog-video-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  text-align: center;
}
.sidebar-widget .sidebar-widget-video .blog-video-icon a {
  font-size: 25px;
  color: #fff;
  background-color: #ff5151;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  display: inline-block;
}
.sidebar-widget .sidebar-widget-video .blog-video-icon a:hover {
  color: #ff5151;
  background-color: #fff;
}

.shop-sidebar-border {
  border-top: 1px solid #ebebeb;
}

/*-------- 20. Cart page ---------*/
@media only screen and (max-width: 767px) {
  .cart-main-area.pt-85 {
    padding-top: 45px;
  }
}

h3.cart-page-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 15px;
}

.cart-table-content table {
  border: 1px solid #ebebeb;
}
@media only screen and (max-width: 767px) {
  .cart-table-content table {
    width: 100%;
  }
}
.cart-table-content table thead > tr {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
}
@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr {
    width: 100%;
    display: block;
    padding: 10px 0;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table thead > tr {
    width: inherit;
    display: inline-block;
    padding: 10px 0;
  }
}
.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 14px;
  font-weight: 500;
  padding: 21px 45px 22px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 21px 35px 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table thead > tr th {
    padding: 21px 15px 22px;
  }
}
@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0px 20px 5px;
    width: 100%;
    display: block;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    width: inherit;
    display: inline-block;
  }
}
.cart-table-content table tbody > tr {
  border-bottom: 1px solid #ebebeb;
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table tbody > tr {
    width: 50%;
    float: left;
  }
}
.cart-table-content table tbody > tr td.product-thumbnail {
  width: 150px;
}
@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-thumbnail {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}
.cart-table-content table tbody > tr td.product-name {
  width: 435px;
}
@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-name {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}
.cart-table-content table tbody > tr td.product-name a {
  font-size: 15px;
}
.cart-table-content table tbody > tr td.product-name a:hover {
  color: #ff5151;
}
.cart-table-content table tbody > tr td.product-price-cart {
  width: 435px;
}
@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-price-cart {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}
.cart-table-content table tbody > tr td.product-price-cart span {
  color: #333;
  font-weight: 500;
}
.cart-table-content table tbody > tr td.product-quantity {
  width: 435px;
}
@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-quantity {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}
.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus {
  display: inline-block;
  height: 40px;
  padding: 0;
  position: relative;
  width: 110px;
}
.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 16px;
  margin: 0;
  position: absolute;
  transition: all 0.3s ease 0s;
  width: 20px;
  text-align: center;
}
.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .dec.qtybutton {
  border-right: 1px solid #e5e5e5;
  height: 40px;
  left: 0;
  padding-top: 8px;
  top: 0;
}
.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus .inc.qtybutton {
  border-left: 1px solid #e5e5e5;
  height: 40px;
  padding-top: 9px;
  right: 0;
  top: 0;
}
.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus input.cart-plus-minus-box {
  color: #333;
  float: left;
  font-size: 14px;
  height: 40px;
  margin: 0;
  width: 110px;
  background: transparent none repeat scroll 0 0;
  border: 1px solid #e1e1e1;
  padding: 0;
  text-align: center;
}
.cart-table-content table tbody > tr td.product-remove {
  width: 100px;
}
@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td.product-remove {
    width: 100%;
    display: block;
    overflow: hidden;
  }
}
.cart-table-content table tbody > tr td.product-remove a {
  font-size: 17px;
  margin: 0 6px;
}
.cart-table-content table tbody > tr td.product-remove a:hover {
  color: #ff5151;
}
.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #ff5151;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  padding: 7px 12px;
  text-transform: uppercase;
}
.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #333;
}
.cart-table-content table tbody > tr td {
  font-size: 15px;
  padding: 30px 0;
  text-align: center;
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td {
    padding: 30px 0 30px 10px;
  }
}
@media only screen and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    padding: 10px 0 10px 0px;
    width: 100%;
    display: block;
    overflow: hidden;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cart-table-content table tbody > tr td {
    text-align: left;
    padding: 10px 20px 10px 20px;
  }
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 0px 55px;
}
@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
    padding: 30px 0px 15px;
  }
}
.cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
  background-color: #f2f2f2;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 40px 17px;
  text-transform: uppercase;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 25px 17px;
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-shiping-update > a,
.cart-shiping-update-wrapper .cart-clear > button,
.cart-shiping-update-wrapper .cart-clear > a {
    padding: 18px 40px 17px;
    margin: 0 0 15px;
  }
}
.cart-shiping-update-wrapper .cart-shiping-update > a:hover,
.cart-shiping-update-wrapper .cart-clear > button:hover,
.cart-shiping-update-wrapper .cart-clear > a:hover {
  background-color: #ff5151;
  color: #fff;
}
.cart-shiping-update-wrapper .cart-clear > button {
  border: medium none;
  cursor: pointer;
  margin-right: 27px;
  transition: all 0.3s ease 0s;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-shiping-update-wrapper .cart-clear > button {
    margin-right: 15px;
  }
}

.cart-tax,
.discount-code-wrapper {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-tax,
.discount-code-wrapper {
    padding: 45px 18px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-tax,
.discount-code-wrapper {
    padding: 45px 20px 50px;
  }
}
@media only screen and (max-width: 767px) {
  .cart-tax,
.discount-code-wrapper {
    padding: 45px 18px 50px;
  }
}
.cart-tax .title-wrap,
.discount-code-wrapper .title-wrap {
  position: relative;
}
.cart-tax .title-wrap::before,
.discount-code-wrapper .title-wrap::before {
  background-color: #e3e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}
.cart-tax .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap h4.cart-bottom-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-right: 18px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-tax .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap h4.cart-bottom-title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .cart-tax .title-wrap h4.cart-bottom-title,
.discount-code-wrapper .title-wrap h4.cart-bottom-title {
    font-size: 17px;
  }
}
.cart-tax .title-wrap .section-bg-gray,
.discount-code-wrapper .title-wrap .section-bg-gray {
  background-color: #f8f9f9;
  position: relative;
  z-index: 99;
}
.cart-tax .tax-wrapper,
.discount-code-wrapper .tax-wrapper {
  margin-top: 22px;
}
.cart-tax .tax-wrapper p,
.discount-code-wrapper .tax-wrapper p {
  margin: 0;
}
.cart-tax .tax-wrapper .tax-select-wrapper,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper {
  margin: 5px 0 0;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select {
  margin: 0 0 26px;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select label,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select label {
  color: #333;
  font-size: 14px;
  margin: 0 0 5px;
  font-weight: 400;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select select,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #fff url("../../assets/images/icon-img/cart.png") no-repeat scroll right 18px center;
  border: 1px solid #ebebeb;
  box-shadow: none;
  color: #242424;
  font-size: 13px;
  height: 40px;
  padding: 0 50px 0 15px;
  width: 100%;
  cursor: pointer;
}
.cart-tax .tax-wrapper .tax-select-wrapper .tax-select input,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper .tax-select input {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #ebebeb;
  height: 40px;
}
.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2 {
  background-color: #333;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 40px 13px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.cart-tax .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover,
.discount-code-wrapper .tax-wrapper .tax-select-wrapper button.cart-btn-2:hover {
  background-color: #ff5151;
}
.cart-tax .discount-code,
.discount-code-wrapper .discount-code {
  margin: 21px 0 0;
}
.cart-tax .discount-code p,
.discount-code-wrapper .discount-code p {
  margin: 0 0 15px;
}
.cart-tax .discount-code form input,
.discount-code-wrapper .discount-code form input {
  background: #fff;
  border: 1px solid #ebebeb;
  height: 40px;
  margin-bottom: 30px;
  padding-left: 10px;
}
.cart-tax .discount-code form button.cart-btn-2,
.discount-code-wrapper .discount-code form button.cart-btn-2 {
  background-color: #333;
  border: medium none;
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 40px 13px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.cart-tax .discount-code form button.cart-btn-2:hover,
.discount-code-wrapper .discount-code form button.cart-btn-2:hover {
  background-color: #ff5151;
}

.grand-totall {
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 45px 30px 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .grand-totall {
    padding: 45px 18px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-totall {
    padding: 45px 20px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-totall {
    margin-top: 30px;
  }
}
.grand-totall .title-wrap {
  position: relative;
}
.grand-totall .title-wrap::before {
  background-color: #e3e1e1;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 10px;
  transition: all 0.4s ease 0s;
  width: 100%;
  z-index: 1;
}
.grand-totall .title-wrap h4.cart-bottom-title {
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-right: 18px;
}
.grand-totall .title-wrap .section-bg-gary-cart {
  background-color: #f9f9f9;
  position: relative;
  z-index: 9;
}
.grand-totall h5 {
  font-size: 14px;
  margin: 36px 0 27px;
  font-weight: 600;
}
.grand-totall h5 span {
  float: right;
  font-size: 18px;
  font-weight: 500;
}
.grand-totall .total-shipping {
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  margin: 0 0 27px;
  padding: 28px 0;
}
.grand-totall .total-shipping h5 {
  font-size: 14px;
  margin: 0;
}
.grand-totall .total-shipping ul {
  padding: 19px 0 0 0px;
}
.grand-totall .total-shipping ul li {
  color: #242424;
  list-style: outside none none;
  margin: 0 0 6px;
}
.grand-totall .total-shipping ul li:last-child {
  margin: 0 0 0px;
}
.grand-totall .total-shipping ul li input {
  background: #e9e9e9 none repeat scroll 0 0;
  border: 1px solid #d7d7d7;
  border-radius: 5px !important;
  color: #626262;
  cursor: pointer;
  height: 13px;
  margin-right: 10px;
  padding: 0;
  position: relative;
  top: 2px;
  width: 13px;
}
.grand-totall .total-shipping ul li span {
  float: right;
}
.grand-totall h4.grand-totall-title {
  color: #ff5151;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 25px;
}
.grand-totall h4.grand-totall-title span {
  float: right;
}
.grand-totall a {
  background-color: #333;
  color: #fff;
  display: block;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 18px 10px 17px;
  text-align: center;
  text-transform: uppercase;
}
.grand-totall a:hover {
  background-color: #ff5151;
}

@media only screen and (max-width: 767px) {
  .discount-code-wrapper {
    margin: 30px 0;
  }
}

/*-------- 21. Login register style ---------*/
.login-register-wrapper .login-register-tab-list {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-register-tab-list {
    margin-bottom: 20px;
  }
}
.login-register-wrapper .login-register-tab-list a {
  position: relative;
}
.login-register-wrapper .login-register-tab-list a::before {
  background-color: #454545;
  bottom: 5px;
  content: "";
  height: 18px;
  margin: 0 auto;
  position: absolute;
  right: -2px;
  transition: all 0.4s ease 0s;
  width: 1px;
}
.login-register-wrapper .login-register-tab-list a h4 {
  font-size: 25px;
  font-weight: 700;
  margin: 0 20px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}
.login-register-wrapper .login-register-tab-list a:last-child::before {
  display: none;
}
.login-register-wrapper .login-register-tab-list a.active h4 {
  color: #ff5151;
}
.login-register-wrapper .login-form-container {
  background: transparent none repeat scroll 0 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  padding: 80px;
  text-align: left;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .login-register-wrapper .login-form-container {
    padding: 80px 50px;
  }
}
@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container {
    padding: 40px 15px;
  }
}
.login-register-wrapper .login-form-container .login-register-form form input {
  background-color: transparent;
  border: 1px solid #ebebeb;
  font-size: 14px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #010225;
}
.login-register-wrapper .login-form-container .login-register-form form input[type=checkbox] {
  height: 15px;
  margin: 0;
  position: relative;
  top: 2px;
  width: 17px;
  color: #010225;
}
.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn {
  padding: 10px 0 19px;
}
.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
  font-size: 15px;
  font-weight: 400;
}
@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn label {
    font-size: 14px;
  }
}
.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
  float: right;
  font-size: 15px;
  color: #010225;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 767px) {
  .login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a {
    font-size: 14px;
  }
}
.login-register-wrapper .login-form-container .login-register-form form .button-box .login-toggle-btn a:hover {
  color: #ff5151;
}
.login-register-wrapper .login-form-container .login-register-form form .button-box button {
  background-color: #ff5151;
  color: #fff;
  border: medium none;
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  line-height: 1;
  padding: 13px 30px 13px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.login-register-wrapper .login-form-container .login-register-form form .button-box button:hover {
  background-color: #010225;
}

@media only screen and (max-width: 767px) {
  .login-register-area.pb-100 {
    padding-bottom: 60px;
  }
}

/*------- 22. Contact us style  -------*/
.contact-info-wrap h3, .get-in-touch-wrap h3 {
  text-transform: capitalize;
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 30px;
}
@media only screen and (max-width: 767px) {
  .contact-info-wrap h3, .get-in-touch-wrap h3 {
    margin: 0 0 24px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-info-wrap.mb-60, .get-in-touch-wrap.mb-60 {
    margin-bottom: 13px;
  }
}

.single-contact-info {
  background-color: #f7f7f7;
  padding: 48px 10px 49px;
}
.single-contact-info > i {
  font-size: 36px;
  color: #ff5151;
  transition: all 0.3s ease 0s;
  display: block;
}
.single-contact-info h4 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  margin: 19px 0 10px;
}
.single-contact-info p {
  letter-spacing: 0.4px;
  font-size: 15px;
  color: #333;
}
.single-contact-info ul li {
  border-bottom: 2px solid #fff;
  letter-spacing: 0.4px;
  margin-bottom: 30px;
  padding-bottom: 33px;
  font-size: 15px;
}
.single-contact-info ul li:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: none;
}
.single-contact-info ul li i {
  font-size: 18px;
  margin-right: 7px;
  color: #ff5151;
  top: 3px;
  position: relative;
  transition: all 0.3s ease 0s;
  display: inline-block;
}
.single-contact-info ul li:hover i {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}
.single-contact-info:hover > i {
  animation: 500ms ease-in-out 0s normal none 1 running tada;
}

.contact-from input, .contact-from textarea {
  background: #f7f7f7 none repeat scroll 0 0;
  border: none;
  color: #000;
  height: 60px;
  padding: 2px 20px;
  margin-bottom: 30px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .contact-from input, .contact-from textarea {
    margin-bottom: 20px;
  }
}
.contact-from textarea {
  height: 200px;
  padding: 20px 20px;
}
.contact-from button {
  border: none;
  background-color: transparent;
  text-transform: capitalize;
  background-color: #ff5151;
  color: #fff;
  padding: 12px 30px;
  font-size: 15px;
  letter-spacing: 0.2px;
  font-weight: 500;
}
.contact-from button:hover {
  background-color: #000;
}

#map {
  height: 500px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #map {
    height: 400px;
  }
}
@media only screen and (max-width: 767px) {
  #map {
    height: 300px;
  }
}

/*-------- 23. Checkout style ---------*/
.customer-zone > p {
  font-size: 14px;
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #eeeff2;
  margin: 0;
  position: relative;
  color: #373737;
}
@media only screen and (max-width: 767px) {
  .customer-zone > p {
    padding: 14px 30px 14px 40px;
  }
}
.customer-zone > p:before {
  content: "";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: LineAwesome;
  left: 15px;
  top: 15px;
  font-size: 18px;
}

.checkout-login-info {
  margin: 20px 0 0;
  display: none;
}
.checkout-login-info p {
  font-size: 14px;
  margin: 0 0 12px;
}
.checkout-login-info form {
  display: block;
  overflow: hidden;
}
.checkout-login-info form .sin-checkout-login {
  margin-bottom: 30px;
}
.checkout-login-info form .sin-checkout-login label {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0 0 5px;
}
.checkout-login-info form .sin-checkout-login label span {
  color: red;
}
.checkout-login-info form .sin-checkout-login input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
}
.checkout-login-info form .sin-checkout-login input:focus {
  border: 1px solid #343538;
}
.checkout-login-info form .button-remember-wrap {
  display: block;
  overflow: hidden;
}
.checkout-login-info form .button-remember-wrap button {
  border: 1px solid #aaa;
  font-size: 13px;
  padding: 10px 30px 9px;
  display: inline-block;
  float: left;
  margin-right: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
.checkout-login-info form .button-remember-wrap button:hover {
  background-color: #ff5151;
  color: #fff;
  border: 1px solid #ff5151;
}
.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn {
  display: flex;
  margin: 8px 0 0;
}
.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn input {
  height: 15px;
  margin: 0;
  position: relative;
  top: 5px;
  width: 17px;
}
.checkout-login-info form .button-remember-wrap .checkout-login-toggle-btn label {
  font-size: 14px;
  margin: 0 0 0 5px;
}
.checkout-login-info form .lost-password {
  margin: 20px 0 11px;
}
.checkout-login-info form .lost-password a {
  font-size: 14px;
}
.checkout-login-info .checkout-login-social span {
  font-size: 14px;
  margin: 0 0 13px;
  display: block;
}
.checkout-login-info .checkout-login-social ul li {
  display: inline-block;
  margin: 0 14px 0 0;
}
.checkout-login-info .checkout-login-social ul li:last-child {
  margin: 0 0px 0 0;
}
@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li {
    margin: 0 5px 0 0;
  }
}
.checkout-login-info .checkout-login-social ul li a {
  background-color: #f5f5f5;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  float: left;
  font-size: 13px;
  padding: 20px;
  padding: 24px 106px 25px;
  line-height: 1;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 24px 70px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .checkout-login-info .checkout-login-social ul li a {
    padding: 15px 13px 16px;
  }
}
.checkout-login-info .checkout-login-social ul li a:hover {
  background-color: #ff5151;
  color: #fff;
}

.checkout-login-info2 {
  display: none;
  margin: 20px 0 0;
}
.checkout-login-info2 ul li {
  display: inline-block;
}
.checkout-login-info2 ul li a {
  color: #8a8a8a;
  font-size: 12px;
}
.checkout-login-info2 ul li a:hover {
  color: #ff5151;
}

.checkout-login-info3 {
  display: none;
  margin-top: 20px;
}
.checkout-login-info3 form input[type=text] {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  width: 48%;
  font-size: 14px;
  padding: 0 20px;
}
.checkout-login-info3 form input[type=text]:focus {
  border: 1px solid #1f2226;
}
.checkout-login-info3 form input[type=submit] {
  background: #1f2226 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-left: 6px;
  padding: 5px 30px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  width: inherit;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-login-info3 form input[type=submit] {
    padding: 5px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .checkout-login-info3 form input[type=submit] {
    padding: 5px 16px;
  }
}
.checkout-login-info3 form input[type=submit]:hover {
  background-color: #ff5151;
}

.billing-info-wrap h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 55px;
  text-transform: uppercase;
}
@media only screen and (max-width: 767px) {
  .billing-info-wrap h3 {
    margin-bottom: 40px;
  }
}
.billing-info-wrap h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #ff5151;
  width: 50px;
  height: 2px;
}
.billing-info-wrap .billing-info label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}
.billing-info-wrap .billing-info label abbr {
  color: red;
}
.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 14px;
}
.billing-info-wrap .billing-info input:focus {
  border: 1px solid #343538;
}
.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}
.billing-info-wrap .billing-select label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}
.billing-info-wrap .billing-select label abbr {
  color: red;
}
.billing-info-wrap .billing-select select {
  border: 1px solid #e6e6e6;
  -moz-appearance: none;
  -webkit-appearance: none;
  color: #333;
  font-size: 14px;
  height: 50px;
  padding: 2px 20px;
  background: rgba(0, 0, 0, 0) url("../../assets/images/icon-img/select.png") no-repeat scroll right 18px center;
  cursor: pointer;
}
.billing-info-wrap .billing-select select option {
  background: #fff;
  border: 0px solid #626262;
  padding-left: 10px;
  color: #333;
  font-size: 12px;
}
.billing-info-wrap .checkout-account input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  float: left;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
  color: #333;
}
.billing-info-wrap .checkout-account span {
  color: #333;
  font-weight: 400;
  margin: 0 0 0 12px;
  font-size: 14px;
  text-transform: uppercase;
}
.billing-info-wrap .checkout-account-toggle label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}
.billing-info-wrap .checkout-account-toggle label abbr {
  color: red;
}
.billing-info-wrap .checkout-account-toggle input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  color: #333;
  font-size: 14px;
  padding: 2px 20px;
}
.billing-info-wrap .checkout-account-toggle input:focus {
  border: 1px solid #343538;
}
.billing-info-wrap .different-address {
  display: none;
}
.billing-info-wrap .additional-info-wrap {
  margin: 22px 0 0;
}
.billing-info-wrap .additional-info-wrap label {
  color: #010225;
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}
.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  padding: 20px;
  color: #333;
  font-size: 14px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-50 {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .billing-info-wrap.mr-50 {
    margin-right: 0px;
  }
}

.your-order-area {
  padding: 40px 50px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border-color: rgba(52, 53, 56, 0.1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}
.your-order-area h3 {
  font-size: 20px;
  font-weight: 600;
  position: relative;
  margin: 0 0 45px;
  text-transform: uppercase;
}
.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -12px;
  background-color: #ff5151;
  width: 50px;
  height: 2px;
}
.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #e8e8e8;
}
.your-order-area .your-order-wrap .your-order-info ul li {
  font-size: 14px;
  padding: 0 0 7px;
  text-transform: uppercase;
  font-weight: 500;
}
.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
  color: #ff5151;
}
.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}
.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}
.your-order-area .your-order-wrap .your-order-info.order-shipping {
  padding: 15px 0 13px;
  display: block;
  overflow: hidden;
}
.your-order-area .your-order-wrap .your-order-info.order-shipping ul li > p {
  float: right;
  font-size: 14px;
  text-align: right;
  color: #333;
  text-transform: capitalize;
}
.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}
.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 16px;
}
.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #e8e8e8;
  padding: 24px 0 23px;
}
.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}
.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}
.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}
.your-order-area .your-order-wrap .payment-method {
  margin: 17px 0 0;
}
.your-order-area .your-order-wrap .payment-method .sin-payment {
  margin-bottom: 20px;
}
.your-order-area .your-order-wrap .payment-method .sin-payment input {
  width: auto;
  display: inline-block;
  float: left;
  height: auto;
  margin-top: 5px;
}
.your-order-area .your-order-wrap .payment-method .sin-payment label {
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 0 0 21px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
@media only screen and (max-width: 767px) {
  .your-order-area .your-order-wrap .payment-method .sin-payment label {
    display: block;
  }
}
.your-order-area .your-order-wrap .payment-method .sin-payment label img {
  max-width: 140px;
  display: inline-block;
  margin: 0 5px;
}
.your-order-area .your-order-wrap .payment-method .sin-payment label a {
  text-decoration: underline;
}
.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box {
  margin: 6px 0 0;
  display: none;
}
.your-order-area .your-order-wrap .payment-method .sin-payment .payment-box p {
  font-size: 14px;
  margin: 0;
}
.your-order-area .Place-order a {
  background-color: #ff5151;
  display: block;
  cursor: pointer;
  padding: 16px 50px 17px;
  text-transform: uppercase;
  font-weight: 700;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}
.your-order-area .Place-order a:hover {
  background-color: #333;
}

@media only screen and (max-width: 767px) {
  .checkout-main-area.pb-70 {
    padding-bottom: 50px;
  }
}

/*-------- 24. Compare style ---------*/
.compare-table .table tbody tr {
  margin-bottom: 20px;
}
.compare-table .table tbody tr td {
  padding: 20px 20px;
  vertical-align: middle;
  border-color: #ccc;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td {
    padding: 20px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td {
    padding: 10px 20px;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
  }
}
.compare-table .table tbody tr td.first-column {
  min-width: 175px;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 110px;
  }
}
@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.first-column {
    min-width: 100%;
    display: block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 5px;
    margin-top: 35px;
  }
}
.compare-table .table tbody tr td.product-image-title {
  min-width: 310px;
  vertical-align: bottom;
  text-align: left;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 210px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 110px;
  }
}
@media only screen and (max-width: 767px) {
  .compare-table .table tbody tr td.product-image-title {
    min-width: 100%;
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 20px;
  }
}
.compare-table .table tbody tr td.product-image-title .image {
  clear: both;
  width: 100%;
  margin-bottom: 15px;
  display: block;
}
.compare-table .table tbody tr td.product-image-title .image img {
  width: 100%;
}
.compare-table .table tbody tr td.product-image-title .category {
  float: left;
  clear: both;
  color: #ff5151;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
.compare-table .table tbody tr td.product-image-title .title {
  float: left;
  clear: both;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .compare-table .table tbody tr td.product-image-title .title {
    font-size: 16px;
  }
}
.compare-table .table tbody tr td.product-image-title .title:hover {
  color: #ff5151;
}
.compare-table .table tbody tr td.pro-desc p {
  text-align: left;
  margin: 0;
}
.compare-table .table tbody tr td.pro-price, .compare-table .table tbody tr td.pro-color, .compare-table .table tbody tr td.pro-stock {
  font-size: 14px;
  font-weight: 400;
}
.compare-table .table tbody tr td.pro-remove button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}
.compare-table .table tbody tr td.pro-remove button:hover {
  color: #ff5151;
}
.compare-table .table tbody tr td.pro-remove button i {
  font-size: 20px;
}
.compare-table .table tbody tr td.pro-ratting i {
  color: #e7a415;
}

@media only screen and (max-width: 767px) {
  .compare-page-wrapper.pt-90 {
    padding-top: 20px;
  }
}
/*------ end Compare Page Wrapper -----*/
/*-------- 25. My account style ---------*/
.myaccount-tab-menu {
  flex-direction: column;
}
.myaccount-tab-menu a {
  border: 1px solid #ccc;
  border-bottom: none;
  font-weight: 600;
  font-size: 13px;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
}
.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ccc;
}
.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #ff5151;
  border-color: #ff5151;
  color: #ffffff;
}
.myaccount-tab-menu a i.fa {
  font-size: 14px;
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}

.myaccount-content {
  border: 1px solid #eeeeee;
  padding: 30px;
}
@media only screen and (max-width: 767px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}
.myaccount-content form {
  margin-top: -20px;
}
.myaccount-content h3 {
  font-size: 20px;
  border-bottom: 1px dashed #ccc;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 600;
}
.myaccount-content .welcome a:hover {
  color: #ff5151;
}
.myaccount-content .welcome strong {
  font-weight: 600;
  color: #ff5151;
}
.myaccount-content fieldset {
  margin-top: 20px;
}
.myaccount-content fieldset legend {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.myaccount-content .account-details-form {
  margin-top: 50px;
}
.myaccount-content .account-details-form .single-input-item {
  margin-bottom: 20px;
}
.myaccount-content .account-details-form .single-input-item label {
  font-size: 14px;
  text-transform: capitalize;
  display: block;
  margin: 0 0 5px;
}
.myaccount-content .account-details-form .single-input-item input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #1f2226;
  font-size: 13px;
}
.myaccount-content .account-details-form .single-input-item input:focus {
  border: 1px solid #343538;
}
.myaccount-content .account-details-form .single-input-item button {
  border: none;
  background-color: #ff5151;
  text-transform: uppercase;
  font-weight: 600;
  padding: 9px 25px;
  color: #fff;
  font-size: 13px;
}
.myaccount-content .account-details-form .single-input-item button:hover {
  background-color: #1f2226;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}
.myaccount-table table th,
.myaccount-table .table th {
  padding: 10px;
  font-weight: 600;
  background-color: #f8f8f8;
  border-color: #ccc;
  border-bottom: 0;
  color: #1f2226;
}
.myaccount-table table td,
.myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ccc;
}

.saved-message {
  background-color: #fff;
  border-top: 3px solid #ff5151;
  font-size: 14px;
  padding: 20px 0;
  color: #333;
}

/*-------- Start My Account Page Wrapper --------*/
/*-------- 26. About us style ---------*/
@media only screen and (max-width: 767px) {
  .about-us-content {
    margin-top: 30px;
  }
}
.about-us-content h2 {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 24px;
  padding-bottom: 28px;
  position: relative;
  text-transform: capitalize;
  line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content h2 {
    font-size: 32px;
    margin-bottom: 18px;
    padding-bottom: 22px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content h2 {
    font-size: 25px;
    margin-bottom: 15px;
    padding-bottom: 19px;
  }
}
@media only screen and (max-width: 767px) {
  .about-us-content h2 {
    font-size: 22px;
    margin-bottom: 15px;
    padding-bottom: 19px;
  }
}
.about-us-content h2 span {
  color: #ff5151;
}
.about-us-content h2::before {
  background-color: #ff5151;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  transition: all 0.4s ease 0s;
  width: 80px;
}
.about-us-content p {
  margin: 0;
  color: #333;
  font-size: 15px;
  line-height: 26px;
}
.about-us-content p.peragraph-blog {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  line-height: 28px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content p.peragraph-blog {
    margin-bottom: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .about-us-content p.peragraph-blog {
    margin-bottom: 15px;
    font-weight: 500;
    font-size: 15px;
  }
}
.about-us-content .about-us-btn {
  margin-top: 45px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-content .about-us-btn {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-content .about-us-btn {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .about-us-content .about-us-btn {
    margin-top: 20px;
  }
}
.about-us-content .about-us-btn a {
  display: inline-block;
  background-color: #ff5151;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  z-index: 1;
  padding: 12px 22px;
  border-radius: 5px;
}

.about-us-img {
  margin-right: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-us-img {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-us-img {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .about-us-img {
    margin-right: 0px;
  }
}
.about-us-img img {
  width: 100%;
}

.sin-testimonial .client-content {
  border: 1px solid #e7e9ec;
  padding: 40px 40px 65px;
}
@media only screen and (max-width: 767px) {
  .sin-testimonial .client-content {
    padding: 40px 22px 65px;
  }
}
.sin-testimonial .client-content p {
  color: #333;
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}
.sin-testimonial .client-info {
  align-items: center;
  background-color: #fff;
  display: flex;
  float: left;
  margin-left: 32px;
  margin-top: -44px;
  padding-right: 18px;
  position: relative;
  z-index: 99;
}
@media only screen and (max-width: 767px) {
  .sin-testimonial .client-info {
    margin-left: 10px;
  }
}
.sin-testimonial .client-info h5 {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin: 0 22px 0 10px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .sin-testimonial .client-info h5 {
    font-size: 14px;
  }
}
.sin-testimonial .client-info h5::before {
  background-color: #95999e;
  content: "";
  height: 1px;
  position: absolute;
  right: -15px;
  top: 8px;
  width: 7px;
}
.sin-testimonial .client-info span {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.testimonial-active.owl-carousel .owl-item img {
  display: inline-block;
  width: auto;
}

.team-wrapper .team-img {
  overflow: hidden;
  position: relative;
}
.team-wrapper .team-img a img {
  width: 100%;
}
.team-wrapper .team-img .team-action {
  background: #fff none repeat scroll 0 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  padding: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 70%;
  transform: translateY(-50%);
  transition: all 0.6s ease 0s;
  width: 100%;
  z-index: 5;
}
.team-wrapper .team-img .team-action a {
  color: #fff;
  font-size: 15px;
  transition: all 0.3s ease 0s;
  margin: 0 7px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
}
.team-wrapper .team-img .team-action a.facebook {
  background-color: #3b5998;
  border: 1px solid #3b5998;
}
.team-wrapper .team-img .team-action a.facebook:hover {
  background-color: transparent;
  color: #3b5998;
}
.team-wrapper .team-img .team-action a.twitter {
  background-color: #55acee;
  border: 1px solid #55acee;
}
.team-wrapper .team-img .team-action a.twitter:hover {
  background-color: transparent;
  color: #55acee;
}
.team-wrapper .team-img .team-action a.instagram {
  background-color: #c32aa3;
  border: 1px solid #c32aa3;
}
.team-wrapper .team-img .team-action a.instagram:hover {
  background-color: transparent;
  color: #c32aa3;
}
.team-wrapper .team-content {
  padding: 20px 10px 21px;
  box-shadow: 0 3px 5px rgba(85, 85, 85, 0.2);
}
.team-wrapper .team-content h4 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 5px;
  text-transform: capitalize;
  color: #333;
}
.team-wrapper .team-content span {
  font-size: 15px;
  font-style: italic;
  color: #555;
}
.team-wrapper:hover .team-action {
  opacity: 1;
  visibility: visible;
  top: 50%;
}

@media only screen and (max-width: 767px) {
  .team-area.pt-60 {
    padding-top: 20px;
  }
  .team-area.pb-60 {
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-area.pt-60 {
    padding-top: 20px;
  }
  .testimonial-area.pb-80 {
    padding-bottom: 40px;
  }
}